import {Injectable} from '@angular/core';
import {SearchLandingClientService} from '../../../shared/service/search-landing/search-landing-client.service';
import {SearchLandingRequest} from '../../../shared/models/search-landing/search-landing-request.model';
import { HandleFilterAlgolioRequest, SearchLandingAlgoliaRequest, SearchLandingMultiIndexRequest } from '@models/search-landing/search-landing-algolia-request.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root', 
})

export class SearchLandingImplementService {
  constructor(
    private _searchLandingClient: SearchLandingClientService
  ) {
  }

  getSearchLandingFilters(searchLandingRequest: SearchLandingRequest) {
    return this._searchLandingClient.getSearchLandingFilters(searchLandingRequest);
  }

  getSearchLandingAlgolaFilters(
    searchLandingRequest: SearchLandingAlgoliaRequest | SearchLandingMultiIndexRequest[],
    indexName?: string | null,  // Si es null, se trata de un multi-index
    api_algolia?: string,
    api_key?: string
  ): Observable<any> {
    if (indexName == null) {
      // Multi-index
      return this._searchLandingClient.getSearchLandingAlgoliaMultiIndex(searchLandingRequest as SearchLandingMultiIndexRequest[], api_algolia, api_key);
    } else {
      // Single-index
      return this._searchLandingClient.getSearchLandingAlgoliaFilter(searchLandingRequest as SearchLandingAlgoliaRequest, indexName, api_algolia, api_key);
    }
  }

  handleSelectedRequestAlgolia(handleFilterAlgolioRequest:HandleFilterAlgolioRequest){
    return this._searchLandingClient.handleFSubmitFilterCategory(handleFilterAlgolioRequest);
  }
}
