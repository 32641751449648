import { FormControl, Validators } from '@angular/forms';
import { DocumentTypeControl } from './document-type-control';
import { GenericValidator } from '../validators/generic-validator';
import { CONFIG_USER_NOT_REGISTERED } from '../parameters/enums';
import { getEnableErrorMessages, getErrorbyFormControl } from '../helpers/error-controls-helpers';
import { CE_ERROR_MESSAGES, DNI_ERROR_MESSAGES, IControlErrorMessages, PASSPORT_ERROR_MESSAGES } from '../parameters/global-error-message';
import { EBrand } from '@parameters/access/brand.parameter';
import { environment } from '@environments/environment';

export interface IDocumentObjectType {
  validValues: any[];
  validationType: string;
}

export const DOCUMENT_OBJECT_TYPES: IDocumentObjectType[] = [
  {
    validValues: ['DNI', 'dni', '1', '01'],
    validationType: 'setDniValidation',
  },
  {
    validValues: ['CE', 'C.E.', 'Carnet de extranjería', '2', '02'],
    validationType: 'setCEValidation',
  },
  {
    validValues: ['Pasaporte', 'pasaporte', '3'],
    validationType: 'setPassportValidation',
  }
];

export class CrossDocumentControl extends FormControl {
  private documentTypeControl: DocumentTypeControl;
  private errorMessages: IControlErrorMessages;

  constructor() {
    super('');
  }

  /**
   * Es obligatorio setear el documentTypeControl
   */
  public setDocumentTypeControl(control: DocumentTypeControl) {
    this.documentTypeControl = control;
    this.documentTypeControl.valueChanges
      .subscribe(value => {
        const matchedObject = DOCUMENT_OBJECT_TYPES.find(object => {
          return object.validValues.includes(String(value));
        });
        if (matchedObject) {
          this.removeValidatorsAndUpdate();
          this[matchedObject.validationType]();
        }
      });
  }

  public get documentError() {
    return !this.errorMessages ? '' : getErrorbyFormControl(this, this.errorMessages);
  }

  public get error(): boolean {
    return getEnableErrorMessages(this);
  }
  // setting validators for DNI (document)
  /*
  * Validación para tipo 'document' se genera solo cuando es Inkafarma
  */
  validationsDNI = [
    Validators.required,
    GenericValidator.validateDni,
    Validators.minLength(CONFIG_USER_NOT_REGISTERED.DNI_LENGTH),
    Validators.maxLength(CONFIG_USER_NOT_REGISTERED.DNI_LENGTH),
  ];

  public setDniValidation() {
    this.setValidators(this.validationsDNI);
    this.errorMessages = DNI_ERROR_MESSAGES;
    this.updateValueAndValidity();
  }

  // setting validators for CE
  public setCEValidation() {
    this.setValidators([
      Validators.required,
      GenericValidator.validateCharactersAndNumbers,
      Validators.minLength(CONFIG_USER_NOT_REGISTERED.CE_MIN_LENGTH),
      Validators.maxLength(CONFIG_USER_NOT_REGISTERED.CE_MAX_LENGTH),
    ]);
    this.errorMessages = CE_ERROR_MESSAGES;
    this.updateValueAndValidity();
  }

  // setting validators for Passport
  public setPassportValidation() {
    this.setValidators([
      Validators.required,
      GenericValidator.validateCharactersAndNumbers,
      Validators.maxLength(CONFIG_USER_NOT_REGISTERED.PASSPORT_MAX_LENGTH),
    ]);
    this.errorMessages = PASSPORT_ERROR_MESSAGES;
    this.updateValueAndValidity();
  }

  public removeValidatorsAndUpdate() {
    this.clearValidators();
    this.updateValueAndValidity();
  }
}
