import { Component, NgZone, OnDestroy, OnInit } from "@angular/core";
import { combineLatest, of, Subscription } from "rxjs";
import { catchError, delay, map, switchMap, take } from "rxjs/operators";

import { environment } from "@environments/environment";
import { EBrand, EBrandName } from "@parameters/access/brand.parameter";
import { AppStoreService } from "@stores/app/app-store.service";
import { AddressItem } from "src/app/shared/models/address/address.model";
import {
  INearestDrugstoresRequest,
  NearestDrugstore,
} from "src/app/shared/models/nearest-drugstores/nearest-drugstores.model";
import { ShoppingCartRequestService } from "src/app/shared/service/shopping-cart/shopping-cart-request.service";
import { AddressListStoreService } from "../../../address-manager/address-stores/address-list-store.service";
import { IDrugStoreRequest } from "../../../selected-drugstore-manager/models/drugstore.interface";
import { DRUG_STORE_CONFIG } from "../../../selected-drugstore-manager/parameters/parameters.const";
import { SelectedDrugstoreHttpService } from "../../../selected-drugstore-manager/services/selected-drugstore-http.service";
import { IModalStatus } from "../../enums/modal-status.enum";
import { ReferenceAddressFormService } from "../../pick-up-forms/reference-address-form.service";
import { UserGeolocationHelperService } from "../../pick-up-helpers/user-geolocation-helper.service";
import { NearestDrugstoresImplementService } from "../../pick-up-implements/nearest-drugstores-implement.service";
import { ModalsManagerHelperService } from "../../pick-up-services/modals-manager-helper.service";
import { PickUpConfigStoreService } from "../../pick-up-stores/pick-up-config-store.service";
import { StoresForPickUpStoreService } from "../../pick-up-stores/stores-for-pick-up-store.service";
import {
  IUserGeolocation,
  UserGeolocationStoreService,
} from "../../pick-up-stores/user-geolocation-store.service";
import { SelectDataStoreService } from "src/app/business/office/store/select-data-store.service";
import { Store } from "@models/store.model";
import { OrderTakerValidator } from "@validators/order-taker.validator";

@Component({
  selector: "fp-choose-a-store-modal-v2",
  templateUrl: "./choose-a-store-modal-v2.component.html",
  styleUrls: ["./choose-a-store-modal-v2.component.sass"],
})
export class ChooseAStoreModalV2Component implements OnInit, OnDestroy {
  public brand = EBrandName[environment.brand];
  public isDisableChooseStore = false; // to enable or disable the complete modal
  public isLoadingModal = true;
  public ALL_MODAL_STATUS = IModalStatus;
  public modalStatus: IModalStatus = IModalStatus.DEFAULT;
  public drugstores: NearestDrugstore[] = [];
  public subscriptions: Subscription[] = [];
  public store: Store
  public isCheckedCoords:boolean = false;
  public isOrderTaker = OrderTakerValidator.isOrderTakerEnv();

  constructor(
    private modalsManagerHelper: ModalsManagerHelperService,
    private userGeolocationHelper: UserGeolocationHelperService,
    private addressListStore: AddressListStoreService,
    private userGeolocationStore: UserGeolocationStoreService,
    private nearestDrugstoresImplement: NearestDrugstoresImplementService,
    private shoppingListRequest: ShoppingCartRequestService,
    private storesForPickUpStore: StoresForPickUpStoreService,
    private referenceAddressForm: ReferenceAddressFormService,
    public pickUpConfig: PickUpConfigStoreService,
    private selectedDrugstoreHttpService: SelectedDrugstoreHttpService,
    private appStore: AppStoreService,
    private ngZone: NgZone,
    public _selectDataStore: SelectDataStoreService
  ) {
    this.isLoadingModal = true;
    const drugstoresSub = this.storesForPickUpStore.drugstores$.subscribe(
      (drugstores) => (this.drugstores = drugstores)
    );
    this.subscriptions.push(drugstoresSub);
  }

  ngOnInit() {
    const addresses = this.addressListStore.addressList;
    if (addresses.length) {
      const selectedAddress = addresses.find((address) => address.favorite);
      const paramsForAddress = this.getParamsForAddress(selectedAddress);

      this.onNearestDrugstores(paramsForAddress).subscribe((response) => {
        this.ngZone.run(() => {
          this.storesForPickUpStore.setDrugstores(response.drugstores);
          if (response.permission.status === "ok") {
            if (response.drugstores.length) {
              this.setSelectedTemporalDrugstore(response.drugstores);
              this.modalStatus = IModalStatus.OK;
            } else {
              this.modalStatus = IModalStatus.DEFAULT;
            }
          } else this.modalStatus = IModalStatus.NO_ONE;
          this.isLoadingModal = false;
        });
      });
    } else {
      this.userGeolocationHelper
        .isPendingToAskGeolocation$()
        .pipe(delay(200))
        .pipe(take(1))
        .subscribe((isPending) => {
          if (isPending) {
            this.modalStatus = IModalStatus.IS_PENDING;
            this.isLoadingModal = false;
          }
          this.getDrugstoresOfGeolocationFlow$()
            .pipe(take(1))
            .subscribe((response) => {
              this.ngZone.run(() => {
                this.storesForPickUpStore.setDrugstores(response.drugstores);
                if (response.permission.status === "ok") {
                  if (response.drugstores.length) {
                    this.setSelectedTemporalDrugstore(response.drugstores);
                    this.modalStatus = IModalStatus.OK;
                  } else {
                    this.modalStatus = IModalStatus.NO_ONE;
                  }
                } else {
                  const referenceAddress =
                    this.referenceAddressForm.referenceAddressControl.value;
                  const MIN_CHARACTERS =
                    this.pickUpConfig.config
                      .minimunCharactersForPredictiveAddresses;
                  if (
                    typeof referenceAddress === "string" &&
                    referenceAddress.length >= MIN_CHARACTERS
                  ) {
                    this.modalStatus = IModalStatus.NO_ONE;
                  } else {
                    this.modalStatus = IModalStatus.NO_ONE;
                  }
                }
                this.isLoadingModal = false;
              });
            });
        });
    }
  }

  onDrugStore(paramsForAddress) {
    this.selectedDrugstoreHttpService
      .getDrugStoreList$({
        position: {
          latitude: paramsForAddress.latitude,
          longitude: paramsForAddress.longitude,
        },
        proximity: DRUG_STORE_CONFIG.PROXIMITY,
        companyCode:
          this.appStore.brandAtention === EBrand.inkafarma
            ? EBrand.inkafarma
            : EBrand.mifarma,
        page: DRUG_STORE_CONFIG.PAGE,
        maxSixeResult: DRUG_STORE_CONFIG.MAX_SIZE_RESULT,
      } as IDrugStoreRequest)
      .pipe(delay(100))
      .pipe(take(1))
      .subscribe((drugstores) => {
        const listDrugstore: NearestDrugstore[] = drugstores.map(
          (drugstore) => {
            const current = new NearestDrugstore(null);

            current.address = drugstore.address || "";
            current.distance = drugstore.distance || 0;
            current.id = drugstore.legacyId || 0;
            current.latitude = drugstore.latitude || 0;
            current.longitude = drugstore.longitude || 0;
            current.localCode = drugstore.localCode || "";
            current.name = drugstore.name || "";
            current.defaultDrugstore = drugstore.defaultDrugstore || false;
            current.localOpeningHours =
              drugstore.startHour + " - " + drugstore.endHour || "";

            return current;
          }
        );

        this.ngZone.run(() => {
          this.storesForPickUpStore.setDrugstores(listDrugstore);
          if (listDrugstore.length) {
            this.setSelectedTemporalDrugstore(listDrugstore);
            this.modalStatus = IModalStatus.OK;
          } else {
            this.modalStatus = IModalStatus.EMPTY;
          }
          this.isLoadingModal = false;
        });
      });
  }

	onNearestDrugstores(paramsForAddress) {
		if (OrderTakerValidator.isOrderTakerEnv()) {
      const latitude = paramsForAddress?.latitude
      const longitude = paramsForAddress?.longitude
			return (
				latitude && longitude
					? this.getDrugstoresOfGeoLocationCoords$({latitude, longitude})
					: this.getDrugstoresOfGeolocationFlow$()
			)
				.pipe(delay(200))
				.pipe(take(1))
				.pipe(
					catchError((err) => {
						this.modalStatus = IModalStatus.EMPTY;
						this.isLoadingModal = false;
						throw err;
					})
				);
		} else {
			return this.getDrugstoresOfGeolocationFlow$()
				.pipe(delay(200))
				.pipe(take(1))
				.pipe(
					catchError((err) => {
						this.modalStatus = IModalStatus.EMPTY;
						this.isLoadingModal = false;
						throw err;
					})
				);
		}
	}

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  private setSelectedTemporalDrugstore(drugstores: NearestDrugstore[]) {
    const foundDefaultDrugstore = drugstores.find((d) => d.defaultDrugstore);
    const defaultDrugstore = foundDefaultDrugstore
      ? foundDefaultDrugstore
      : drugstores[0];
    this.storesForPickUpStore.setSelectedTemporalDrugstore(defaultDrugstore);
  }

  private getDrugstoresOfGeolocationFlow$() {
    return this.userGeolocationHelper.getGeolocationPermissions$().pipe(
      switchMap((permission) => {
        if (permission.status === "ok") {
          const loadMap$ =
            this.userGeolocationHelper.loadGeolocationAddress$(permission);
          const paramsForGeo = this.getParamsForGeo(permission.coords);
          const drugstores$ =
            this.nearestDrugstoresImplement.getDrugstores$(paramsForGeo);
            // if (permission.coords.latitude !== null) {
            //   this.modalStatus = IModalStatus.OK;
            //   this.isLoadingModal = false;
            // }
          return combineLatest([drugstores$, loadMap$]).pipe(
            map(([drugstores, loadMap]) => {
              return { drugstores, permission };
            })
          );
        } else {
          this.modalStatus = IModalStatus.EMPTY;
          const userGeolocation = {
            permissions: permission,
          } as IUserGeolocation;
          this.userGeolocationStore.setUserGeolocation(userGeolocation);
          return of({ drugstores: [] as NearestDrugstore[], permission });
        }
      })
    );
  }

  private getDrugstoresOfGeoLocationCoords$(coords) {
    const paramsForGeo = this.getParamsForGeo(coords);
    const drugstores$ = this.nearestDrugstoresImplement.getDrugstores$(paramsForGeo);

    return combineLatest([drugstores$]).pipe(
      map(([drugstores]) => {
        return { drugstores, permission: {
          status: 'ok'
        }};
      })
    );
  }

  private getVerifiedSelectedDrugstore(drugstores: NearestDrugstore[]) {
    const { selectedDrugstore } = this.storesForPickUpStore;
    if (selectedDrugstore.id) {
      const lastSelectedDrugstoreId = selectedDrugstore.id;
      const temporalSelectedDrugstore = drugstores.find(
        (d) => d.id === lastSelectedDrugstoreId
      );
      return temporalSelectedDrugstore
        ? temporalSelectedDrugstore
        : drugstores[0];
    } else {
      return drugstores[0];
    }
  }

  private loadDrugstores(location) {
    this.storesForPickUpStore.setDrugstores([]);
    const lat = location.lat;
    const lng = location.lng;

    const params = {
      latitude: lat,
      longitude: lng,
      shoppingCart: this.shoppingListRequest.shoppingCartRequest.products,
      companyCode: this.appStore.companyCode
    } as INearestDrugstoresRequest;
    this.nearestDrugstoresImplement
      .getDrugstores$(params)
      .pipe(take(1))
      .subscribe(
        (drugstores) => {
          this.storesForPickUpStore.setDrugstores(drugstores);
          const temporalSelectedDrugstore =
            this.getVerifiedSelectedDrugstore(drugstores);
          this.storesForPickUpStore.setSelectedTemporalDrugstore(
            temporalSelectedDrugstore
          );
        },
        () => {
          this.storesForPickUpStore.setDrugstores([]);
          this.modalStatus = IModalStatus.EMPTY;
        }
      );
  }

  private getParamsForAddress(selectedAddress: AddressItem) {
    return {
      latitude: selectedAddress?.latitude,
      longitude: selectedAddress?.longitude,
      shoppingCart: this.shoppingListRequest.shoppingCartRequest.products,
      companyCode: this.appStore.companyCode
    } as INearestDrugstoresRequest;
  }

  private getParamsForGeo(coords: GeolocationCoordinates) {
    return {
      latitude: coords?.latitude,
      longitude: coords?.longitude,
      shoppingCart: this.shoppingListRequest.shoppingCartRequest.products,
      companyCode: this.appStore.companyCode
    } as INearestDrugstoresRequest;
  }

  public closeModal() {
    if (!this.isDisableChooseStore) {
      this.modalsManagerHelper.closeChooseAStoreModalV2();
    }
  }

  public saveChanges() {
    /**
    if(OrderTakerValidator.isOrderTakerEnv()){
      this.setNearByStoreTomado();
    }else{
     **/
    this._selectDataStore.setNearbyStore(this.storesForPickUpStore.selectedTemporalDrugstore);
    this.storesForPickUpStore.setSelectedDrugstore(this.storesForPickUpStore.selectedTemporalDrugstore);
      this.appStore.setStorageItem('drugstoreSelected', JSON.stringify(this.storesForPickUpStore.selectedTemporalDrugstore));
    // }
    setTimeout(() => this.modalsManagerHelper.closeChooseAStoreModalV2(), 100);
  }

  public setModalStatus(state: IModalStatus) {
    this.modalStatus = state;
  }

  public setNearByStoreTomado(){
    const drugstoreSelected = this.storesForPickUpStore.selectedTemporalDrugstore;
      if(drugstoreSelected){
        this.store = {
          address: drugstoreSelected.address,
          distance: drugstoreSelected.distance,
          drugstoreWareHouseId: drugstoreSelected.drugstoreWareHouseId,
          id: drugstoreSelected.id,
          latitude: drugstoreSelected.latitude,
          longitude: drugstoreSelected.longitude,
          localCode: drugstoreSelected.localCode,
          localOpeningHours: drugstoreSelected.localOpeningHours,
          name: drugstoreSelected.name
        };
        this._selectDataStore.setNearbyStore(this.store as Store);
        this.appStore.setStorageItem('drugstoreSelected', JSON.stringify(this.store));
      }
  }
  
  clickSwitch(){
    this.isCheckedCoords = !this.isCheckedCoords;
  }
}
