import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { environment } from "@environments/environment";
import { EBrand, EBrandAsset } from "@parameters/access/brand.parameter";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { NewAddressFormHelperService } from "../../address-forms/new-address-form-helper.service";
import { NewAddressFormService } from "../../address-forms/new-address-form.service";
import { AddressImplementService } from "../../address-services/address-implement.service";
import { AddressManagerPersistanceService } from "../../address-services/address-manager-persistance.service";
import { AddressRequestService } from "../../address-services/address-request.service";
import { AddressNicknamesStoreService } from "../../address-stores/address-nicknames-store.service";
import { ISearchAutocompleteItem } from "../../models/google-map.interface";
import {
  GEO_MESSAGE_1,
  GEO_MESSAGE_2,
} from "../../parameters/geolocation.parameter";
import {
  ADDRESSES_PREDICTIVE_DELAY,
  ADDRESS_MINIMAL_LENGHT,
} from "../../parameters/global.parameter";
import {
  FLOW_SEARCH_ADDRESS_TYPE,
  NEW_ADDRESS_MODAL_TITLE,
  UPDATE_ADDRESS_MODAL_TITLE,
} from "../../parameters/modal.constant";
import { PATHS_TO_RELOAD } from "../../parameters/special-behaviors.parameter";

import { CoverageLocationImplementService } from "../../../custom-agm-core/services/coverage-location-implement.service";
import {
  GoogleMapPlacesService,
  IGoogleFormattedAddress,
} from "../../../custom-agm-core/services/google-map-places.service";
import {
  GoogleMapService,
  ZOOM_BY_DEFAULT,
  ZOOM_BY_DEFAULT_2,
} from "../../../custom-agm-core/services/google-map.service";

import { BreakpointBehavior } from "src/app/shared/behaviors";
import {
  GeolocationService,
  GEO_STATUS_TYPES,
  IBasicLocation,
} from "src/app/shared/geolocation/geolocation.service";
import { AddressNickname } from "src/app/shared/models/address/address-nickname.model";
import { BREAKPOINTS } from "src/app/shared/parameters/global";

import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { OrderTakerValidator } from "@validators/order-taker.validator";
import { of, Subject, Subscription } from "rxjs";
import { debounceTime, switchMap, take, takeUntil } from "rxjs/operators";
import { OfficeModalService } from "src/app/business/office/services/office-modal.service";
import { setNewAddressModal } from "src/app/core/store/actions/app.actions";
import { AppState } from "src/app/core/store/reducer/app.reducer";
import { AppStoreSubscription } from "src/app/core/store/subscription/app.store.subscription";
import { getRouterPath } from "src/app/shared/helpers/router-functions.helper";
import { AddressRequest } from "src/app/shared/models/address/address-request";
import { AddressItem } from "src/app/shared/models/address/address.model";
import { AnalyticServiceService } from "src/app/shared/service/analitycService/analitycService.service";
import { ClientStoreService } from "src/app/shared/stores/client/client-store.service";
import {
  HereMapPlacesService,
  POSITION_BY_DEFAULT,
} from "../../../here-maps/services/here-map-places.service";
import { AddressHttpService } from "../../address-services/address-http.service";
import { AddressListStoreService } from "../../address-stores/address-list-store.service";
import { RetValidateCartService } from "src/app/shared/service/cart/ret-validate-cart.service";
import { AppStoreService } from "@stores/app/app-store.service";
import { CoachmarkService } from "src/app/shared/service/coachmark/coachmark.service";
import { AuthImplementService } from "@implements/auth/auth-implement.service";
import { UserGeolocationHelperService } from "../../../pick-up-in-store/pick-up-helpers/user-geolocation-helper.service";
import { TaggingService } from "src/app/atm-services/tagging.service";
import { LS_ADDRESS_SELECTED } from "@service/local-storage/constants/address.constant";
import { GeolocationStoreService } from "src/app/business/consulta-stock/stock-stores/geolocation-store.service";
import { HereMapConfigService } from "../../../here-maps/services/here-map-config.service";
import { SnackbarAlertService } from "../../../snackbar/snackbar-alert.service";
import { ISwitchState } from "src/app/business/checkout/checkout-components/generic-components/switch/switch.component";

@Component({
  selector: "fp-new-address-modal-v2",
  templateUrl: "./new-address-modal-v2.component.html",
  styleUrls: ["./new-address-modal-v2.component.sass"],
})
export class NewAddressModalV2Component
  implements OnInit, OnDestroy, AfterViewInit
{
  public isInkaBrand = OrderTakerValidator.isOrderTakerEnv()
		? EBrand[EBrandAsset[environment.brand]] == EBrand.inkafarma
		: EBrand[environment.brand] == EBrand.inkafarma;

  @ViewChild("googleMap", { static: false })
  googleMapRef: ElementRef<HTMLDivElement>;
  @ViewChild("hereMap", { static: false })
  hereMapRef: ElementRef<HTMLDivElement>;

  public addressNicknameValue: number;

  public showGoogleMap = false;
  public showCurrentLocationItem = false;
  public showSearchAutocompleteList = false;
  public thereIsNoCoverage = false;

  public searchAutocompleteList: ISearchAutocompleteItem[] = [];

  public location: IBasicLocation | null = null;
  public locationError: GeolocationPositionError | null = null;

  public isPendingGeo = true;
  public geoMessage1 = GEO_MESSAGE_1;
  public geoMessage2 = GEO_MESSAGE_2;
  public googleMapImage =
    "../../../../../../assets/images/map/google-map-img.JPG";
  public geolocationAddress = "";
  public geolocationGoogleAddress: IGoogleFormattedAddress =
    {} as IGoogleFormattedAddress;

  private subscriptions: Subscription[] = [];
  private addressNicknameList: AddressNickname[] = [];
  public addressToUpdate: AddressItem =
    this.addressManagerPersistanceService.currentAddressUpdate;
  public backToAddressList = false;
  public wasDoneTheSearch = false;

  public modalTitle;

  private isLoadedReady = false;
  isOrderTaker = OrderTakerValidator.isOrderTakerEnv();

  public isFocused = false;
  public isCreatingOrUpdating = false;
  private cancelSearchSubject = new Subject<boolean>();
  private cancelSearch$ = this.cancelSearchSubject.asObservable();

  public addressValue = "";
  private isLocatedDirectlyOnMap = false;

  public isDesktop = false;

  // this attributes will be used to avoid setting address with marker
  private isAddressToUpdate = false;
  private isAddressToCreate = false;
  public isLoadingAddressFromMarker = false;

  // variables heremap
  public showHereMap = false;
  private geolocationReverseAddress;

  newAddress = false

  private readonly unsubscribe$ = new Subject<void>();
  isCheckedCoords:ISwitchState = {isChecked:false};
  constructor(
    public newAddressForm: NewAddressFormService,
    private newAddressFormHelper: NewAddressFormHelperService,
    public dialogRef: MatDialogRef<NewAddressModalV2Component>,
    public geolocationService: GeolocationService,
    private _geolocationStoreService: GeolocationStoreService,
    private googleMapService: GoogleMapService,
    private googleMapPlacesService: GoogleMapPlacesService,
    private addressManagerPersistanceService: AddressManagerPersistanceService,
    private addressNicknamesStore: AddressNicknamesStoreService,
    private addressImplement: AddressImplementService,
    private coverageLocationImplement: CoverageLocationImplementService,
    private breakpointBehavior: BreakpointBehavior,
    private addressRequestService: AddressRequestService,
    private router: Router,
    private hereMapPlacesService: HereMapPlacesService,
    private appStoreSubscription: AppStoreSubscription,
    private readonly store: Store<{ appSate: AppState }>,
    private serviceAnalytic: AnalyticServiceService,
    private _addressHttpService: AddressHttpService,
    private _clientStore: ClientStoreService,
    private _addressListStore: AddressListStoreService,
    private _officeModalService: OfficeModalService,
    private retValidateService: RetValidateCartService,
    private appStoreService: AppStoreService,
    private coachMarkService: CoachmarkService,
    private authImplementService: AuthImplementService,
    private userGeolocationHelper: UserGeolocationHelperService,
    private _snackbarAlert: SnackbarAlertService,
    @Inject(MAT_DIALOG_DATA) public data,
    private hereMapConfigService:HereMapConfigService
  ) {
    const addressNicknameSubscription =
      this.newAddressForm.addressNicknameControl.valueChanges.subscribe(
        (value) => {
          this.addressNicknameValue = value;
        }
      );
    this.googleMapService.isSearchInputFocus$.subscribe(
      (isFocused) => (this.isFocused = isFocused)
    );
    this.subscriptions.push(addressNicknameSubscription);
    this.textSearchObserver = this.textSearchObserver.bind(this);
    this.breakpointBehavior
      .mediaBreakpointUpObserver(BREAKPOINTS.xl)
      .subscribe((value) => (this.isDesktop = value.matches));
    this.isFocused = false;
    this.isLoadedReady = false;
    this.showSearchAutocompleteList = false;
    this.isAddressToUpdate = false;
    this.isAddressToCreate = false;

    this.modalTitle =
      this.data === FLOW_SEARCH_ADDRESS_TYPE.EDIT_ADDRESS
        ? UPDATE_ADDRESS_MODAL_TITLE
        : NEW_ADDRESS_MODAL_TITLE;
  }

  ngOnInit() {
    this.coachMarkService.activeProductCoachmark.next(false);
    this.newAddressForm.enableInitialConfigs();
    this.newAddressFormHelper.resetFormToCreateAddress();
    if (
      this.addressToUpdate &&
      this.data === FLOW_SEARCH_ADDRESS_TYPE.EDIT_ADDRESS
    ) {
      this.newAddressFormHelper.setFormValuesToUpdate(this.addressToUpdate);
    }
    this.subscribeGeolocationConfigErrorObserver();
    this.verifyGeolocation();
    this.addressNicknamesStore.addressNicknameList$
      .pipe(
        switchMap((list) => {
          return list.length === 0
            ? this.addressImplement.getAddressNicknameList$()
            : of(list);
        })
      )
      .pipe(take(1))
      .subscribe((addressNicknames) => {
        this.addressNicknameList = addressNicknames;
      });
    this.subscribeDirectionControlObserver();
    const valueChangesSubs =
      this.newAddressForm.directionControl.valueChanges.subscribe((value) => {
        this.addressValue = value;
      });
    this.subscriptions.push(valueChangesSubs);
    this.refreshNewAddressModal();
    this.newAddress = this._addressListStore.addressList.length === 0
  }

  ngOnDestroy() {
    this.newAddressForm.disableInitialConfigs();
    this.subscriptions.forEach((sub) => sub.unsubscribe());
    this.googleMapService.removeMarker();
    this.newAddressForm.unsubscribeObservers();
    this.googleMapService.resetCustomControls();
    this.hereMapPlacesService.clearMap();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public selectRadioOption(optionValue: number) {
    this.newAddressForm.addressNicknameControl.setValue(optionValue);
  }

  private hereMapPlaces$(input){
    if (this.isOrderTaker && this.isCheckedCoords.isChecked) {
      return this.hereMapPlacesService.getReverseGeocodeService$(input);
    }
    return  this.hereMapPlacesService.getAutoCompleteService$(input);
  }

  private subscribeDirectionControlObserver() {
    const directionControlSubscription =
      this.newAddressForm.directionControl.valueChanges
        .pipe(debounceTime(this.hereMapConfigService.getDebounceTime()))
        .subscribe((address) => {
          if (this.isFocused && address.length > 0) {
            this.showSearchAutocompleteList = true;
            if (address.length >= ADDRESS_MINIMAL_LENGHT) {
              this.hereMapPlaces$(address)
                .subscribe(
                  (formattedAddresses) => {
                    this.searchAutocompleteList = formattedAddresses.items.map(
                      (_address) => {
                        return {
                          icon: "pin",
                          title: _address.title,
                          subTitle: _address.address
                            ? _address.address.label
                            : "",
                          searchItem: _address,
                        } as ISearchAutocompleteItem;
                      }
                    );
                  },
                  (error) => {
                    console.error(error);
                  }
                );
            }
          }
        });
    const directionControlSubscription2 =
      this.newAddressForm.directionControl.valueChanges.subscribe((address) => {
        this.cancelSearchSubject.next(true);
        this.wasDoneTheSearch = false;
        this.showSearchAutocompleteList = false;
        this.searchAutocompleteList = [];
        if (address.length < ADDRESS_MINIMAL_LENGHT) {
          this.showSearchAutocompleteList = false;
        }
        if (address.length === 0) {
          this.showCurrentLocationItem = true;
          this.googleMapService.removeMarker();
          this.showGoogleMap = false;
          this.googleMapService.removeGeolocationControl();
          this.googleMapService.removePositionControl();
        } else {
          this.showCurrentLocationItem = false;
        }
      });
    this.subscriptions.push(
      directionControlSubscription,
      directionControlSubscription2
    );
  }

  public addressIsChanging() {
    this.isFocused = true;
    this.showGoogleMap = false;
  }

  private textSearchObserver(formattedAddresses: IGoogleFormattedAddress[]) {
    this.searchAutocompleteList = formattedAddresses.map((address) => {
      return {
        icon: "pin",
        title: address.formattedAddressName,
        subTitle: address.addressLocality,
        searchItem: address,
      } as ISearchAutocompleteItem;
    });
    this.wasDoneTheSearch = true;
  }

  ngAfterViewInit() {
    this.showHereMap = false;
    this.showGoogleMap = false;
    this.googleMapService.removeGeolocationControl();
    this.googleMapService.removePositionControl();
    this.wasDoneTheSearch = false;
    this.addressRequestService.resetRequestParams();

    if (
      this.addressToUpdate &&
      this.data === FLOW_SEARCH_ADDRESS_TYPE.EDIT_ADDRESS
    ) {
      setTimeout(() => {
        this.showHereMap = true;
        const { latitude: lat, longitude: lng } = this.addressToUpdate;
        this.hereMapPlacesService.showMap(this.hereMapRef, { lat, lng });
        this.showHereMap = true;
        this.isFocused = false;
        this.wasDoneTheSearch = true;
      }, 200);
      this.addressRequestService.updateRequestParamsFromAddressItemV2(
        this.addressToUpdate
      );
    } else {
      const locationSubscription = this.geolocationService.location$
        .pipe(
          switchMap((location) => {
            this.location = location;
            this.isPendingGeo = false;
            this.isLoadedReady = true;
            this.isFocused = true;
            // return this.googleMapService.loadGoogleMapConfig$(this.hereMapRef.nativeElement);
            return [location];
          })
        )
        .pipe(take(1))
        .subscribe(() => {
          this.responseGeolocalization();
        });
      this.subscriptions.push(locationSubscription);
    }
  }

  public closeModal() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.store.dispatch(
      setNewAddressModal({
        newAddressModal: { direction: "", hereAddressMarked: null },
      })
    );
    this.serviceAnalytic.registerEventAnalytics(
      this.serviceAnalytic.detectModuleTag("x_o_cancelar"),
      "Click :: Cerrar modal direcciones",
      ""
    );
    setTimeout(() => {
      this.dialogRef.close();
    }, 250);
  }

  public createOrUpdateAddress() {
    TaggingService.RetSaveDeliveryTypeDialog(true);
    this.isCreatingOrUpdating = true;
    const selectedNickName = this.addressNicknameList.find((nickname) =>
        nickname.id === this.newAddressForm.addressNicknameControl.value
    );

    this.addressRequestService.requestParams.name = selectedNickName?.name === "Otros"
        ? this.newAddressForm.otherDirectionControl.value
        : selectedNickName.name;
    this.addressRequestService.requestParams.notes = this.newAddressForm.referenceControl.value;
    if (this.data === FLOW_SEARCH_ADDRESS_TYPE.EDIT_ADDRESS) {
      this.serviceAnalytic.registerEventAnalytics(
        this.serviceAnalytic.detectModuleTag("agregar_nueva_direccion"),
        "Click:: agregar_nueva_direccion",
        "agregar_nueva_direccion"
      );

      if (this.addressToUpdate) {
        this.addressRequestService.requestParams.id = this.addressToUpdate.id;
        if (this.isUndefinedLongitudeLatitude) {
          this.addressRequestService.requestParams.street =
            this.addressToUpdate.street;
          this.addressRequestService.requestParams.latitude =
            this.addressToUpdate.latitude;
          this.addressRequestService.requestParams.longitude =
            this.addressToUpdate.longitude;
          this.addressRequestService.requestParams.city =
            this.addressToUpdate.city;
          this.addressRequestService.requestParams.country =
            this.addressToUpdate.country;
          this.addressRequestService.requestParams.district =
            this.addressToUpdate.district;
          this.addressRequestService.requestParams.number =
            this.addressToUpdate.number;
        }

        if (this.isOrderTaker) {
          const valueForm = this.newAddressForm.form.value;
          const {client} = this._clientStore;

          const updateAddressRequest: AddressRequest = {
            idClient: client.codClient,
            idAddress: this.addressRequestService.requestParams.id,
            addressDescription: valueForm.direction,
            referenceAddressDescription: valueForm.reference,
            number: valueForm.number,
            latitude: this.addressRequestService.requestParams.latitude,
            longitude: this.addressRequestService.requestParams.longitude,
            addressType: `${valueForm.addressNickname}`,
            mz: valueForm.mz,
            lote: valueForm.lote,
            ownerName: this.addressRequestService.requestParams.name,
            district:this.addressRequestService.requestParams.district
          };

          this._addressHttpService
            .updateAddress$(updateAddressRequest)
            .subscribe(() => {
              const addressListStore = this._addressListStore.addressList;
              const newAddressListStore = addressListStore.map((addressM) => {
                if (addressM.id === updateAddressRequest.idAddress) {
                  const newAddress = new AddressItem({
                    id: updateAddressRequest.idAddress,
                    zoneId: 0,
                    name: updateAddressRequest.ownerName,
                    district: updateAddressRequest.district,
                    street: updateAddressRequest.addressDescription,
                    number: updateAddressRequest.number,
                    apartment: "",
                    city: "",
                    country: "",
                    state: "",
                    latitude: updateAddressRequest.latitude,
                    longitude: updateAddressRequest.longitude,
                    notes: "",
                    favorite: true,
                    canContinue: true,
                    programmedZone: true,
                    viaId: null,
                    requireEdition: "",
                    otherDirection: "",
                    mz: updateAddressRequest.mz,
                    lote: updateAddressRequest.lote,
                    reference: updateAddressRequest.referenceAddressDescription,
                  });

                  return newAddress;
                }else addressM.favorite = false;
                return addressM;
              });

              this._addressListStore.setAddressList(newAddressListStore);
              const editAddres = newAddressListStore.find((address) => address.id === updateAddressRequest.idAddress);
              this.addressManagerPersistanceService.setAddressToUpdate(editAddres);
              this._addressListStore.setSelectedAddress(editAddres);
              client.addresses = newAddressListStore;
              this._clientStore.setClient$(client);
              this._clientStore.saveClient$();

              this.isCreatingOrUpdating = false;
              setTimeout(() => this.closeModal(), 200);
            });
        } else {
          this.addressImplement
            .updateAddress$(this.addressRequestService.requestParams)
            .pipe(take(1))
            .subscribe(() => {
              this.appStoreService.setStorageItem(LS_ADDRESS_SELECTED, 'true');
              this.retValidateService.storeProcessType('RAD');
              this.isCreatingOrUpdating = false;
              this.closeModal();
              this.validateIfNeedReload();
            });
        }
      }
    }
    if (this.data === FLOW_SEARCH_ADDRESS_TYPE.REGISTER_NEW_ADDRESS) {
      if (this.isOrderTaker) {
        let userId = this.authImplementService.currentUID;
        this.addressRequestService.requestParams.id = null;
        let address: AddressRequest = new AddressRequest();
        address.idClient = this._clientStore?.client?.codClient || userId;
        address.addressDescription = `${this.addressRequestService.requestParams.street} ${this.addressRequestService.requestParams.number}, ${this.addressRequestService.requestParams.district},${this.addressRequestService.requestParams.city}`;
        address.latitude = this.addressRequestService.requestParams.latitude;
        address.longitude = this.addressRequestService.requestParams.longitude;
        address.ownerName = this.addressRequestService.requestParams.name;
        address.district = this.addressRequestService.requestParams.district;
        address.number = this.newAddressForm.numberControl.value;
        address.referenceAddressDescription =
          this.newAddressForm.referenceControl.value;
        address.typeAddress = this.newAddressForm.addressNicknameControl.value;
        address.ownerName = this.addressRequestService.requestParams.name;

        this._addressHttpService.registerAddress$(address).subscribe((res) => {
          this.isCreatingOrUpdating = false;

          const list =
            this._addressListStore.addressList.length > 0
              ? this._addressListStore.addressList
              : [];
          let newAddress = new AddressItem({
            id: res?.idAddress,
            zoneId: 0,
            name: address.ownerName,
            district: address.district,
            street: address.addressDescription,
            number: address.number,
            apartment: "",
            city: "",
            country: "",
            state: "",
            latitude: address.latitude,
            longitude: address.longitude,
            notes: "",
            favorite: true,
            canContinue: true,
            programmedZone: true,
            viaId: null,
            requireEdition: "",
            otherDirection: "",
            reference: address.referenceAddressDescription,
          });
          newAddress.isNew = true;
          list.map((r) => (r.favorite = false));
          list.push(newAddress);
          this._addressListStore.setAddressList(list);
          this._addressListStore.setSelectedAddress(newAddress);
          this.addressManagerPersistanceService.setAddressToUpdate(newAddress);
          if (this._addressListStore.addressList.length === 1) {
            this._officeModalService.openOfficeModal();
          }
          let client = this._clientStore.client;
          if(client){
            client.addresses = list;
          }
          this._clientStore.setClient$(client);
          this._clientStore.saveClient$();
          this.closeModal();
          // this.validateIfNeedReload();
        });
      } else {
        const requestParams = this.addressRequestService.requestParams;
        requestParams.id = this.isOrderTaker ? requestParams.id : null;

        this.addressImplement
          .createAddress$(this.addressRequestService.requestParams)
          .pipe(take(1))
          .subscribe(() => {
            this.closeModal();
            const {addressList} = this._addressListStore;
            this.appStoreService.setStorageItem(LS_ADDRESS_SELECTED, 'true');
            this.retValidateService.storeProcessType('RAD');
            this.isCreatingOrUpdating = false;
            if (addressList.length === 1) {
              window.location.reload();
            }
          });
      }
    }
  }

  get isUndefinedLongitudeLatitude(): boolean {
    const isUndefinedLongitude =
      this.addressRequestService.requestParams.longitude === undefined;
    const isUndefinedLatitude =
      this.addressRequestService.requestParams.latitude === undefined;
    return isUndefinedLongitude && isUndefinedLatitude;
  }

  private loadGeolocationAddressAtMyLocation() {
    this.googleMapPlacesService
      .getFormattedGeolocationAddress$()
      .pipe(take(1))
      .subscribe((googleFormattedAddress) => {
        const { district, formattedAddressName } = googleFormattedAddress;
        this.geolocationAddress =
          `${formattedAddressName ? formattedAddressName : ""}` +
          `${district ? ", " + district : ""}`;
        this.geolocationGoogleAddress = googleFormattedAddress;
      });
  }

  private loadHereGeolocationAddressAtMyLocation() {
    this.hereMapPlacesService
      .getFormattedGeolocationAddress$()
      .pipe(take(1))
      .subscribe((data) => {
        if (data) {
          this.geolocationReverseAddress = data;
          const addressItem = this.newAddressFormHelper.formatHereToAddress(data);
          this.geolocationAddress = this.newAddressFormHelper.formatAddress(addressItem);
          this.geolocationService.isAcceptedGeolocation = true;
        } else {
          this.geolocationService.isAcceptedGeolocation = false;
          this.geolocationService.loadGeolocation();
        }
        this.searchFocus();
      });
  }

  private addGeolocationControl() {
    if (this.geolocationAddress) {
      const { location } = this.geolocationGoogleAddress;
      this.googleMapService.addGeolocationControl(location);
    }
  }

  private subscribeGeolocationConfigErrorObserver() {
    const locationErrorSubscription =
      this.geolocationService.locationError$.subscribe((error) => {
        this.locationError = error;
        this.isPendingGeo = false;
      });
    this.subscriptions.push(locationErrorSubscription);
  }

  private verifyGeolocation() {
    if (this.geolocationService.existNavigatorGeolocation) {
      this.geolocationService
        .getGeolocationStatus()
        .pipe(take(1))
        .subscribe((status) => {
          if (status.state === GEO_STATUS_TYPES.ACCEPTED) {
            this.isPendingGeo = false;
            this.geolocationService.isAcceptedGeolocation = true;
            this.geolocationService.loadGeolocation();
            this._geolocationStoreService.availableLoc = true;
          } else if (status.state === GEO_STATUS_TYPES.DENIED) {
            this.isPendingGeo = false;
            this.geolocationService.isAcceptedGeolocation = false;
            this.geolocationService.loadGeolocation();
            this._geolocationStoreService.availableLoc = false;
          } else {
            this.isPendingGeo = true;
            this.geolocationService.isAcceptedGeolocation = false;
          }
        });
    }
  }

  public activateGeolocation() {
    if (!this.geolocationService.isAcceptedGeolocation) {
      this.geolocationService.loadGeolocation();
    }
  }

  public searchFocus() {
    this.isFocused = true;
    const address = this.newAddressForm.directionControl.value as string;
    if (address.length === 0) {
      this.showCurrentLocationItem = true;
    }
    if (address.length >= ADDRESS_MINIMAL_LENGHT) {
      this.showSearchAutocompleteList = true;
    }
  }

  public searchBlur() {
    setTimeout(() => {
      this.isFocused = false;
      this.showCurrentLocationItem = false;
      this.showSearchAutocompleteList = false;
    }, 300);
  }

  public searchAgain() {
    this.thereIsNoCoverage = false;
    this.showCurrentLocationItem = false;
    this.showSearchAutocompleteList = false;
    this.searchAutocompleteList = [];
    this.googleMapService.removeMarker();
    this.showGoogleMap = false;
    this.googleMapService.removeGeolocationControl();
    this.googleMapService.removePositionControl();
    this.wasDoneTheSearch = false;
    this.newAddressForm.directionControl.setValue("");
    this.store.dispatch(
      setNewAddressModal({
        newAddressModal: { direction: "", hereAddressMarked: null },
      })
    );
    this.isFocused = false;
  }

  public getPositionAndLocateAddress(hereFormattedAddress: any) {
		this.hereMapPlacesService.getDetailedAddress$(hereFormattedAddress.id).subscribe({
			next: (direction) => {
				hereFormattedAddress.position = direction.position;
				this.locateAddressOnTheHereMap(hereFormattedAddress);
        this.validatingCoverageOfSelectedAddress(hereFormattedAddress);
			},
			error: () => {
				this._snackbarAlert.snackbarAlertError("¡Ocurrió un error! Intente de nuevo.");
			}
		});
	}

  public locateAddressOnTheHereMap(direction) {
    const addressItem = this.newAddressFormHelper.formatHereToAddress(direction);
    const _location = {
      lat: addressItem.latitude,
      lng: addressItem.longitude,
    } as IBasicLocation;

    if (!this.showHereMap) {
      this.hereMapPlacesService.showMap(
        this.hereMapRef,
        _location
      );
      this.showHereMap = true;
    }
    this.isAddressToCreate = true;
    this.newAddressForm.directionControl.setValue(this.newAddressFormHelper.formatAddress(addressItem));
    this.searchAutocompleteList = [];
    this.hereMapPlacesService.addMarker(_location);

  }

  public locateGeolocationOnTheMap() {
    if (this.geolocationAddress) {
      this.showHereMap = true;
      this.showGoogleMap = true;
      this.isAddressToCreate = true;
      this.newAddressForm.directionControl.setValue(
        this.geolocationGoogleAddress.completedFormattedAddressName
      );
      this.putNewMarker(this.location, ZOOM_BY_DEFAULT);
      this.validatingCoverageOfSelectedAddress(this.geolocationGoogleAddress);
    }
  }

  public locateGeolocationOnTheHereMap() {
    if (this.geolocationAddress) {
      this.isAddressToCreate = true;
      this.locateAddressOnTheHereMap(this.geolocationReverseAddress);
      this.validatingCoverageOfSelectedAddress(this.geolocationReverseAddress);
    }
  }

  private validatingCoverageOfGoogleAddress(
    googleFormattedAddress: IGoogleFormattedAddress
  ) {
    const { location } = googleFormattedAddress;
    this.coverageLocationImplement
      .getValidatedCoverage$({
        latitude: location.lat(),
        longitude: location.lng(),
      })
      .pipe(take(1))
      .subscribe(() => {
        this.addressRequestService.updateRequestParamsFromGoogleFormattedAddress(
          googleFormattedAddress
        );
        this.newAddressForm.validateAddressNickname();
        setTimeout(() => {
          this.wasDoneTheSearch = true;
        }, 200);
      });
  }

  private validatingCoverageOfSelectedAddress(address: any) {
    const { position } = address;
    this.coverageLocationImplement
      .getValidatedCoverage$({
        latitude: position.lat,
        longitude: position.lng,
      })
      .pipe(take(1))
      .subscribe((response) => {
        this.addressRequestService.updateRequestParamsFromAddress(
          address
        );

        this.thereIsNoCoverage = OrderTakerValidator.isOrderTakerEnv()
          ? false
          : !response.current.inCoverage;
        this.newAddressForm.validateAddressNickname();
        this.wasDoneTheSearch = true;
      });
  }

  public locateDirectlyOnTheMap() {
    this.showGoogleMap = true;
    this.isLocatedDirectlyOnMap = true;
    this.isAddressToCreate = false;
    this.isAddressToUpdate = false;
    this.googleMapService.resizeMap();
    if (this.geolocationAddress) {
      const { location } = this.geolocationGoogleAddress;
      this.putNewMarker(
        { lat: location.lat(), lng: location.lng() },
        ZOOM_BY_DEFAULT
      );
      this.validatingCoverageOfSelectedAddress(this.geolocationGoogleAddress);
      this.newAddressForm.directionControl.setValue(
        this.geolocationGoogleAddress.completedFormattedAddressName
      );
    } else {
      const center = this.googleMapService.map.getCenter();
      this.putNewMarker(
        { lat: center.lat(), lng: center.lng() },
        ZOOM_BY_DEFAULT_2
      );
      this.googleMapPlacesService
        .getDetailedAddress$(center)
        .pipe(take(1))
        .subscribe((googleFormattedAddress) => {
          this.validatingCoverageOfSelectedAddress(googleFormattedAddress);
          this.newAddressForm.directionControl.setValue(
            googleFormattedAddress.completedFormattedAddressName
          );
        });
    }
  }

  public locateDirectlyOnTheHereMap() {
    this.isLocatedDirectlyOnMap = true;
    this.isAddressToCreate = false;
    this.isAddressToUpdate = false;
    if (this.geolocationAddress || this.geolocationReverseAddress) {
      this.locateAddressOnTheHereMap(this.geolocationReverseAddress);
      this.newAddressForm.directionControl.setValue(
        this.geolocationReverseAddress.address.label
      );
    } else {
      const positionDefault = POSITION_BY_DEFAULT;
      this.hereMapPlacesService
        .getReverseGeocodeService$(positionDefault)
        .subscribe((place) => {
          this.locateAddressOnTheHereMap(place);
          this.newAddressForm.directionControl.setValue(place.address.label);
        });
    }
  }

  public removeAddress() {
    this.newAddressForm.directionControl.setValue("");
    this.store.dispatch(
      setNewAddressModal({
        newAddressModal: { direction: "", hereAddressMarked: null },
      })
    );
    this.showHereMap = false;
    this.hereMapPlacesService.removeMarker();
    this.hereMapRef.nativeElement.innerHTML = "";
    // this.googleMapService.removeGeolocationControl();
    // this.googleMapService.removePositionControl();
    // this.googleMapService.removeMarker();
  }

  private putNewMarker(location: IBasicLocation, zoom?: number) {
    this.googleMapService.setMapOptions(location, zoom);
    this.googleMapService.removeMarker();
    this.wasDoneTheSearch = false;
    this.googleMapService.putMarker(location, (event) => {
      if (
        (this.wasDoneTheSearch && this.isLoadedReady) ||
        this.isLocatedDirectlyOnMap
      ) {
        this.isLoadingAddressFromMarker = true;
        this.googleMapPlacesService
          .getDetailedAddress$(event.latLng)
          .pipe(take(1))
          .subscribe((googleFormattedAddress) => {
            this.searchAutocompleteList = [];
            this.newAddressForm.directionControl.setValue(
              googleFormattedAddress.completedFormattedAddressName
            );
            this.validatingCoverageOfGoogleAddress(googleFormattedAddress);
            this.isLocatedDirectlyOnMap = false;
            this.wasDoneTheSearch = true;
            this.isLoadingAddressFromMarker = false;
          });
      }
    });
  }

  private validateIfNeedReload() {
    const isSubpathToReload = PATHS_TO_RELOAD.includes(
      getRouterPath(this.router.url)
    );
    const isMainpath = this.router.url === "/";
    if (isSubpathToReload || isMainpath) {
      window.location.reload();
    }
  }

  refreshNewAddressModal() {
    this.appStoreSubscription.selectNewAddressModal$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((newAddressModal) => {
        this.newAddressForm.directionControl.setValue(
          newAddressModal.direction
        );
        if (newAddressModal.hereAddressMarked) {
          this.geolocationReverseAddress = newAddressModal.hereAddressMarked;
          this.locateAddressOnTheHereMap(newAddressModal.hereAddressMarked);
          this.validatingCoverageOfSelectedAddress(newAddressModal.hereAddressMarked);
        }
      });
  }

  responseGeolocalization(){
    this.userGeolocationHelper.getGeolocationPermissions$().subscribe(result => {
      this.loadHereGeolocationAddressAtMyLocation();
      this._geolocationStoreService.cordsGeo = result;
    })
  }
  public clickSwitch() {
    this.isCheckedCoords.isChecked = !this.isCheckedCoords.isChecked;
    if (this.newAddressForm.directionControl.value.length > 0 || this.showHereMap) {
      this.newAddressFormHelper.resetFormToCreateAddress();
      this.removeAddress();
    }
  }
}
