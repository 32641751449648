<div class="row container-price">
	<ng-container *ngIf="productStatus === eProductStatus.available || productStatus === 23 || productStatus === 24; else templateEmpty">
		<div class="col-12 display-vertical-center justify-content-end mt-1" *ngIf="subTotalOffer">
			<div class="container-icons-card">
				<ng-container *ngFor="let image of _globalProductCardService.paymentMethodCard.imageArray; trackBy: trackByImage">
					<img class="icon-card" [src]="image" alt="icono tarjeta inkafarma" />
				</ng-container>
			</div>
			<span class="label-black-xl--1 ml-2 currency text-right">{{ subTotalOffer | currency : "S/ " }}</span>
		</div>
		<ng-container *ngIf="subTotal; else templateRegularPrice">
			<div class="col-12 display-vertical-center justify-content-end mt-1" *ngIf="subTotal">
				<span class="label label-xl--1 currency text-right">{{ subTotal | currency : "S/ " }}</span>
			</div>
			<div class="col-12 display-vertical-center justify-content-end mt-1" *ngIf="regularPrice > 0">
				<fp-product-regular-price [regularPrice]="regularPrice" fontSize="--1"> </fp-product-regular-price>
			</div>
		</ng-container>
		<ng-template #templateRegularPrice>
			<div class="col-12 display-vertical-center justify-content-end mt-1" *ngIf="regularPrice > 0">
				<span class="label label-xl--1 text-dark currency text-right">{{ regularPrice | currency : "S/ " }}</span>
			</div>
		</ng-template>
	</ng-container>
	<ng-template #templateEmpty>
		<span class="label-black label-black-lg-1 label-black-xl text-dark">---</span>
	</ng-template>
</div>
