import { Injectable } from "@angular/core";
import { OrderTakerValidator } from "@validators/order-taker.validator";
import { BehaviorSubject } from "rxjs";
import { Observable } from "rxjs/internal/Observable";
import { AddressItem } from "src/app/shared/models/address/address.model";
import { IGoogleFormattedAddress } from "../../custom-agm-core/services/google-map-places.service";
import { NewAddressFormHelperService } from "../address-forms/new-address-form-helper.service";
import { AddressRequest } from "../models/address-request";

@Injectable({
  providedIn: "root",
})
export class AddressRequestService {
  private requestParamsSubject: BehaviorSubject<AddressRequest> =
    new BehaviorSubject<AddressRequest>(null);
  public requestParams$: Observable<AddressRequest> =
    this.requestParamsSubject.asObservable();

  private _requestParams = this.getNewRequestParams();

  public get requestParams() {
    return this._requestParams;
  }
  public set requestParams(params: AddressRequest) {
    this._requestParams = params;
  }

  constructor(private newAddressFormHelper: NewAddressFormHelperService) {}

  private getNewRequestParams() {
    return new AddressRequest();
  }

  public resetRequestParams() {
    this._requestParams = this.getNewRequestParams();
  }

  public updateRequestParamsFromAddressItem(address: AddressItem) {
    const googleFormattedAddress =
      this.newAddressFormHelper.getGoogleFormattedAddress(address);
    this.requestParams.number = googleFormattedAddress.streetNumber;
    this.requestParams.country = googleFormattedAddress.country;
    this.requestParams.district = googleFormattedAddress.district;
    this.requestParams.street = googleFormattedAddress.formattedBasicAddress;
    this.requestParams.city = googleFormattedAddress.province;
    this.requestParams.latitude = googleFormattedAddress.location.lat();
    this.requestParams.longitude = googleFormattedAddress.location.lng();
  }

  public updateRequestParamsFromAddressItemV2(address: AddressItem) {
    this.requestParams.number = address.number;
    this.requestParams.country = address.country;
    this.requestParams.district = address.district;
    this.requestParams.street = address.street;
    this.requestParams.city = address.city;
    this.requestParams.latitude = address.latitude;
    this.requestParams.longitude = address.longitude;
  }

  public updateRequestParamsFromGoogleFormattedAddress(
    googleFormattedAddress: IGoogleFormattedAddress
  ) {
    this.requestParams.number = googleFormattedAddress.streetNumber;
    this.requestParams.country = googleFormattedAddress.country;
    this.requestParams.district = googleFormattedAddress.district;
    this.requestParams.street = googleFormattedAddress.formattedBasicAddress;
    this.requestParams.city = googleFormattedAddress.province;
    this.requestParams.latitude = googleFormattedAddress.location.lat();
    this.requestParams.longitude = googleFormattedAddress.location.lng();
  }

  public updateRequestParamsFromAddress(detailedAddress: any) {
    if (OrderTakerValidator.isOrderTakerEnv()) {
      this.requestParamsSubject.next(detailedAddress);
    }
    const { address } = detailedAddress;
    this.requestParams.id = detailedAddress.id ? detailedAddress.id : "";
    this.requestParams.number = address?.houseNumber ? address.houseNumber : "";
    this.requestParams.country = address.countryName ? address.countryName : ""; // Perú
    this.requestParams.district = address.city ? address.city : ""; // Santiago de Surco
    this.requestParams.street = (address?.street && address?.street?.trim() !== "")
      ? address.street
      : address?.label ?? "";
    this.requestParams.city = address.county ? address.county : ""; // Lima
    this.requestParams.latitude = detailedAddress.position.lat;
    this.requestParams.longitude = detailedAddress.position.lng;
    this.requestParams.state = address.state ? address.state : ""; // Lima
  }
}
