import { IEnvironment } from "@models/app/environment.model";
import { EBrand } from "@parameters/access/brand.parameter";

export const environment: IEnvironment = {
	// Configs
	production: true,
	show_logs: false,
	brand: EBrand.mifarma,

	// Firebase
	firebase: {
		apiKey: "AIzaSyDVgyPcYZit04sbOFLuFrItfm5U9znhwKM",
		authDomain: "mifarma-prod.firebaseapp.com",
		databaseURL: "https://mifarma-prod.firebaseio.com/",
		projectId: "mifarma-prod",
		storageBucket: "mifarma-prod.appspot.com",
		messagingSenderId: "237409701725"
	},
	firebase2: {
		apiKey: "AIzaSyC2fWm7Vfph5CCXorWQnFqepO8emsycHPc",
		authDomain: "inkafarma-prod.firebaseapp.com",
		databaseURL: "https://inkafarma-prod.firebaseio.com/",
		projectId: "inkafarma-prod",
		storageBucket: "inkafarma-prod.appspot.com",
		messagingSenderId: "140898732349"
	},
	app2: {
		databaseURL: "https://inkatracker-lite-prod.firebaseio.com/"
	},

	// Contentful
	CONTENTFUL_CONFIG: {
		space: "buvy887680uc",
		accessToken: "2mzxjD0YGBnoTP_bgiJPs5azplmO6bAPEnIjeY6LSvI",
		environment: "master"
	},

	api_gateway: "https://5doa19p9r7.execute-api.us-east-1.amazonaws.com/MMMFPRD",
	amazon_s3: "https://mifarma-resources.s3-us-west-2.amazonaws.com",
	visa: "https://api.mifarma.com.pe",

	urlMock: "https://demo0141199.mockable.io",
	api_gateway_04: "https://promomultimarcaws.fapeti.com/cliente_mifarma",
	api_gateway_05: "https://rqwks0lv25.execute-api.us-east-1.amazonaws.com/MFPRD",

	// Cuponero
	api_gateway_07: "https://ulmtgv7wxa.execute-api.us-east-1.amazonaws.com/PRD/v1/api",

	urlToken: "https://securetoken.googleapis.com/v1/token",
	keyCupon:
		"AFxQ4_oHIDp5BEJ_jHaSng3yY_YdjrDT7UhCf_QkgrJwi6zaVkyQg1RSAl-nJwncuOmmk8ULEse58z_zKyoXxXAsp6cbEbFPZ8dz04l0PIxiu6wre99iMwNYYd83dwDOLpZKnRza1tfqDcL1eL89droA15T_daSvczoihbjsRzNihXpFFcS1-DtKgPFNAcg6Roo2Din0Ugxf",
	api_gateway_notifications: "https://6l2uev361h.execute-api.us-east-1.amazonaws.com/PROD",
	s3_aws_endpoint: "https://s3-us-west-2.amazonaws.com/menu-web-2.0/CI",
	algolia: {
		appId: "O74E6QKJ1F",
		apiKey: "b65e33077a0664869c7f2544d5f1e332",
		indexName: "products",
		urlSync: false
	},
	api_gateway_campaign: "https://wp2mo78am0.execute-api.us-west-2.amazonaws.com/PROD",

	// consulta de stock
	api_gateway_03: "https://id7qys57b8.execute-api.us-east-1.amazonaws.com/MFPRD",
	api_gateway_02: "https://wp2mo78am0.execute-api.us-west-2.amazonaws.com/PROD",
	algolia_for_consulta_stock: {
		appId: "O74E6QKJ1F",
		apiKey: "b65e33077a0664869c7f2544d5f1e332",
		indexName: "products_farmacias",
		urlSync: false
	},

	api_inkaclub: "https://promomultimarcaws.fapeti.com",
	api_seller: "http://sellercenterci01.backend.cindibyinkafarma.com",

	pathIssuu: "https://www.yumpu.com/es/embed/view/",

	recaptcha: "6LeDYJAiAAAAAAH1lpyvstK32CY_ahovwv7xFR03",
	api_brain: "https://s2.braindw.com/tracking/track?HASH=mifarma_produccion_hx27n",
	apigee: {
		url: "https://apiprod.farmaciasperuanas.pe",
		token: "anZnNmxuNmxla0plY1BVUm1jM042d00xZFlhbU9Zb2ZUaFJoM0dmQUIyZHFNUEFBOnA5cDRHeXd1V2RMaXBhaTg3ZG1yWXU2dHg5M0JBdUFsTDFoR0ZteG5LZktaVWl5cTV6V1BTRW9JSUI5VUxqWkc=",
		customerOrder: "customer-order-mf",
		tokenControlled:"ZUFtUUd2TDIzWEx0eGNQUHFJZDlKenE1UGlOY0FlMTVLY1djaUdFN2RmOVZDQkhDOnR1bEdhbGU2NFZKMDdQZGNuR0ZDb0w4c2tHaThlQ0RXTUZlaEFQSHdiMXB5alk1eTJrNThHc0h5SEtXc2NOc0I=",
		token_cookie: "SjJDckdtOUZsYjVTQ0l4R2FrQ01qNWJBRlRvcDBneDFyWlZ1dzg3Y2tlSTNwQk1GOnRuR0NBd2lDVlhFampHbGFpZDZTZExTSUtCVW1QdE9EeEFEUlIzVEQwUW5aWnBqNTE3SzRXWFJHQ2NJYlUzdFc=",
		tokenBase64Encode: "RlVwNWlIODBOZ2V2UGRtbXZyRmQ5b01Bbm1mNWl4aTNsWHNETHBHZTVDa0VJTUNNOm41eldVQU1JWkdpa1pObE1KWGNzSVRYODJCUWJZMHFqRVpDaEtpNnNWSUUzU3VMT1dwNlhxWDRjVE5JbW5jRHU=",
	},
	// super discount
	super_discount: "https://apiprod.farmaciasperuanas.pe/loyalty/highdiscount/v1/customer/",
	apigee_auth_url: "https://apiprod.farmaciasperuanas.pe/oauth/access-token",
	apigee_auth_token:
		"MTh4cWJxS1Flbkc5VmUzeldkOTVWdlprQXA5RmlSRnNJVWdUY1cxVVJjOEM5SkU0OllyS2ZLQmZ3N0p5STg5bVlIc2o1Mzk5UHdlY21veXBXbkhMV0tWQ2tHSEp5bllxV0dsSEFvUVYzMHpBeEdkd0Y=",
	orderTaker: false,
	// missions
	api_gateway_missions: "https://rqwks0lv25.execute-api.us-east-1.amazonaws.com/MFPRD",
	api_filter_algolia: "https://15W622LAQ4-dsn.algolia.net/1/indexes/",
	algolia_filter_categories: {
		X_Algolia_API_Key: "c5104f7d7cb461b69603485ec9d17b1c",
		X_Algolia_Application_Id: "15W622LAQ4"
	},
	/** cryptoKey */
	cryptoKey: "wK3HiJ",
	/** Salesforce */
	salesforceInka: "fape_inkafarma_prod",
	salesforceMifa: "fape_mifarma_prod",
	// Google Secret Manager
	secretManager: {
		url: "https://apiprod.farmaciasperuanas.pe/fp-arq-secret-manager/api/v1",
		secretId: "secret-arq-maps-web-mf-prd",
		appKey: "c2VjcmV0TWFuYWdlcklk",
		apiKeyHere: ""
	}
};
