<div
	class="card product h-100"
	[ngClass]="{ 'border-neutral-30': type == 'complementary' }"
	[id]="product.id"
	[attr.data-product-brand]="product?.brand"
	[attr.data-product-category]="product?.category"
>
	<div *ngIf="product.alertTagText && product.alertTagText?.length > 0" class="tag-product red px-2 py-1">
		<div class="tag-product-imagen mr-1">
			<fp-icon iconSize="16" iconName="alert-white" class="vertical-align-middle"></fp-icon>
		</div>
		<div class="tag-text text-tag-mini" [innerText]="product.alertTagText"></div>
	</div>

	<fp-link
		[linkRouter]="['/' + productPath, product.slug, product.id]"
		(linkActive)="registerEventAnalyticsAndValidateRedirectToAlgolia()"
	>
		<div class="row align-items-center">
			<div class="col-12 display-center">
				<div class="product-image-container">
					<fp-product-image
						(click)="sectionID ? addEventBrain() : ''"
						[productStatus]="productStatus"
						[isProductPack]="!!product.productPack"
						[image]="productPresentation?.image"
						[imageName]="product.name"
						[isSeller]="product.isFromSeller"
						class="w-100"
						imageSize="192px"
					>
					</fp-product-image>

					<div class="tag-product-image" *ngIf="product.tagImageUrl">
						<fp-lazy-wrapper (click)="sectionID ? addEventBrain() : ''" [lazy]="product?.tagImageUrl">
							<img #fpImg alt="tag" />
						</fp-lazy-wrapper>
					</div>
				</div>
			</div>

			<div class="col-12 mt-2">
				<fp-product-card-information
					(click)="sectionID ? addEventBrain() : ''"
					[name]="product.name"
					[description]="productPresentation?.description"
				>
				</fp-product-card-information>
			</div>
			<div class="tags-container col-12 mt-2 d-flex flex-wrap tags">
				<fp-tag-seller-brand
					*ngIf="product.isGeneric"
					(click)="sectionID ? addEventBrain() : ''"
					[iconSize]="'16'"
					[iconName]="'pill'"
					[text]="'Genérico'"
				>
				</fp-tag-seller-brand>

				<fp-tag-seller-brand
					(click)="sectionID ? addEventBrain() : ''"
					*ngIf="showPrescription"
					[iconName]="iconPrescription"
					[text]="product.prescription"
					[shortTag]="shortTagPrescription"
				>
				</fp-tag-seller-brand>
				
				<fp-tag-seller-brand
					(click)="sectionID ? addEventBrain() : ''"
					[iconSize]="'16'"
					[iconName]="iconBrand"
					[text]="textTagSeller"
					[shortTag]="shortTagBrand"
				>
				</fp-tag-seller-brand>
			</div>
			<div class="col-12 mt-2">
				<fp-product-card-price
					*fpBrandAccess="[brand.inkafarma]"
					(click)="sectionID ? addEventBrain() : ''"
					[regularPrice]="productPresentation?.regularPrice"
					[price]="productPresentation?.price"
					[offerPrice]="productPresentation?.offerPrice"
					[productStatus]="productStatus"
					[crossOutPL]="productPresentation?.crossOutPL"
				>
				</fp-product-card-price>
				<fp-product-card-price-mifa
					*fpBrandAccess="[brand.mifarma]"
					(click)="sectionID ? addEventBrain() : ''"
					[regularPrice]="productPresentation?.regularPrice"
					[price]="productPresentation?.price"
					[offerPrice]="productPresentation?.offerPrice"
					[productStatus]="productStatus"
					[crossOutPL]="productPresentation?.crossOutPL"
				>
				</fp-product-card-price-mifa>
				<br />
				<div class="d-flex gap-3">
					<ng-container>
						<div>
							<ng-container *ngIf="isOrderTaker; else ecommerceTemplateRad">
								<fp-icon
									iconSize="24"
									[iconName]="isRAD ? (radHaveStock ? 'icon_rad_on' : 'icon_rad_off') : 'icon_rad_on'"
								></fp-icon>
							</ng-container>
							<ng-template #ecommerceTemplateRad>
								<fp-icon iconSize="24" [iconName]="radHaveStock ? 'icon_rad_on' : 'icon_rad_off'"></fp-icon>
							</ng-template>
						</div>
						<div>
							<ng-container *ngIf="isOrderTaker; else ecommerceTemplateRet">
								<fp-icon
									iconSize="24"
									[iconName]="isRAD ? 'icon_ret_on' : retHaveStock ? 'icon_ret_on' : 'icon_ret_off'"
								></fp-icon>
							</ng-container>
							<ng-template #ecommerceTemplateRet>
								<fp-icon
									iconSize="24"
									[iconName]="!isProductSeller && retHaveStock ? 'icon_ret_on' : 'icon_ret_off'"
								></fp-icon>
							</ng-template>
						</div>
					</ng-container>
				</div>
			</div>
		</div>
	</fp-link>

	<fp-product-favorite
		class="product-favorite"
		[favorite]="product.favorite"
		(addFavorite)="addToFavorite()"
		(removeFavorite)="removeFromFavorite()"
	>
	</fp-product-favorite>
	<br />

	<div class="row button-add-content m-lg-0">
		<div class="col p-lg-0" *ngIf="!productInShoppingCart">
			<fp-button-primary
				class="add-product"
				buttonDisplay="block"
				[buttonSize]="'regular'"
				[buttonDisabled]="productStatus === eProductStatus.unavailable && product.isFromSeller"
				(click)="addProductToShoppingCart()"
			>
				Agregar al carrito
			</fp-button-primary>
		</div>
		<div class="col p-lg-0" *ngIf="productInShoppingCart">
			<fp-product-add-button
				class="card"
				[productInShoppingCart]="productInShoppingCart"
				[quantityLimit]="productPresentation?.maxUnitSale"
				[quantity]="productQuantity"
				[productStatus]="productStatus"
				[product]="product"
				(newQuantity)="updateQuantity($event)"
				(addProduct)="addProductToShoppingCart(true)"
				(deleted)="doDeleted()"
			>
			</fp-product-add-button>
		</div>
	</div>
</div>
