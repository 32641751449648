import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { ChooseAStoreModalComponent } from './choose-a-store-modal/choose-a-store-modal.component';
import { WhoPickUpModalComponent } from './who-pick-up-modal/who-pick-up-modal.component';
import { PickUpImplementsModule } from '../pick-up-implements/pick-up-implements.module';
import { PickUpHelpersModule } from '../pick-up-helpers/pick-up-helpers.module';
import { PickUpFormsModule } from '../pick-up-forms/pick-up-forms.module';
import { PickUpComponentsModule } from '../pick-up-components/pick-up-components.module';
import { PickUpServicesModule } from '../pick-up-services/pick-up-services.module';
import { PickUpStoresModule } from '../pick-up-stores/pick-up-stores.module';
import { ContainersModule } from '../../containers/containers.module';
import { ButtonsModule } from 'src/app/core/generic/buttons/buttons.module';
import { FormControlsModule } from '../../form-controls/form-controls.module';
import { ChooseAStoreSidenavComponent } from './choose-a-store-sidenav/choose-a-store-sidenav.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { IconsModule } from 'src/app/core/generic/icons/icons.module';
import { ChooseAStoreResponsiveModalComponent } from './choose-a-store-responsive-modal/choose-a-store-responsive-modal.component';
import { CheckoutDirectivesModule } from 'src/app/business/checkout/checkout-directives/checkout-directives.module';
import { BasicSkeletonModule } from '../../skeletons/basic-skeleton/basic-skeleton.module';
import { NearbyBoticaModalComponent } from './nearby-botica-modal/nearby-botica-modal.component';
import { NearbyBoticaResponsiveModalComponent } from './nearby-botica-responsive-modal/nearby-botica-responsive-modal.component';
import { CheckoutTypeOfDeliveryFormService } from 'src/app/business/checkout/checkout-forms/checkout-type-of-delivery-form.service';
import { CheckoutTypeOfDeliveryFormStoreService } from 'src/app/business/checkout/checkout-stores/checkout-type-of-delivery-form-store.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StoresForPickUpStoreService } from '../pick-up-stores/stores-for-pick-up-store.service';
import { ChooseAStoreModalV2Component } from './choose-a-store-modal-v2/choose-a-store-modal-v2.component';
import { ChooseAStoreResponsiveModalV2Component } from './choose-a-store-responsive-modal-v2/choose-a-store-responsive-modal-v2.component';
import { ComponentsModule } from 'src/app/core/components/components.module';

const COMPONENTS = [
  ChooseAStoreSidenavComponent,
];

const ENTRY_COMPONENTS = [
  ChooseAStoreModalComponent,
  WhoPickUpModalComponent,
  ChooseAStoreResponsiveModalComponent,
  NearbyBoticaModalComponent,
  NearbyBoticaResponsiveModalComponent,

  ChooseAStoreModalV2Component,
  ChooseAStoreResponsiveModalV2Component,
];

const EXTERNAL_MODULES = [
  ContainersModule,
  ButtonsModule,
  PickUpServicesModule,
  FormControlsModule,
  ReactiveFormsModule,
  PickUpImplementsModule,
  MatSidenavModule,
  IconsModule,
  CheckoutDirectivesModule,
  BasicSkeletonModule,
  ComponentsModule
];

@NgModule({
  declarations: [
    ...ENTRY_COMPONENTS,
    ...COMPONENTS,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormControlsModule,
    PickUpImplementsModule,
    PickUpHelpersModule,
    PickUpFormsModule,
    PickUpComponentsModule,
    PickUpStoresModule,
    EXTERNAL_MODULES,
  ],
  exports: [
    ...ENTRY_COMPONENTS,
    ...COMPONENTS,
  ],
  providers : [
    CheckoutTypeOfDeliveryFormService,
    CheckoutTypeOfDeliveryFormStoreService,
    StoresForPickUpStoreService,
    { provide: MAT_DIALOG_DATA, useValue: [] }
  ]
})
export class PickUpModalsModule { }
