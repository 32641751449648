import { OrderTakerValidator } from "@validators/order-taker.validator";

export class SearchLandingAlgoliaRequest {
  params: string;
  facets? : Array<string> = [];
  facetFilters?: Array<any> = [];
  maxValuesPerFacet?: number;
  offset?: number;
  length?: number;
  analyticsTags?: string[];
  clickAnalytics?: boolean;


  constructor(query: string, facets: Array<string>, selectedCategories?: Array<any>, length? , max?) {
    const Filters: string[][] = OrderTakerValidator.isOrderTakerEnv() ? [["channel:ALL", "channel:CALL"]] : [["channel:ALL", "channel:DIGITAL"]];
    const FiltersString: string[][] = Filters;
    selectedCategories = selectedCategories ? selectedCategories.concat(FiltersString) : null;
    this.params = "query=" + encodeURIComponent(query);
    this.facets = facets;
    this.facetFilters = selectedCategories || FiltersString;
    this.length = length;
    this.offset = 0;
    this.maxValuesPerFacet = max;
  }
}


export class SearchLandingMultiIndexRequest {
  indexName: string;
  params: string;
  facets?: Array<string> = [];
  facetFilters?: Array<any> = [];
  maxValuesPerFacet?: number;
  offset?: number;
  length?: number;
  analyticsTags?: string[];
  clickAnalytics?: boolean;

  constructor(
    indexName: string, 
    query: string, 
    facets: Array<string>, 
    selectedCategories?: Array<any>, 
    length?: number, 
    max?: number
  ) {
    const Filters: string[][] = OrderTakerValidator.isOrderTakerEnv() ? [["channel:ALL", "channel:CALL"]] : [["channel:ALL", "channel:DIGITAL"]];
    const FiltersString: string[][] = Filters;
    selectedCategories = selectedCategories ? selectedCategories.concat(FiltersString) : null;
    this.indexName = indexName;
    this.params = "query=" + encodeURIComponent(query);
    this.facets = facets;
    this.facetFilters = selectedCategories || FiltersString;
    this.length = length;
    this.offset = 0;
    this.maxValuesPerFacet = max;
  }
}



export class HandleFilterAlgolioRequest {
  params: string;
  facetFilters: Array<any> = [];
  facets?: [];

  constructor(query: string, selectedCategories: Array<any>){
    this.params = "query="+query;
    this.facetFilters = selectedCategories;
  }
}

