import { Injectable } from '@angular/core';
import { PlacesSearch, ReverseAddress } from 'src/app/core/modules/here-maps/interfaces/places-search';

import type { IGeolocationPermission } from 'src/app/shared/geolocation/geolocation.service';

import { CookieService } from 'src/app/shared/storage/cookie.service';

@Injectable()
export class GeolocationStoreService {

  constructor(private readonly cookieService: CookieService) { }

  public clearAddressInfo() {
    this.cookieService.removecookie("address_country");
    this.cookieService.removecookie("address_city");
    this.cookieService.removecookie("address_state");
    this.cookieService.removecookie("address_postal_code");
  }

  public get cordsGeo() {
    const dataStr = sessionStorage.getItem("coordsGeo");
    return JSON.parse(dataStr) as IGeolocationPermission;
  }
  public set cordsGeo(result: IGeolocationPermission) {
    const coords = {
      latitude: result?.coords?.latitude || 0,
      longitude: result?.coords?.longitude || 0,
      status: result?.status
    }
    sessionStorage.setItem("coordsGeo", JSON.stringify(coords));
  }
  public get provinceGeo() {
    const dataStr = sessionStorage.getItem("provinceGeo");
    return dataStr;
  }
  public set provinceGeo(province: string) {
    if(!province) return;

    sessionStorage.setItem("provinceGeo", province);
  }

  public set addressInfo(data: PlacesSearch<ReverseAddress>) {
    if(!data) return;

    this.cookieService.setCookie("address_country", data.address.countryName);
    this.cookieService.setCookie("address_city", data.address.city);
    this.cookieService.setCookie("address_state", data.address.state);
    this.cookieService.setCookie("address_postal_code", data.address.postalCode);
  }

  public set addressLoaded(value: string) {
    sessionStorage.setItem("address_loaded", value);
  }

  public get availableLoc() {
    const data = sessionStorage.getItem("availableLoc");

    return JSON.parse(data);
  }

  public set availableLoc(available: boolean) {
    if(available) sessionStorage.setItem("availableLoc", 'true');
    else sessionStorage.removeItem("availableLoc")
  }
}
