import { Injectable } from '@angular/core';
import { ProductManagerService } from './product-manager.service';
import { FromProductToProductShoppingCartRequest, ProductShoppingCartRequest } from '../../../../shared/models/shopping-cart/product-shopping-cart-request.model';
import { Observable } from 'rxjs';
import { ProductClientService } from '../../../../shared/service/product/product-client.service';
import { MatDialog } from '@angular/material/dialog';
import { NotificationDiscountModalComponent } from 'src/app/business/cart/components/notification-discount-modal/notification-discount-modal.component';
import { ISkuTalonOneList } from '@models/user/user';
import { OrderTakerValidator } from '@validators/order-taker.validator';
import { IProductAliviamed } from '@models/product-default/product-default.interface';

@Injectable({
	providedIn: "root"
})
export class ProductImplementService {
	constructor(
		private _productManager: ProductManagerService,
		private _productClient: ProductClientService,
		private matDialog: MatDialog
	) {}

	public getProductShoppingCart$(productId: string): Observable<ProductShoppingCartRequest> {
		return this._productManager.getProduct$(productId);
	}

	public getProductShoppingCart(productId: string): ProductShoppingCartRequest {
		return this._productManager.getProduct(productId);
	}

	public isProductInShoppingCart(productId: string): boolean {
		return this._productManager.isProductInShoppingCart(productId);
	}
	public isAliviamedConsultationInShoppingCart(): boolean {
		return this._productManager.isAliviamedConsultationInShoppingCart();
	}
	public addProduct(
		id: string,
		quantity: number,
		presentation: number,
		sellerId: string,
		sellerName: string,
		productPack?: boolean,
		type?: string,
		productAliviamed?: IProductAliviamed
	) {
		let requestTalon: ISkuTalonOneList = {
			skuTalonOneList: [],
			channel: "",
			dni: ""
		};
		const OtClient = JSON.parse(localStorage.getItem("OT_CLIENT_DATA"));
		const channel = OrderTakerValidator.isOrderTakerEnv() ? "WEB-TOMADOR" : "WEB-ECOMMERCE";
		requestTalon.skuTalonOneList.push({ price: 0, sku: id, quantity: quantity });
		requestTalon.channel = channel;
		requestTalon.dni = OtClient?.identityDocumentNumber || "";

		this.callServicesValidation(requestTalon);
		const productRequest = new FromProductToProductShoppingCartRequest(
			id,
			quantity,
			presentation,
			sellerId,
			sellerName,
			productPack,
			type,
			productAliviamed
		);
		this._productManager.addProduct(productRequest);
	}

	public deleteProduct(productId: string) {
		this._productManager.deleteProduct(productId);
	}

	public updateProduct(id: string, quantity: number, presentation: number, sellerId = "", sellerName = "", productPack?: boolean) {
		const productRequest = new FromProductToProductShoppingCartRequest(id, quantity, presentation, sellerId, sellerName, productPack);
		this._productManager.updateProduct(productRequest);
	}

	public addToFavoriteProduct(id: string) {
		return this._productClient.postAddFavorite(id);
	}

	public removeFromFavoriteProduct(id: string) {
		return this._productClient.postRemoveFavorite(id);
	}

	public callServicesValidation(request: ISkuTalonOneList) {
		return this._productClient.callServicesNotification(request).subscribe((resp) => {
			if (resp.length > 0) {
				this.matDialog.open(NotificationDiscountModalComponent, {
					width: "450px",
					disableClose: true,
					data: resp
				});
			}
		});
	}
}
