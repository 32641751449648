import { Injectable } from "@angular/core";
import { EBrand } from "@parameters/access/brand.parameter";
import { AppStoreService } from "@stores/app/app-store.service";
import { ClientStoreService } from "@stores/client/client-store.service";
import { UserTokenStoreService } from "@stores/user/user-token-store.service";
import { OrderTakerValidator } from "@validators/order-taker.validator";
import { BehaviorSubject, Observable, of } from "rxjs";
import { switchMap, tap } from "rxjs/operators";
import { GlobalProductCardService } from "src/app/core/modules/product-card/service/global-product-card.service";
import { ShoppingCartResponse } from "src/app/shared/models/shopping-cart/shopping-cart-response.model";
import { CartClientService } from "src/app/shared/service/cart/cart-client.service";
import { ShoppingCartRequest } from "../../models/shopping-cart/shopping-cart-request.model";
import { ShoppingListStoreService } from "../../stores/shopping-list/shopping-list-store.service";
import { ShoppingCartLoaderService } from "./shopping-cart-loader.service";
import { ShoppingCartRequestService } from "./shopping-cart-request.service";
import { RetValidateCartService } from "../cart/ret-validate-cart.service";
import { SelectDataStoreService } from "src/app/business/office/store/select-data-store.service";
import { StoresForPickUpStoreService } from "src/app/core/modules/pick-up-in-store/pick-up-stores/stores-for-pick-up-store.service";
import { AddressListStoreService } from "src/app/core/modules/address-manager/address-stores/address-list-store.service";
import { NAVIGATION_URL } from "@parameters/global";
import { BUSINESS_PATHS } from "@parameters/business.parameter";
import { IDeliveryTimeStore } from "src/app/business/checkout/models/delivery-time-store.interface";
import { LS_ADDRESS_SELECTED, LS_ID_FAVORITE_ADDRESS } from "../local-storage/constants/address.constant";
import { ProductSellersShoppingCartBase } from "src/app/core/modules/product-card/models/product-shopping-cart/product-seller-shopping-cart-base.model";
import { ModalsCheckoutFacadeService } from "./../../../business/checkout/checkout-services/modals-checkout-facade.service";
import { CART_CODE, CART_CODES_ACTION, CART_CODE_ACTION } from "src/app/business/checkout/parameters/finish-purchase-code.constant";
import { TYPE_NOTIFICATION_ACTION } from "src/app/business/cart/components/notification-sku/types-notification.enum";
import { Router } from "@angular/router";
import { TaggingService } from "src/app/atm-services/tagging.service";
import { AddressListManagerService } from "src/app/core/modules/address-manager/address-services/address-list-manager/address-list-manager.service";
// import { MatDialogRef } from "@angular/material/dialog";
// import { CheckoutOrderSummaryResponsiveComponent } from "src/app/business/checkout/checkout-components/custom-components/checkout-order-summary-responsive/checkout-order-summary-responsive.component";

interface IActionForCartCode {
	[key: string]: {
		runAction: (payload: any) => any;
	};
}

interface IActionPayload {
	shoppingCartResponse: ShoppingCartResponse;
	buynow: boolean;
}

@Injectable()
export class ShoppingCartImplementService {
	checkout = false;
	prepareForNotification: BehaviorSubject<boolean> = new BehaviorSubject(false);
	notificationCode: BehaviorSubject<string> = new BehaviorSubject("");
	shoppingCartId = null;
	actionsByCartCode = {
		[CART_CODE_ACTION.SKU_VISIBLE_CODE]: {
			runAction: (payload: any) => this.actionForSkuNoVisible(payload)
		},
		[CART_CODE_ACTION.DUPLICATE_PRODUCTS]: {
			runAction: (payload: any) => this.actionForDuplicateProducts(payload)
		},
		[CART_CODE_ACTION.ONLINE_PAYMENT_RET]: {
			runAction: (payload: any) => this.actionForOnlinePaymenRet(payload)
		}
	};

	carRequest: any;
	private lastCart: any;

	public hasProductsSKU$ = new BehaviorSubject<boolean>(false);

	get notificationKey() {
		return this.notificationCode.getValue();
	}

	get lastCartResponse() {
		return this.lastCart;
	}

	constructor(
		private _cartClient: CartClientService,
		private _shoppingCartRequest: ShoppingCartRequestService,
		private _globalProductCardService: GlobalProductCardService,
		private _userTokenStoreService: UserTokenStoreService,
		private _shoppingListStore: ShoppingListStoreService,
		private clientStore: ClientStoreService,
		private appStore: AppStoreService,
		private _loaderService: ShoppingCartLoaderService,
		private retCartService: RetValidateCartService,
		private _selectDataStoreService: SelectDataStoreService,
		private storesForPickUpStore: StoresForPickUpStoreService,
		private AddressListStoreService: AddressListStoreService,
		private _modalsCheckoutFacadeService: ModalsCheckoutFacadeService,
		private router: Router,
		private _addressListManagerService: AddressListManagerService // public dialogRef: MatDialogRef<CheckoutOrderSummaryResponsiveComponent>,
	) {}

	public getShoppingCart(): Observable<ShoppingCartResponse> {
		const stringFromStorage = this.appStore.getStorageItem("CDTS");
		let jsonFormatOfForm;
		if (stringFromStorage) {
			jsonFormatOfForm = JSON.parse(stringFromStorage) as IDeliveryTimeStore;
		}
		const navigationUrl = this.appStore.getStorageItem(NAVIGATION_URL);
		if (navigationUrl === "/" + BUSINESS_PATHS.CHECKOUT) {
			this.checkout = true;
		} else {
			this.checkout = false;
		}
		let drugstoreWharehouseId;
		let addressId;
		let favoriteAddress = this.AddressListStoreService.favoriteAddress?.id;
		let currentSelectedAddress = this.AddressListStoreService.currentSelectedAddressFromStore?.id;
		const selectAdress = JSON.parse(this.appStore.getStorageItem(LS_ADDRESS_SELECTED));
		/** add logic to send favoriteId to services or localstorage */
		let favoriteId =
			this.getLocalStoragefavoriteId() && this.getLocalStoragefavoriteId() !== undefined
				? this.getLocalStoragefavoriteId()
				: favoriteAddress || currentSelectedAddress;
		favoriteId = currentSelectedAddress ? currentSelectedAddress : favoriteId;
		if (OrderTakerValidator.isOrderTakerEnv()) {
			// const drugstoreSelected = JSON.parse(this.appStore.getStorageItem('drugstoreSelected'));
			const drugstoreSelected = this._selectDataStoreService.nearbyStore;
			drugstoreWharehouseId = this.retCartService.isRADProcess ? null : drugstoreSelected?.drugstoreWareHouseId;
			addressId = this.retCartService.isRADProcess ? this.getLocalStoragefavoriteId() : "-1";
		} else {
			drugstoreWharehouseId = this.retCartService.isRADProcess
				? null
				: this.storesForPickUpStore.selectedDrugstoreFromStorage?.drugstoreWareHouseId;
			addressId =
				this.retCartService.isRADProcess || selectAdress ? (isNaN(parseInt(favoriteId)) ? "-1" : parseInt(favoriteId)) : "-1";
		}

		if (this.retCartService.isRADProcess || selectAdress) {
			const deliveryType =
				this._shoppingCartRequest.shoppingCartRequest.deliveryType === undefined
					? jsonFormatOfForm?.serviceType
					: this._shoppingCartRequest.shoppingCartRequest.deliveryType;
			this._shoppingCartRequest.shoppingCartRequest.drugstoreIdSelected = null;
			this._shoppingCartRequest.shoppingCartRequest.deliveryType = this.checkout ? deliveryType : null;
			this.retCartService.storeProcessType("RAD");
		} else {
			const drugstoreSelected = JSON.parse(this.appStore.getStorageItem("drugstoreSelected"));
			const drugstoreOTSelected = JSON.parse(this.appStore.getStorageItem("OT_STORE_SELECTED"));
			// const drugstoreOTSelected = this._selectDataStoreService.nearbyStore
			this._shoppingCartRequest.shoppingCartRequest.drugstoreIdSelected = OrderTakerValidator.isOrderTakerEnv()
				? drugstoreOTSelected?.localCode
				: drugstoreSelected?.localCode;
			this._shoppingCartRequest.shoppingCartRequest.deliveryType = "RET";
			this.retCartService.storeProcessType("RET");
		}

		if (OrderTakerValidator.isOrderTakerEnv() && this.appStore.brandAtention == EBrand.mifarma) {
			const stPricePurse = JSON.parse(this.appStore.getStorageItem("pricePurse"));
			this._shoppingCartRequest.shoppingCartRequest.noPursePrice = !stPricePurse;
		}
		this.updateDiscountsList();
		const currentUser = this._userTokenStoreService.currentUser;
		const shoppingCartListLocal = this._shoppingListStore.getLocalStorageShoppingCartList();
		this._shoppingCartRequest.shoppingCartRequest.addressId = addressId;
		this._shoppingCartRequest.shoppingCartRequest = this.getRequestClone();
		this._shoppingCartRequest.shoppingCartRequest.products = shoppingCartListLocal;
		const shoppingCartRequest = this._shoppingCartRequest.shoppingCartRequest;
		shoppingCartRequest.defaultPaymentMethod = {
			paymentMethodCardType: this._globalProductCardService.paymentMethodCardType,
			paymentMethodName: this._globalProductCardService.paymentMethodCard.valueTalon
		};
		if (OrderTakerValidator.isOrderTakerEnv()) {
			shoppingCartRequest.userCallCenterId = currentUser.id;
			if (this.clientStore.client) {
				shoppingCartRequest.firstBuyCall =
					this.appStore.brandAtention == EBrand.inkafarma
						? this.clientStore.client.isFirstBuyIKF
						: this.clientStore.client.isFirstBuyMF;
			} else shoppingCartRequest.firstBuyCall = false;
		}

		this._loaderService.isLoadedCart.next(false);

		const shoppingListStore = this._shoppingListStore.getLocalStorageShoppingCartList();
		let shp = shoppingCartRequest;

		if (shoppingListStore[0].productAliviamed?.isMedicalConsultation) {
			shp = { ...shoppingCartRequest, paymentMethodId: -1, addressId: -1 };
		}

		this.carRequest = shp;

		return this._cartClient.getCart(shp).pipe(
			switchMap((lastResponse) => {
				if (CART_CODES_ACTION.includes(lastResponse.code)) {
					return this.actionsForCartCode(lastResponse);
				} else {
					return of(lastResponse);
				}
			}),
			tap((lastResponse) => {
				this.lastCart = lastResponse;
				this.shoppingCartId = lastResponse.id;
				this._loaderService.isLoadedCart.next(true);
				this.verifyControlledProducts(lastResponse);
			})
		);
	}
	updateDiscountsList(): void {
		const cuponListStorage = this.appStore.getStorageItem("cuponesArr");
		if (cuponListStorage && cuponListStorage != null) {
			try {
				const jsonFormatOfCoupons = JSON.parse(cuponListStorage);
				const formatCouponList = jsonFormatOfCoupons.map((data) => ({
					couponCode: data.barCode,
					couponId: null,
					discountByReferenceId: null
				}));
				this._shoppingCartRequest.shoppingCartRequest.discountsList = formatCouponList;
			} catch (error) {
				console.error("Error parsing coupon list:", error);
			}
		}
	}

	public getLocalStoragefavoriteId() {
		const localFavoriteId = localStorage.getItem(LS_ID_FAVORITE_ADDRESS);
		return localFavoriteId !== "undefined" ? parseInt(localFavoriteId) : "-1";
	}

	public getShoppingCartOrderTaker(data): Observable<ShoppingCartResponse> {
		const currentUser = this._userTokenStoreService.currentUser;
		const shoppingCartListLocal = this._shoppingListStore.getLocalStorageShoppingCartList();
		this._shoppingCartRequest.shoppingCartRequest = this.getRequestClone();
		this._shoppingCartRequest.shoppingCartRequest.products = shoppingCartListLocal;
		const shoppingCartRequest = this._shoppingCartRequest.shoppingCartRequest;
		shoppingCartRequest.defaultPaymentMethod = {
			paymentMethodCardType: this._globalProductCardService.paymentMethodCardType,
			paymentMethodName: this._globalProductCardService.paymentMethodCard.valueTalon
		};
		shoppingCartRequest.userCallCenterId = currentUser.id;
		shoppingCartRequest.observations = data.observations;
		shoppingCartRequest.skuTransfer = data.sku;
		if (this.clientStore.client) {
			shoppingCartRequest.firstBuyCall =
				this.appStore.brandAtention == EBrand.inkafarma
					? this.clientStore.client.isFirstBuyIKF
					: this.clientStore.client.isFirstBuyMF;
		} else shoppingCartRequest.firstBuyCall = false;
		return this._cartClient.getCart(shoppingCartRequest);
	}

	getNotifications(response: any) {
		return response.precalculation.discountedAmountCanonical.notificationTalon.filter((f: any) => f.source === this.notificationKey);
	}

	private getRequestClone() {
		return JSON.parse(JSON.stringify(this._shoppingCartRequest.shoppingCartRequest)) as ShoppingCartRequest;
	}

	checkPurchaseNumber(puchaseNumberId: string | number, error: string, service: string, fromClick: boolean) {
		return this._cartClient.postValidatePuchaseNumber(puchaseNumberId, error, service, fromClick);
	}

	private actionsForCartCode(payload): Observable<ShoppingCartResponse> {
		const codigo = payload.code;

		const funcion = this.actionsByCartCode[codigo];

		return funcion.runAction(payload);
	}

	actionForSkuNoVisible(payload: any) {
		const shoppingcartListUpdated = this.listProductsSKU(payload);
		localStorage.setItem("shoppingCartList", JSON.stringify(shoppingcartListUpdated));
		const URLWEB = window.location.href;
		TaggingService.errorCartForSkuNoVisible();
		if (URLWEB.includes("/checkout")) {
			this._modalsCheckoutFacadeService.getModalSkuCheckout
				.openModalCheckout(TYPE_NOTIFICATION_ACTION.SKU_NO_VISIBLE)
				.afterClosed()
				.subscribe((res) => {
					// this.dialogRef.close()
					this._addressListManagerService.closeAddressListModal();
					this.router.navigate(["/carrito"]);
					this._modalsCheckoutFacadeService.getModalSkuCheckout.openModalCart(TYPE_NOTIFICATION_ACTION.SKU_NO_VISIBLE);
				});
			return of(payload);
		} else {
			this._modalsCheckoutFacadeService.getModalSkuCheckout.openModalCart(TYPE_NOTIFICATION_ACTION.SKU_NO_VISIBLE);

			return of(payload);
		}
	}

	actionForDuplicateProducts(payload: any) {
		const shoppingcartListUpdated = this.listProductsSKU(payload);
		localStorage.setItem("shoppingCartList", JSON.stringify(shoppingcartListUpdated));
		const URLWEB = window.location.href;
		TaggingService.errorCartForDuplicateProducts();
		if (URLWEB.includes("/checkout")) {
			this._modalsCheckoutFacadeService.getModalSkuCheckout
				.openModalCheckout(TYPE_NOTIFICATION_ACTION.DUPLICATE_PRODUCTS)
				.afterClosed()
				.subscribe((res) => {
					// this.dialogRef.close()
					this._addressListManagerService.closeAddressListModal();
					this.router.navigate(["/carrito"]);
					this._modalsCheckoutFacadeService.getModalSkuCheckout.openModalCart(TYPE_NOTIFICATION_ACTION.DUPLICATE_PRODUCTS);
				});
			return of(payload);
		} else {
			this._modalsCheckoutFacadeService.getModalSkuCheckout.openModalCart(TYPE_NOTIFICATION_ACTION.DUPLICATE_PRODUCTS);

			return of(payload);
		}
	}

	actionForOnlinePaymenRet(payload: any) {
		TaggingService.errorCartForOnlinePaymentRet();
		const URLWEB = window.location.href;
		if (URLWEB.includes("/checkout")) {
			this._modalsCheckoutFacadeService.getModalSkuCheckout
				.openModalCheckout(TYPE_NOTIFICATION_ACTION.ONLINE_PAYMENT_RET)
				.afterClosed()
				.subscribe((res) => {
					window.location.reload();
				});
			return of(payload);
		}
	}

	listProductsSKU(response: ShoppingCartResponse) {
		let newListProducts = [];
		let listaAgrupada = [];

		const listaTienda = response.shoppingList;
		const listaSeller = this.filterProductsSeller(response.productItemSellersList);

		listaTienda.forEach((x) => listaAgrupada.push(x));
		listaSeller.forEach((x) => listaAgrupada.push(x));
		const shoppingCartListLocal = this._shoppingListStore.getLocalStorageShoppingCartList();
		listaAgrupada.forEach((product) => {
			shoppingCartListLocal.forEach((prodLocal) => {
				if (product.id == prodLocal.productId) newListProducts.push(prodLocal);
			});
		});
		return newListProducts.filter((objeto, index, self) => index === self.findIndex((t) => t.productId === objeto.productId));
	}

	filterProductsSeller(sellers: ProductSellersShoppingCartBase[]) {
		let productsSeller = [];
		sellers.forEach((seller) => {
			seller.products.forEach((product) => productsSeller.push(product));
		});
		return productsSeller;
	}

	verifyControlledProducts(lastResponse: ShoppingCartResponse) {
		const hasSomeControlledProducts = lastResponse.shoppingList.some((product) => product.prescriptionCode);
		if (hasSomeControlledProducts && !OrderTakerValidator.isOrderTakerEnv()) localStorage.setItem("hasControlledProducts", "1");
		else localStorage.setItem("hasControlledProducts", "0");
	}
}
