<div class="dialog-close">
	<span class="icon icon-24-close icon-close cursor-pointer" (click)="close()"> </span>
</div>

<div class="out-of-stock">
	<div class="out-of-stock__icon">
		<img *ngIf="isMifaBrand" src="../../../../assets/icons/cart-mifa.svg" alt="Carrito Fuera de stock" />
		<img *ngIf="!isMifaBrand" src="../../../../assets/icons/cart-inka.svg" alt="Carrito Fuera de stock" />
	</div>

	<h2 *ngIf="!isCode20">{{ responseData.statusDescription }}</h2>
	<h2 *ngIf="isCode20">Este producto no se encuentra disponible para tu zona de despacho.</h2>

	<ng-container *ngIf="isFromRad">
		<p *ngIf="!isCode20">Cambia de dirección o elige Retiro en tienda</p>
		<p *ngIf="isCode20">Debes cambiar la dirección de despacho para agregarlo al carrito.</p>
		<div class="out-of-stock__actions d-flex flex-column">
			<fp-button-primary class="mb-2" buttonDisplay="inline-block" (click)="changeAddress()">
				Cambiar de dirección
			</fp-button-primary>
      <ng-container *ngIf="!isCode20">
        <fp-button-ghost *fpServiceType buttonDisplay="inline-block"  (click)="selectDrugstore()">
          Elegir retiro en tienda
        </fp-button-ghost>
      </ng-container>
			<fp-button-ghost buttonDisplay="inline-block" *ngIf="isCode20" (click)="close()"> Cancelar </fp-button-ghost>
		</div>
	</ng-container>

	<ng-container *ngIf="!isFromRad">
		<p *ngIf="!isCode13 && !isCode14">Selecciona otra botica o elige Despacho a domicilio</p>
		<p *ngIf="isCode13 || isCode14">{{ responseData.statusDescriptionAux }}</p>

		<div class="out-of-stock__actions d-flex flex-column" *ngIf="!isCode13 && !isCode14">
			<fp-button-primary class="mb-2" buttonDisplay="inline-block" (click)="changeDrugstore()">
				Seleccionar otra botica
			</fp-button-primary>
			<fp-button-ghost buttonDisplay="inline-block" (click)="selectLocation()"> Elegir despacho a domicilio </fp-button-ghost>
		</div>

		<div class="out-of-stock__actions d-flex flex-column" *ngIf="isCode13 || isCode14">
			<fp-button-primary *ngIf="isCode13" class="mb-2" buttonDisplay="inline-block" (click)="selectLocation()">
				Cambiar a despacho a domicilio
			</fp-button-primary>
			<fp-button-primary *ngIf="isCode14" class="mb-2" buttonDisplay="inline-block" (click)="close()"> Entendido </fp-button-primary>
		</div>
	</ng-container>
</div>
