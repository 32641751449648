import { AuthImplementService } from './../../../../../shared/implements/auth/auth-implement.service';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from "@angular/core";
import { Observable, Subject, Subscription } from "rxjs";
import { debounceTime, delay, distinctUntilChanged, filter, switchMap, take, takeUntil, tap } from "rxjs/operators";

import { ReferenceAddressFormService } from "../../pick-up-forms/reference-address-form.service";
import { PickUpConfigStoreService } from "../../pick-up-stores/pick-up-config-store.service";

import { EBrand } from "@parameters/access/brand.parameter";
import { AppStoreService } from "@stores/app/app-store.service";
import { GeolocationService } from "src/app/shared/geolocation/geolocation.service";
import { INearestDrugstoresRequest, NearestDrugstore } from "src/app/shared/models/nearest-drugstores/nearest-drugstores.model";
import { ShoppingCartRequestService } from "src/app/shared/service/shopping-cart/shopping-cart-request.service";
import { AddressListStoreService } from "../../../address-manager/address-stores/address-list-store.service";
import { ISearchAutocompleteItem } from "../../../address-manager/models/google-map.interface";
import { GoogleMapPlacesService } from "../../../custom-agm-core/services/google-map-places.service";
import { HereMapPlacesService } from "../../../here-maps/services/here-map-places.service";
import { HereMapService } from "../../../here-maps/services/here-map.service";
import { IDrugStoreRequest } from "../../../selected-drugstore-manager/models/drugstore.interface";
import { DRUG_STORE_CONFIG } from "../../../selected-drugstore-manager/parameters/parameters.const";
import { SelectedDrugstoreHttpService } from "../../../selected-drugstore-manager/services/selected-drugstore-http.service";
import { IModalStatus } from "../../enums/modal-status.enum";
import { UserGeolocationHelperService } from "../../pick-up-helpers/user-geolocation-helper.service";
import { NearestDrugstoresImplementService } from "../../pick-up-implements/nearest-drugstores-implement.service";
import { AgmManagerService } from "../../pick-up-services-for-agm/services/agm-manager.service";
import { StoresForPickUpStoreService } from "../../pick-up-stores/stores-for-pick-up-store.service";
import {
  IUserGeolocation,
  UserGeolocationStoreService,
} from "../../pick-up-stores/user-geolocation-store.service";
import { ShoppingListStoreService } from '@stores/shopping-list/shopping-list-store.service';
import { RetValidateCartService } from 'src/app/shared/service/cart/ret-validate-cart.service';
import { HereMapConfigService } from '../../../here-maps/services/here-map-config.service';
import { ADDRESSES_PREDICTIVE_DELAY } from '../../../address-manager/parameters/global.parameter';
import { OrderTakerValidator } from '@validators/order-taker.validator';
import { NewAddressFormHelperService } from '../../../address-manager/address-forms/new-address-form-helper.service';

@Component({
  selector: "fp-input-search-for-ret",
  templateUrl: "./input-search-for-ret.component.html",
  styleUrls: ["./input-search-for-ret.component.sass"]
})
export class InputSearchForRetComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  @ViewChild("inputSearchForRET", { static: false })
  inputSearch: ElementRef<HTMLDivElement>;
  @ViewChild("hereMap", { static: false })
  hereMapRef: ElementRef<HTMLDivElement>;

  public addressForm = this.referenceAddressForm.form;
  public addressControl = this.referenceAddressForm.referenceAddressControl;

  @Input() isDisabledInputSearch = false;
  @Input() isResponsive = false;
  @Input() product: any;
  @Input() byCoords: boolean = false;
  @Output() searchDone = new EventEmitter();

  // attributes for predictive list
  public isFocus = false;
  public existGeolocation = false;
  public existMatchAddress = false;
  public shouldShowPredictive = false;
  public wasSelectedOneAddress = false;
  public selectedAddress: string;

  private wasPressedEnter = false;
  private wasAutomaticSearch = false;

  public places = [];
  public searchAutocompleteList: ISearchAutocompleteItem[] = [];
  private cancelSearchSubject = new Subject<boolean>();
  private cancelSearch$ = this.cancelSearchSubject.asObservable();

  public userGeolocation: IUserGeolocation = {} as IUserGeolocation;
  public drugstores: NearestDrugstore[] = [];
  public isOrderTaker = OrderTakerValidator.isOrderTakerEnv();
  // subscriptions
  public subscriptions: Subscription[] = [];

  private geolocationReverseAddress;

  get needAddProduct() {
    return this.retValidateCartService.addIntentIsFromProduct
  }

  constructor(
    private referenceAddressForm: ReferenceAddressFormService,
    private googleMapPlaces: GoogleMapPlacesService,
    private agmManager: AgmManagerService,
    public pickUpConfig: PickUpConfigStoreService,
    private ngZone: NgZone,
    private userGeolocationStore: UserGeolocationStoreService,
    private storesForPickUpStore: StoresForPickUpStoreService,
    private nearestDrugstoresImplement: NearestDrugstoresImplementService,
    private shoppingListRequest: ShoppingCartRequestService,
    private shoppingListStore: ShoppingListStoreService,
    private addressListStore: AddressListStoreService,
    private userGeolocationHelper: UserGeolocationHelperService,
    private hereMapPlacesService: HereMapPlacesService,
    private hereMapService: HereMapService,
    public geolocationService: GeolocationService,
    private selectedDrugstoreHttpService: SelectedDrugstoreHttpService,
    private appStore: AppStoreService,
    private authImplementService: AuthImplementService,
    private retValidateCartService: RetValidateCartService,
    private hereMapConfigService:HereMapConfigService
  ) {}

  ngOnInit() {
    this.subscribeAddressEvent();
    const userGeolocationSub = this.userGeolocationStore.userGeolocation$.subscribe((userGeolocation) => {
      this.userGeolocation = userGeolocation;
    });
    const drugstoresSub = this.storesForPickUpStore.drugstores$.subscribe((drugstores) => {
      this.drugstores = drugstores;
    });
    this.subscriptions.push(drugstoresSub, userGeolocationSub);
    this.addressListStore.selectedAddress$.subscribe((res) => {
      if (res.longitude) {
        const { street, district, city } = res;
        this.selectedAddress = street + ", " + district + ", " + city;
      } else {
        this.selectedAddress = "";
      }
    });
    this.searchPlacesAfterEnterPressesControlObserver()
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes["byCoords"]){
      this.searchAutocompleteList = [];
      this.referenceAddressForm.referenceAddressControl.setValue("");
    }
    if (this.isDisabledInputSearch) {
      this.addressControl.disable();
    } else {
      this.addressControl.enable();
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  ngAfterViewInit() {
    this.hereMapPlacesService
      .getFormattedGeolocationAddress$()
      .pipe(take(1))
      .subscribe((data) => {
        this.geolocationReverseAddress = data;
      });
  }

  private initialLoadDrugstoresFromReferenceAddress() {
    this.userGeolocationHelper
      .getGeolocationPermissions$()
      .pipe(take(1))
      .subscribe((permission) => {
        const addresses = this.addressListStore.addressList;
        const { value } = this.referenceAddressForm.referenceAddressControl;
        const minNumberOfCharacters = this.pickUpConfig.config.minimunCharactersForPredictiveAddresses;
        if (permission.status !== "ok" && addresses.length === 0 && value && value.length > minNumberOfCharacters) {
          const { nativeElement } = this.inputSearch;
          this.hereMapPlacesService
            .getAutosuggestService$(value)
            .pipe(take(1))
            .subscribe((places) => {
              this.ngZone.run(() => {
                if (places.length > 0) {
                  const defaultPlace = places[0];
                  this.loadDrugstores(defaultPlace.geometry.location);
                }
              });
            });
        }
      });
  }

  public searchPlacesAfterEnterPresses() {
    this.loadHereGeolocationAddressAtMyLocation();
    const address = this.addressControl.value as string;
    const minNumberOfCharacters = this.pickUpConfig.config.minimunCharactersForPredictiveAddresses;
    const isValidForMinorThanMinOfCharacters = typeof address === "string" && address.length > 0 && address.length < minNumberOfCharacters;
    const isValidForMajorThanMinOfCharacters = typeof address === "string" && address.length >= minNumberOfCharacters;
    const isValidTriggerEnter =
      (isValidForMinorThanMinOfCharacters && !this.wasPressedEnter) ||
      (isValidForMajorThanMinOfCharacters && !this.wasPressedEnter && !this.wasAutomaticSearch);
    this.wasPressedEnter = true;
    this.isFocus = true;
    const INVALID_VALUES = [null, "", " ", undefined];
    if (INVALID_VALUES.every((v) => v !== address)) {
      this.hereMapPlacesService
        .getAutoCompleteService$(address)
        .pipe(take(1))
        .subscribe((places) => {
          this.ngZone.run(() => {
            this.shouldShowPredictive = true;
            this.existMatchAddress = places.length > 0;
            this.searchAutocompleteList = places.items.map((_address) => {
              return {
                id: _address.id,
                icon: "pin",
                title: _address.title,
                subTitle: _address.address ? _address.address.label : "",
                searchItem: _address
              } as ISearchAutocompleteItem;
            });
          });
        });
    }
  }
  private hereMapPlaces$(input){
    if (this.isOrderTaker && this.byCoords) {
      return this.hereMapPlacesService.getReverseGeocodeService$(input);
    }
    return  this.hereMapPlacesService.getAutoCompleteService$(input);
  }

  public searchPlacesAfterEnterPressesControlObserver() {
    this.loadHereGeolocationAddressAtMyLocation();
    const valueObserver =  this.addressControl.valueChanges
    .pipe(
      debounceTime(this.hereMapConfigService.getDebounceTime()),
      distinctUntilChanged(),
    )
    .subscribe((address) => {
      this.wasPressedEnter = true;
      const INVALID_VALUES = [null, "", " ", undefined];
      if (this.isFocus && INVALID_VALUES.every((v) => v !== address)) {
        this.hereMapPlaces$(address)
          .pipe(take(1))
          .subscribe((places) => {
            this.ngZone.run(() => {
              this.shouldShowPredictive = true;
              this.existMatchAddress = places.length > 0;
              NewAddressFormHelperService;
              this.searchAutocompleteList = places.items.map((_address) => {
                return {
                  id: _address.id,
                  icon: "pin",
                  title: _address.title,
                  subTitle: _address.address ? _address.address.label : "",
                  searchItem: _address
                } as ISearchAutocompleteItem;
              });
            });
          });
      }
    })
    this.subscriptions.push(valueObserver)
  }

  private loadHereGeolocationAddressAtMyLocation() {
    this.hereMapPlacesService
      .getFormattedGeolocationAddress$()
      .pipe(take(1))
      .subscribe((data) => {
        this.geolocationReverseAddress = data;
      });
  }

  public activateGeolocation() {
    if (!this.geolocationService.isAcceptedGeolocation) {
      this.geolocationService.loadGeolocation();
    }
  }

  public subscribeAddressEvent() {
    const valueChanges$ = this.addressControl.valueChanges as Observable<string>;
    const valueChangesSubs = valueChanges$
      .pipe(delay(10))
      .pipe(
        filter((value) => {
          return typeof value === "string" && this.isFocus && !this.wasSelectedOneAddress;
        })
      )
      .pipe(distinctUntilChanged())
      .pipe(
        tap(() => {
          this.ngZone.run(() => {
            this.shouldShowPredictive = false;
            this.wasPressedEnter = false;
            this.wasAutomaticSearch = false;
            this.cancelSearchSubject.next(true);
          });
        })
      )
      // .subscribe(() => {});
      .pipe(debounceTime(this.pickUpConfig.config.delayForPredictiveAddresses))
      .pipe(
        filter((value) => {
          const minNumberOfCharacters = this.pickUpConfig.config.minimunCharactersForPredictiveAddresses;
          return value.length >= minNumberOfCharacters && !this.wasPressedEnter;
        })
      )
      .pipe(
        switchMap((address) => {
          this.wasAutomaticSearch = true;
          const { nativeElement } = this.inputSearch;
          return this.googleMapPlaces.textSearch2$(address, nativeElement).pipe(takeUntil(this.cancelSearch$));
        })
      )
      .subscribe((places) => {
        this.ngZone.run(() => {
          this.shouldShowPredictive = true;
          this.existMatchAddress = places.length > 0;
          this.places = places;
        });
      });
    this.subscriptions.push(valueChangesSubs);
  }

  private getFormattedPlaces(places: any[]) {
    const REGEXP = /15\d{3}, Perú/gim;
    return places.map((place) => {
      place.formatted_address = place.formatted_address.replace(REGEXP, "");
      return place;
    });
  }

  public selectStoreAddress(place) {
    this.wasSelectedOneAddress = true;
    this.referenceAddressForm.referenceAddressControl.setValue(place.subTitle);
    this.hereMapPlacesService.getDetailedAddress$(place.id).subscribe((data) => {
      const lat = data?.position?.lat;
      const lng = data?.position?.lng;
      this.places = [];
      this.loadDrugstores({ lat, lng });
    })
  }

  public selectGeolocationAddress() {
    if (this.agmManager.map) {
      this.wasSelectedOneAddress = true;
      this.referenceAddressForm.referenceAddressControl.setValue(this.userGeolocation.geolocationAddress.completedFormattedAddressName);
      const { location } = this.userGeolocation.geolocationAddress;
      this.agmManager.setMapCenter(location.lat(), location.lng());
      this.places = [];
      this.loadDrugstores(location);
    }
  }

  public locateGeolocationOnTheHereMap() {
    const address = this.geolocationReverseAddress.title;
    const { position } = this.geolocationReverseAddress;
    const lat = position.lat;
    const lng = position.lng;
    this.geolocationReverseAddress = { lat, lng };
    this.locateAddressOnTheHereMap(this.geolocationReverseAddress, address);
  }

  public locateAddressOnTheHereMap(hereFormattedAddress: any, address) {
    const lat = hereFormattedAddress.lat;
    const lng = hereFormattedAddress.lng;
    this.referenceAddressForm.referenceAddressControl.setValue(address);
    this.loadDrugstores(hereFormattedAddress);
  }

  private getParamsForAddress(coords: any) {
    return {
      latitude: coords.latitude || coords.lat,
      longitude: coords.longitude || coords.lng ,
      shoppingCart: this.getCurrentCart(),
      companyCode: this.appStore.companyCode,
      userId: this.authImplementService.currentUID
    } as INearestDrugstoresRequest;
  }

  getCurrentCart() {
    const shoppingCart = this.shoppingListStore.getLocalStorageShoppingCartList();
    return this.product?.id ? this.productIsInShopping(shoppingCart) : shoppingCart
  }

  productIsInShopping(shoppingCart) {
    const {
      productId
    } = this.product4Shopping;

    return !shoppingCart.find((p: any) => p.productId === productId) ? [...shoppingCart, this.product4Shopping] : shoppingCart
  }

  get product4Shopping() {
    const {
      id: productId,
      presentationIdSelected
    } = this.product

    return {
      productId,
      presentationIdSelected,
      quantity: 1
    }
  }

  private loadDrugstores(location) {
    this.searchDone.emit(IModalStatus.LOADING);
    this.storesForPickUpStore.setDrugstores([]);
    const lat = location.lat;
    const lng = location.lng;
    this.nearestDrugstoresImplement.getDrugstores$(this.getParamsForAddress(location))
    const params = {
      latitude: lat,
      longitude: lng,
      shoppingCart: this.shoppingListRequest.shoppingCartRequest.products,
      companyCode: this.appStore.companyCode
    } as INearestDrugstoresRequest;
    //
    this.onNearestDrugstores(this.getParamsForAddress(location));
  }

  onDrugStore(params: INearestDrugstoresRequest) {
    this.selectedDrugstoreHttpService
      .getDrugStoreList$({
        position: {
          latitude: params.latitude,
          longitude: params.longitude
        },
        proximity: DRUG_STORE_CONFIG.PROXIMITY,
        companyCode: this.appStore.brandAtention === EBrand.inkafarma ? EBrand.inkafarma : EBrand.mifarma,
        page: DRUG_STORE_CONFIG.PAGE,
        maxSixeResult: DRUG_STORE_CONFIG.MAX_SIZE_RESULT
      } as IDrugStoreRequest)
      .pipe(delay(100))
      .pipe(take(1))
      .subscribe(
        (drugstores) => {
          const listDrugstore: NearestDrugstore[] = drugstores.map((drugstore) => {
            const current = new NearestDrugstore(null);

            current.address = drugstore.address || "";
            current.distance = drugstore.distance || 0;
            current.id = drugstore.legacyId || 0;
            current.latitude = drugstore.latitude || 0;
            current.longitude = drugstore.longitude || 0;
            current.localCode = drugstore.localCode || "";
            current.name = drugstore.name || "";
            current.defaultDrugstore = drugstore.defaultDrugstore || false;
            current.localOpeningHours = drugstore.startHour + " - " + drugstore.endHour || "";

            return current;
          });

          this.storesForPickUpStore.setDrugstores(listDrugstore);
          if (drugstores.length) {
            this.searchDone.emit(IModalStatus.OK);
            const temporalSelectedDrugstore = this.getVerifiedSelectedDrugstore(listDrugstore);
            this.storesForPickUpStore.setSelectedTemporalDrugstore(temporalSelectedDrugstore);
          } else {
            this.searchDone.emit(IModalStatus.EMPTY);
          }
          this.wasSelectedOneAddress = false;
          this.isFocus = false;
          this.hereMapPlacesService.addMarker({
            lat: params.latitude,
            lng: params.longitude
          });
          this.hereMapService.setMapCenter(params.latitude, params.longitude);
        },
        () => {
          this.storesForPickUpStore.setDrugstores([]);
          this.searchDone.emit(IModalStatus.EMPTY);
          this.wasSelectedOneAddress = false;
        }
      );
  }

  onNearestDrugstores(params: INearestDrugstoresRequest) {
    this.nearestDrugstoresImplement
      .getDrugstores$(params)
      .pipe(delay(200))
      .pipe(take(1))
      .subscribe(
        (drugstores) => {
          this.storesForPickUpStore.setDrugstores(drugstores);
          if (drugstores.length) {
            this.searchDone.emit(IModalStatus.OK);
            const temporalSelectedDrugstore = this.getVerifiedSelectedDrugstore(drugstores);
            this.storesForPickUpStore.setSelectedTemporalDrugstore(temporalSelectedDrugstore);
            const position = {
              lat: params.latitude,
              lng: params.longitude
            };

            setTimeout(() => {
              this.hereMapPlacesService.addMarker(position);
              this.hereMapService.setMapCenter(params.latitude, params.longitude);
            }, 400);
          } else {
            this.searchDone.emit(IModalStatus.EMPTY);
          }
          this.wasSelectedOneAddress = false;
          this.isFocus = false;
        },
        () => {
          this.storesForPickUpStore.setDrugstores([]);
          this.searchDone.emit(IModalStatus.EMPTY);
          this.wasSelectedOneAddress = false;
        }
      );
  }

  private getVerifiedSelectedDrugstore(drugstores: NearestDrugstore[]) {
    const { selectedDrugstore } = this.storesForPickUpStore;
    if (selectedDrugstore?.id) {
      const lastSelectedDrugstoreId = selectedDrugstore.id;
      const temporalSelectedDrugstore = drugstores.find((d) => d.id === lastSelectedDrugstoreId);
      return temporalSelectedDrugstore ? temporalSelectedDrugstore : drugstores[0];
    } else {
      return drugstores[0];
    }
  }

  public focusEvent() {
    this.isFocus = true;
    this.loadHereGeolocationAddressAtMyLocation();
  }

  public blurEvent() {
    setTimeout(() => {
      this.isFocus = false;
    }, 200);
  }
}
