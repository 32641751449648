
import { OrderTakerValidator } from "@validators/order-taker.validator";
import { environment } from "../../../environments/environment";
import { EBrand } from '@parameters/access/brand.parameter';

const OT_BRAND_ATENTION = "OT_BRAND_ATENTION";
const OT_BRAND = localStorage.getItem(OT_BRAND_ATENTION)
const GET_CUSTOMER_ORDER = OT_BRAND ? localStorage.getItem(OT_BRAND_ATENTION) === 'IKF' ? 'customer-order-ikf' : 'customer-order-mf' : environment?.apigee?.customerOrder
// const GET_CUSTOMER_ORDER = 'customer-order-ikf'

export class ENDPOINTS {
	public static ONE_TIME_PASSWORD = `${environment.api_gateway}/onetimepassword/`;
	public static GET_CUSTOM_TOKEN = `${environment.api_gateway}/authentication-token`;
	public static GET_REGISTER_FIELDS = `${environment.api_gateway}/getregisterfields`;
	public static GET_USER_BY_PHONE = `${environment.api_gateway}/user/phone/?`;
	public static GET_FAVORITE = `${environment.api_gateway}/favorite`;
	public static GET_BRAND = `${environment.api_gateway}/brand`;
	public static GET_BRAND_LIST = `${environment.api_gateway}/brand/v2`;
	public static GET_BRAND_PRODUCTS = `${environment.api_gateway}/v2/brand/`;
	public static GET_CATEGORY_LIST = `${environment.api_gateway}/categories/v2/tree`;
	public static GET_CATEGORY_FILTERS = `${environment.api_gateway}/v2/category/filters`;
	public static GET_ANTIFRAUD = `${environment.api_gateway}/antifraud/search`;

	public static POST_CATEGORY_PRODUCTS = `${environment.api_gateway}/v2/category/products`;
	public static POST_UPDATE_USER = `${environment.api_gateway}/updateuser`;
	public static POST_UPDATE_USER_V2 = `${environment.api_gateway}/v2/updateuser`; // nuevo
	public static POST_REMOVE_FAVORITE = `${environment.api_gateway}/delfromfav`;
	public static POST_ADD_FAVORITE = `${environment.api_gateway}/addtofav`;
	public static POST_NOTIFICATION = `${environment.api_gateway}/dsc/v1/notification`;
	public static POST_PURCHASE_NUMBER = `${environment.api_gateway}/v1/pagoweb/purchaseNumber`;
	public static PUT_PURCHASE_NUMBER_STATUS = `${environment.api_gateway}/v1/pagoweb/purchaseNumberStatus`;
	public static POST_SIGNATURE_TRANSACTION = `${environment.api_gateway}/v1/agorapay/authentication`;
	public static POST_PAY_ONLINE = `${environment.api_gateway}/payonline/update`;
	public static GET_SCHEDULE_DAY = "assets/json/respCheckoutScheduleDays.json";
  public static GET_SCHEDULE_HOUR =
    "assets/json/respCheckoutScheduleHours.json";

	public static GET_MENU_CATEGORIES = `${environment.api_gateway}/departments`;
	public static GET_CATEGORIES_FILTERS = `${environment.api_gateway}/department-tree?keyword=`;
	public static GET_SEARCH_LANDING_FILTERS = `${environment.api_gateway}/search-filters`;
	public static GET_FILTERED_PRODUCTS = `${environment.api_gateway}/filtered-products`;
	public static GET_SEARCH_LANDING_FILTERS_MF = `${environment.api_gateway}/mf/search-filters`;
	public static GET_FILTERED_PRODUCTS_MF = `${environment.api_gateway}/mf/filtered-products`;

	public static GET_PRODUCTS_RESULT = `${environment.api_gateway}/productsearch/v2`;
	public static GET_SUGGESTED_PRODUCTS_BY_KEYWORD = `${environment.api_gateway}/search/keyword`;

  public static POST_CART = environment.brand === EBrand.inkafarma
    ? `${environment.api_gateway}/cart`
    : `${environment.api_gateway}/v2/cart`;
	public static POST_CART_V2 = `${environment.api_gateway}/v2/cart`;

	public static GET_PATIENT_DELIVERY_INFO = `${environment.api_gateway}/delivery-info`;
	public static POST_SUGGESTIONS_AND_ENQUIRIES_EMAIL = `${environment.api_gateway_notifications}/mail/v2/client`;

	public static POST_MINI_CART = `${environment.api_gateway}/mini-cart`;
	public static GET_PRODUCTS_BY_IDS = `${environment.api_gateway}/products/`;
	public static GET_PRODUCTS_BY_ID = `${environment.api_gateway}/product/`;
	public static GET_PRODUCTS_BY_ID_FOR_SEO = `${environment.api_gateway}/productSeo/`;
	public static GET_MF_PRODUCTS_BY_IDS = `${environment.api_gateway}/mf/products/`;
	public static GET_MF_PRODUCTS_BY_ID = `${environment.api_gateway}/mf/product/`;
	public static GET_MF_PRODUCTS_BY_ID_FOR_SEO = `${environment.api_gateway}/mf/productSeo/`;

	// Checkout - Direction
  public static GET_ADDRESS_NICKNAME_LIST =
    "assets/json/address-nickname-list.json";

	public static POST_ADD_ADDRESS = `${environment.api_gateway}/address`;
	public static GET_VALID_COVERAGE = `${environment.api_gateway}/coverage-locations`;
	public static GET_DELIVERY_INFO = `${environment.api_gateway}/deliveryinfo`;
	public static GET_DELIVERY_INFO_SCHEDULE = `${environment.api_gateway}/deliveryinfo/schedule`;

	public static GET_GOOGLE_MAP_CONFIGS = "assets/json/google-map-config.json";
	public static ADDRESS = `${environment.api_gateway}/address`;
	public static USER_ADDRESS = `${environment.api_gateway}/user-address`;
	public static DELIVERY_INFO = `${environment.api_gateway}/deliveryinfo`;
	public static COVERAGE_LOCATION = `${environment.api_gateway}/coverage-locations`;
	// public static SCHEDULE = `${environment.api_gateway}/deliveryinfo/schedule`;
	public static SCHEDULE_RET = `${environment.api_gateway}/deliveryinfo/schedule/retirement`;
	// public static NEAREST_DRUGSTORES = `${environment.api_gateway}/v0/drugstores/nearestDrugstores`;
	public static NEAREST_DRUGSTORES = `${environment.apigee.url}/${GET_CUSTOMER_ORDER}/ecommerce/v1/nearest-drugstores`;
	public static NEAREST_SELECTED_DRUGSTORES = `${environment.apigee.url}/${GET_CUSTOMER_ORDER}/ecommerce/v1/nearest-drugstore`;
	public static STATIC_NEAREST_DRUGSTORES = `assets/json/nearestDrugstores.json`;

	public static ORDERS_FIREBASE = "orders";
	public static COMPANY_CODE = "companyCode";
	public static NODE_CLIENT_USER_ID = "client/joinIdentifierId";
	public static MOTORIZED = "motorized";

	public static SALESFORCE_PERSONALITATION_INKA = `${environment.api_gateway}/authevent/${environment.salesforceInka}`;
	public static SALESFORCE_PERSONALITATION_MIFA = `${environment.api_gateway}/authevent/${environment.salesforceMifa}`;

	public static GET_HISTORY = `${environment.api_gateway}/gethistory`;
	public static GET_MESSAGE_CANCEL = `${environment.api_gateway}/message/cancel`;
	public static GET_SHOPPRING_CART_CANCELLABLE = `${environment.api_gateway}/shoppingcart/cancellable`;

	public static FIREBASE_URL_INKATRACKER_LITE = `${environment.app2.databaseURL}`;

	public static USER_SERVICE = `${environment.api_gateway}/user/v2/api`;
	public static CART_CONFIRMATION = `${environment.api_gateway}/cartconfirmation`;
	public static UPDATE_ANONYMOUS = `${environment.api_gateway}/updateAnonymous`;
	public static DISCOUNT_CODE = `${environment.api_gateway}/dsc/discountByCode`;
	public static UPDATE_USER_LEGACY = `${environment.api_gateway}/updateUserLegacy`;
	public static SEARCH_PURSE = `${environment.api_gateway}/user/v2/api/buscarCliente`;

	public static ORDER_TRACKING = `${environment.api_gateway}/order/tracking/search`;

	// afiliation campaing
	public static GET_IDENTITY_DOCUMENT_TYPE = `${environment.api_gateway_campaign}/campaign/identityDocumentType`;
	public static GET_CAMPAIGN_DEPARTMENT = `${environment.api_gateway_campaign}/campaign/department`;
	public static GET_CAMPAIGN_CUSTOMER = `${environment.api_gateway_campaign}/campaign/customer`;
	public static POST_CAMPAIGN_CUSTOMER = `${environment.api_gateway_campaign}/campaign/customer`;
	public static POST_CAMPAIGN_CUSTOMER_VALUES = `${environment.api_gateway_campaign}/campaign/customer/values`;

	// CONSULTA DE STOCK
	public static NEAREST_DRUGSTORES_ENDPOINT = `${environment.api_gateway}/drugstores/nearestDrugstores`;
	//public static NEAREST_DRUGSTORES_ENDPOINT = `${environment.apigee.mock}/v0/drugstores/nearestDrugstores`;
	public static DOCUMENT_TYPES_ENDPOINT = `${environment.api_gateway_02}/campaign/identityDocumentType`;
	public static AVAILABLE_DEPARTMENTS_ENDPOINT = `${environment.api_gateway_02}/campaign/department`;
	//IKF AFFILIATE & VALIDATE - MF VALIDATE
	public static AFFILIATE_CUSTOMER_ENDPOINT =
		environment.brand === EBrand.inkafarma
			? `${environment.api_gateway_02}/campaign/customer`
			: `${environment.api_gateway_07}/nvr/auth`;
	public static AFFILIATE_CUSTOMER_MF_ENDPOINT = `${environment.api_gateway_07}/nvr/affiliation`;

	// CHECKOUT RET
	public static PERSON_FOR_STORE_PICKUP = `${environment.api_gateway}/deliveryinfo/peopleForStorePickup`;
	public static PERSON_FOR_STORE_PICKUP_UPDATE = `${environment.api_gateway}/deliveryinfo/peopleForStorePickup/update`;
	public static PERSON_FOR_STORE_PICKUP_DEFAULT = `${environment.api_gateway}/deliveryinfo/peopleForStorePickup/defaultPerson`;
	public static PEOPLE_FOR_STORE_PICKUP = `${environment.api_gateway}/deliveryinfo/peopleForStorePickup/obtain`;

	// SELLER
	public static GET_SELLER_ORDER = `${environment.api_gateway}/sellercenter/list/getOrdersActive`;
	public static CANCEL_SELLER_ORDER = `${environment.api_gateway}/shoppingcart/cancel`;

	// Here maps
  public static HERE_MAPS_GEOCODE =
    "https://geocode.search.hereapi.com/v1/geocode";
  public static HERE_MAPS_AUTOSUGGEST =
    "https://autosuggest.search.hereapi.com/v1/autosuggest";
  public static HERE_MAPS_REVERSE_GEOCODE =
    "https://revgeocode.search.hereapi.com/v1/revgeocode";
  public static HERE_MAPS_LOOKUP =
    "https://revgeocode.search.hereapi.com/v1/lookup";
  public static HERE_MAPS_AUTOCOMPLETE =
    "https://autocomplete.search.hereapi.com/v1/autocomplete";
    

	// VALIDATE RUC
	public static VALIDATE_RUC_ENDPOINT = `${environment.api_gateway}/validateRuc`;

	// Generci Resources
	public static USER_DOCUMENT_TYPES_ENDPOINT = `${environment.api_gateway}/user/v2/api/identityDocumentType`;


	// BENEFITS_USER
	// public static POST_BENEFITS_USER = `${environment.api_gateway_05}/benefits`;
	public static POST_BENEFITS_USER = `${environment.api_gateway}/benefits`;

	// AMOUNT TO POINT
	public static POST_AMOUNT_TO_POINT = `${environment.api_gateway_05}/amountToPoint`;

	//SUBSTITUTE PRODUCTS
	public static POST_SUBSTITUTE_PRODUCTS = `${environment.api_gateway}/search-substitute-products`;

	//BRAIN
	public static GET_LIST_CONTENIDOS = `${environment.api_brain}&branchOffice=`;

  // APIGEE
  public static APIGEE_POST_OAUTH_TOKEN = `${environment.apigee.url}/oauth/access-token`;
  public static APIGEE_PATH_VALIDATE_ADD_TO_CART = `${environment.apigee.url}/${GET_CUSTOMER_ORDER}/ecommerce/v1/validate-cart`;
  public static APIGEE_PATH_PURCHASE_INTENT = `${environment.apigee.url}/${GET_CUSTOMER_ORDER}/ecommerce/v1/purchase-intent`;
  public static APIGEE_GET_CUSTOMER_APPROVAL = `${environment.apigee.url}/customer-approval/v1/approval`;
  public static APIGEE_GET_CONTROLLED_PRODUCTS = `${environment.apigee.url}/prescription`;
  public static APIGEE_POST_PROMO_MULTIMARCA = `${environment.apigee.url}/promomultimarca`;


  public static APIGEE_GET_CLIENT_RECORD = `${environment.apigee.url}/client-record/api/points/`;
  // APIGEE TOMADOR
  public static APIGEE_POST_OAUTH_TOKEN_TOMADOR = `${environment.apigeeTomador?.url}/oauth/access-token`;
  public static APIGEE_PATH_VALIDATE_ADD_TO_CART_TOMADOR = `${environment.apigeeTomador?.url}/fp_tomador_neardrugstore/v2/validateAddToCart`;
  public static NEAREST_DRUGSTORES_TOMADOR = `${environment.apigeeTomador?.url}/fp_tomador_neardrugstore/v2/nearestDrugstores`;
  public static NEAREST_SELECTED_DRUGSTORES_TOMADOR = `${environment.apigeeTomador?.url}/fp_tomador_neardrugstore/v2/nearestDrugstoreOne`;
  public static APIGEE_PATH_PURCHASE_INTENT_TOMADOR = `${environment.apigeeTomador?.url}/fp_tomador_neardrugstore/v1/purchaseIntent`;
  //BRAIN
  public static SUPERDISCOUNT = `${environment.super_discount}`;

	//ALGOLIA SERVICE
  public static GET_LIST_FILTER_ALGOLIA = `${environment.api_filter_algolia}`
	// MISSIONS
	public static GET_MISSIONS_RESULT = `${environment.api_gateway_missions}/clientMissions`;
	// ALIVIAMED
	public static POST_ALIVIAMED_SESSIONS = `${environment.api_aliviamed}/graphql`;
	//APIGEE MONEDERO
	public static APIGEE_GET_NOMEDERO_VALIDATION = `${environment.apigee.url}/monedero/validation`;
	public static APIGEE_GET_NOMEDERO_VALIDATION_TOMADOR = `${environment.apigeeTomador?.url}/monedero/validation`;
	public static POST_ALIVIAMED_WSP_GENERATE_LINK = `${environment.api_aliviaWsp}/payment/generate/link/tomador`;
	public static POST_ALIVIAMED_WSP_RESEND_LINK = `${environment.api_aliviaWsp}/payment/link/forwarding`;
	public static POST_ALIVIAMED_SET_STATUS_CART_LINK = `${environment.api_gateway}/shoppingcart/aliviamed/status`;
	public static POST_ALIVIAMED_SEARCH_PATIENT = `${environment.api_alivia_patients}/support/validate/document/`;
	public static GET_ALIVIAMED_PRODUCT = `${environment.api_gateway}/shoppingCart/aliviamed/product`;

	//CHECK PURCHASE NUMBER
	public static CANCEL_TRANSACTION = `${environment.api_gateway}/v1/app/cancelTransaction`;

	//API GOOGLE SECRET MANAGER
	public static GET_SECRET_BY_ID = `${environment.secretManager.url}/secret`;
}

export const JSON_ENDPOINTS = {
  COVERAGE_AREAS: `${environment.amazon_s3}/zones.json`,
};

export const CONTENTFUL_PATH = {
  contentTypeIds: {
    Dashboard: "dashboard",
    banner: "banner",
    mainSliderBanner: "mainSliderBanner",
    bannerList: "bannerList",
    bannerKit: "bannerKit",
    bannerCountDown: "bannerCoundown",
    shortcutList: "shortcutList",
    shortcut: "shortcut",
    productList: "productList",
    product: "product",
    titleSection: "titleSection",
    featuredProduct: "featuredProduct",
    bannerProductList: "bannerProductList",
    headerProductList: "headerProductList",
    customerServiceQuestionsAndAnswer: "customerServiceQuestionsAndAnswer",
    suggestionsAndEnquiriesEmailSubjectList: "customerServiceEmailSubjectList",
    staticLanding: "staticLanding",
    staticLandingPage: "staticLandingPage",
    footer: "footer",
    header: "header",
    dashboardShowcases: "dashboardShowcases",
    showcase: "showcases",
    BannerWorld: "bannerWorld",
    dashboardWorld: "dashboardWorld",
    worldList: "worldList",
    headerWordList: "headerWordList",
    author: "author",
    tag: "blogLandingTag",
    worldBlogDetail: "blogLandingDetail",
    blogListPreview: "blogLandingPreview",
    worldBlogLanding: "blogLanding",
    advertising: "advertising",
    serviceLanding: "serviceLanding",
    bannerInkaclub: "bannerInkaclub",
    inkapuntosList: "inkapuntosList",
    inkaclubItem: "inkaclubItem",
    inkaclubInkapuntos: "inkaclubInkapuntos",
    inkaclubSorteos: "inkaclubSorteos",
    informativeItems: "informativeLandingItems",
    inkaclubListWinners: "inkaclubListWinners",
    inkaclubSorteo: "inkaclubSorteo",
    inkaclubItemGanadores: "inkaclubItemGanadores",
    coupon: "product",
    INKAFARMA_CONFIGURATION_KEY: "inkafarmaConfiguration",
    MIFARMA_CONFIGURATION_KEY: "configuration",
    modalStatic: "modalStatic",
    inkaclubHeroLanding: "inkaclubHeroLanding",
    textoLibre: "textoLibre",
    callToAction: "sectionCallToAction",
    contentfulConfigurationKey: "configuration",
  },
};
