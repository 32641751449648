import { Directive, TemplateRef, ViewContainerRef, OnInit } from '@angular/core';
import { OrderTakerValidator } from '@validators/order-taker.validator';

@Directive({
  selector: '[fpServiceType]'
})
export class ServiceTypeDirective implements OnInit {

  constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef) {}

  ngOnInit(): void {
    this.shouldRenderTemplate();
  }
  private shouldClearContainer(controlledSale: any): boolean {
    return (controlledSale?.enableRetCall as boolean) === false;
  }
  private shouldRenderTemplate() {
    const controlledSale = JSON.parse(localStorage.getItem('controllated-sale'));
    if (OrderTakerValidator.isOrderTakerEnv()) {
        if (this.shouldClearContainer(controlledSale)) {
            // Si la condición es falsa, limpia el contenedor
            this.viewContainer.clear();
            return;
        }
    }
    // Si la condición es verdadera o no es un entorno de OrderTaker, renderiza el template
    this.viewContainer.createEmbeddedView(this.templateRef);
  }
}
