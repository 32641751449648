export const STATIC_LANDING_SLUG = {
	legals: "legals-landing",
	termsOfService: "terms-of-service-landing",
	termsOfServiceSeller: "terms-and-condition-marketplace-landing",
	termsOfconditionsHighDiscount: "terms-conditions-high-discount",
	modalActiveHighDiscount: "modal_super_discount",
	privacyPolicy: "privacy-policy-landing",
	treatmentForAdditionalPurposes: "treatment-for-additional-purposes-landing",
	termsAndConditionsOfPayment: "terms-and-conditions-of-payment-landing",
	frequentlyAskedQuestions: "frequently-asked-questions-landing",
	callCenter: "call-center-landing",
	inkaclub: "inka-club-landing",
	electronicVoucher: "electronic-vouchers-landing",
	termsAndConditionsSpecial: "terms-and-conditions-special",
	blog: "blog-landing",
	catalogue: "catalogue-landing",
	strategicPartners: "strategic-partners-landing",
	treatmentManagers: "treatment-managers-landing",
	usLanding: "us-landing",
	oneDocThree: "one-doc-three",
	termsOfOneDocThree: "terms-of-service-1doc3",
	dataUsageOfOneDocThree: "uso-de-datos-de-1doc3",
	privacyPoliciesOfOneDocThree: "politica-de-privacidad-de-1doc3",
	landingEmbedCatalogs: "landing-embebido-catalogos",
	cyberWow: "CyberWow",
	cyberWOWRegister: "cyberwow-register",
	formularioEventosMifarma: "eventos-inkafarma",
	neighborPharmacy: "farmacia-vecina",
	campania: "campania",
	category: "category",
	tarjetaOh: "tarjeta-oh",
	agora: "agora",
	agoraClub: "agora-club",
	agoraPay: "agora-pay",
	personalDataProcesing: "personal-data-procesing",
	cookiesPolicy: "cookies-policy-landing",
	termsOfServiceDrugstore: "retiro-en-botica",
	haircode: "haircode"
};
 
export const STATIC_LANDING_TYPE = {
	iframe:"iframe",
	others: "otros"
}

export const STATIC_LANDING_GENDER = {
	M: "MASCULINO",
	F: "FEMENINO"
};

export enum GENDER_CODE_SMALL {
	"MASCULINO" = "M",
	"FEMENINO" = "F"
}

export enum GENDER_CODE_LARGE {
	"MASCULINO" = "MASCULINO",
	"FEMENINO" = "FEMENINO"
}

export const MF_FIELD_GENDER_BLANK = "9999";
export const MF_FIELD_BIRTHDAY_BLANK = "01/01/1900";
