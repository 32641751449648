import { BreakpointBehavior } from "./shared/behaviors";
import { RegistrationValidationService } from "./core/modules/register/register-services/registration-validation.service";
import { DOCUMENT, ViewportScroller } from "@angular/common";
import { AfterViewInit, Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { Auth } from "@angular/fire/auth";
import { environment } from "@environments/environment";
import { UserInfoStoreService } from "@stores/user/user-info-store.service";
import { catchError, filter, mergeMap, startWith, switchMap, take, tap } from "rxjs/operators";
import {
  ActivatedRoute,
  Event,
  NavigationEnd,
  NavigationStart,
  Router,
} from "@angular/router";
import { IconsImplementService } from "./core/generic/icons/service/icons-implement.service";
import { AppStoreService } from "@stores/app/app-store.service";
import { AuthImplementService } from "@implements/auth/auth-implement.service";
import { GlobalProductImplementService } from "@implements/product/global-product-implement.service";
import { EBrand, EBrandAsset, EBrandCode } from "@parameters/access/brand.parameter";

import { BUSINESS_KEYS, BUSINESS_PATHS } from "@parameters/business.parameter";
import {
  AUTH_PROVIDER,
  BREAKPOINTS,
  NAVIGATION_URL,
  REDIRECT_STATUS,
  USER_SOURCE_LOGIN,
} from "@parameters/global";
import { TaggingService } from "src/app/atm-services/tagging.service";
import { SeoClientService } from "./shared/service/seo/seo-client.service";
import { GlobalProductCardService } from "./core/modules/product-card/service/global-product-card.service";
import { CardAssociatedService } from "./shared/service/card-associated/card-associated-client.service";
import { Subscription, combineLatest, forkJoin, of } from "rxjs";
import { OrderTakerValidator } from "@validators/order-taker.validator";

import {
  JSON_INKA_STRUCTURED_DEFECT,
  JSON_MIFA_STRUCTURED_DEFECT,
} from "./business/checkout/parameters/constants";
import { AuthenticationTokenImplementService } from "@implements/auth/authentication-token-implement.service";
import { AppManagerService } from "./shared/service/firebase/auth/app-manager.service";
import { onIdTokenChanged } from "@firebase/auth";
import { MatDialog } from "@angular/material/dialog";
import { ScrollStrategyOptions } from "@angular/cdk/overlay";
import {
  FooterItem,
  MainFooter,
} from "./core/modules/footer/models/footer.model";
import { CookiesPolicyComponent } from "./shared/components/cookies-policy/cookies-policy.component";
import { FooterImplementService } from "./core/modules/footer/services/footer-implement.service";
import { RegisterClientService } from "./shared/service/user-register/register-client.service";
import {
  RegisterResponse,
  UserRegister,
} from "@models/register/register.model";
import { RegisterImplementService } from "./core/modules/register/register-services/register-implement.service";
import { UpdateAnonymousImplementService } from "./business/checkout/checkout-services/update-anonymous-implement.service";
import { UserClientService } from "./shared/service/user/user-client.service";
import { MainLoaderService } from "@helpers/main-loader.service";
import { ApigeeTokenService } from "./shared/service/apigee/apigee-token.service";
import { ApigeeStoreService } from "./shared/service/apigee/apigee-store.service";
import { RetValidateCartService } from "./shared/service/cart/ret-validate-cart.service";
import { CookiesPolicyService } from "./shared/service/cookies/cookies-policy.service";

import { AlgoliaInstanceService } from "./core/modules/custom-instantsearch/algolia-instance.service";
import { HomeClientService } from "./shared/service/home/home-client.service";
import { LocalStorageService } from "@service/local-storage/local-storage.service";
import { LS_ALGOLIA_REDIRECTS } from "@service/local-storage/algolia/ls-algolia.constans";
import { GEO_STATUS_TYPES, GeolocationService } from "./shared/geolocation/geolocation.service";
import { UserGeolocationHelperService } from "./core/modules/pick-up-in-store/pick-up-helpers/user-geolocation-helper.service";
import { GeolocationStoreService } from "./business/consulta-stock/stock-stores/geolocation-store.service";
import { HereMapPlacesService } from "./core/modules/here-maps/services/here-map-places.service";

@Component({
  selector: "fp-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.sass"]
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  public isUserAuthenticated: boolean;
  public isBigScreenSubscription: Subscription;
  public isBigScreen: boolean;
  private dialog;
  private dialogPolicyStatus: boolean;
  private dialogClosedByCustomer: boolean;
  private footerCookies: any;
  private userResponseData: any;
  private userLoaded: boolean;
  private scriptCokkie: HTMLScriptElement = undefined;
  private subscriptions: Subscription[] = []

  isOrderTaker = OrderTakerValidator.isOrderTakerEnv()

  get userCookie() {
    return localStorage.getItem("user_accept_cookies");
  }

  get listUsersCookies(): string[] {
    const users = localStorage.getItem("users_accept_cookies");
    return users ? JSON.parse(users) : [];
  }

  isLoadedContent: boolean

  constructor(
    public userInfoStoreService: UserInfoStoreService,
    private viewportScroller: ViewportScroller,
    private _iconsImplement: IconsImplementService,
    private _appStoreService: AppStoreService,
    private _authImplement: AuthImplementService,
    private _seoClient: SeoClientService,
    private _auth: Auth,
    private _authenticationTokenImplement: AuthenticationTokenImplementService,
    private _appManager: AppManagerService,
    private cardAssociatedService: CardAssociatedService,
    private _globalProductCardService: GlobalProductCardService,
    private matDialog: MatDialog,
    private router: Router,
    private activatedRouter: ActivatedRoute,
    private readonly strategy: ScrollStrategyOptions,
    private registerClient: RegisterClientService,
    private registerImplementService: RegisterImplementService,
    private userClientService: UserClientService,
    private updateAnonymousImplement: UpdateAnonymousImplementService,
    private localStorageService: LocalStorageService,
    private _geolocationService: GeolocationService,
    private _userGeolocationHelper: UserGeolocationHelperService,
    private _geolocationStoreService: GeolocationStoreService,
    private _hereMapPlacesService: HereMapPlacesService,
    /**
     * No quitar el globalProductImplement
     * Este servicio expone métodos que utiliza braindw
     */
    public globalProductImplement: GlobalProductImplementService,
    public algoliaInstanceService: AlgoliaInstanceService,
    private registrationValidation: RegistrationValidationService,
    private _breakpointBehavior: BreakpointBehavior,
    private _footerImplementService: FooterImplementService,
    private cookiesService: CookiesPolicyService,
    private mainLoaderService: MainLoaderService,
    private retValidateCartService: RetValidateCartService,
    private apigeeTokenService: ApigeeTokenService,
    private apigeeStoreService: ApigeeStoreService,
    private homeClientService: HomeClientService,
    private loadingService: MainLoaderService,
    @Inject(DOCUMENT) private document: Document

  ) {
    this._hereMapPlacesService.initializeApiKeyHere().subscribe(() => this.saveProvinceLocation());

    this.setFirstLogin();
    this.loadingService.authLoaded$.subscribe((event) => {
      if (event && !this.isLoadedContent) {
        this.homeClientService.loadUserRegister().subscribe((resp) => {
          this.isLoadedContent = true;

          // const uid = this._authImplement.currentUID;

          this.algoliaInstanceService.algoliaGetUserToken();
          this._hereMapPlacesService.initializeHereMapConfig();


          let id = (resp.data instanceof Object )? resp.data.authId : null;
          if(!id) return ;

          this.algoliaInstanceService.setAuthenticateUserToken(id);
        })
      }
    })

    environment.brand = OrderTakerValidator.isOrderTakerEnv()
      ? EBrand[EBrandAsset[this._appStoreService.brandAtention]]
      : environment.brand;

    this.algoliaInstanceService.initAlgoliaAA()

    this.apigeeTokenService
      .getTokenFromOauth()
      .subscribe((apigeeData) => {
        this.apigeeStoreService.oAuthToken.next(apigeeData);
        this.retValidateCartService.fillOnStartApp();
      });

    this.mainLoaderService.isLoaded$.subscribe((isLoaded) => {
      if (!isLoaded) {
         setTimeout(() => {
          combineLatest([
            this._footerImplementService.getFooter(),
            this.registerClient.currentUserData$.pipe(
              switchMap(data => {
                if (data == null || data === undefined) {
                  return this.registerClient.getUser$().pipe(
                    tap(user => console.log('Fetched user data:', user)),
                    catchError(error => {
                      console.error('Error fetching user data:', error);
                      return of(null);
                    })
                  );
                } else {
                  return of({ data } as RegisterResponse);
                }
              })
            )
          ]).subscribe(([mainFooter, user ]) => {
            if (!this.userCookie && mainFooter.cookies) {
              this.userResponseData = user?.data as UserRegister;
              this.userLoaded = true;
              this.footerCookies = mainFooter.cookies;
              if (
                !document.URL.includes("checkout") &&
                !this.userHaveCookiesAccepted(this.userResponseData?.id) &&
                !this.dialogPolicyStatus
              ) {
                this.dialogPolicyStatus = true;
                if (!OrderTakerValidator.isOrderTakerEnv()) {
                  this.showPolicyDialog(this.footerCookies);
                }
              }
            }

            this.registerClient.currentUserData$.subscribe((userData) => {
              this.userResponseData = userData?.data as UserRegister;

              const userIDCookies = localStorage.getItem(
                "userid_processed_cookies"
              );

              if (
                this.userResponseData &&
                !this.userHaveCookiesAccepted(this.userResponseData?.id)
              ) {
                if (
                  this.footerCookies &&
                  !this.dialogPolicyStatus &&
                  userIDCookies != "undefined" &&
                  userIDCookies != this.userResponseData.id
                ) {
                  this.cookiesService.resetLocalStorage();

                  if (!document.URL.includes("checkout")) {
                    this.dialogPolicyStatus = true;
                    if (!OrderTakerValidator.isOrderTakerEnv()) {
                      this.showPolicyDialog(this.footerCookies);
                    }
                  }
                }

                if (
                  this.userCookie != "undefined" &&
                  userIDCookies == "undefined"
                ) {
                  if (
                    this.userResponseData?.id &&
                    this.userResponseData.isAnonymous === false
                  ) {
                    this.saveStorageCookiesTimestamp(this.userResponseData);
                    this.registerImplementService
                      .doRegisterCookies(this.userResponseData)
                      .subscribe(() => this.hideCookiesPolicies());
                  }
                  // else {
                  //   this.saveStorageCookiesTimestamp(this.userResponseData);
                  //   this.updateAnonymousImplement
                  //     .doRegisterCookies()
                  //     .subscribe(() => this.hideCookiesPolicies());
                  // }
                }
              }
            });
          });
         }, 0);
      }
    });


    this.router.events.subscribe((event) => {
      this.manageSearchByAlgolia(event)

      this.manageCookiesPolicies(event)

      this.loadJsonStructured(event)

      this.loadAuthProviderAndSignOut(event)

      this.isBigScreenSubscription = _breakpointBehavior
        .mediaBreakpointUpObserver(BREAKPOINTS.lg)
        .subscribe((value) => (this.isBigScreen = value.matches));
    });

    this._iconsImplement.declareIcons();

    //   todo:  this.cardAssociatedService.getMifarmaConfigurationForRET$()
    this.cardAssociatedService
      .getCloudConfigurationForRET$()
      .subscribe((data) => {
        const { cardsAssociatedDefault, cardsAssociated } =
          data.fields.configuration;
        this._globalProductCardService.paymentMethodCard =
          cardsAssociated[parseInt(cardsAssociatedDefault, 10) - 1];
        this._globalProductCardService.paymentMethodCardType =
          cardsAssociatedDefault;
        this._globalProductCardService.paymentMethodName =
          cardsAssociated[parseInt(cardsAssociatedDefault, 10) - 1].valueTalon;
      });


    let secondApp;
    if (environment.brand == EBrand.mifarma) {
      const configFirebase = OrderTakerValidator.isOrderTakerEnv()
        ? environment.firebase
        : environment.firebase2;
      this._appManager.initializeFirebaseApp(configFirebase, "second");
      secondApp = this._appManager.getFirebaseApp("second");
    }

    this.userInfoStoreService.authStateObservable().subscribe((userData) => {
      if (userData.id) {
        this.isUserAuthenticated = true;
        if (environment.brand == EBrand.mifarma) {
          this.singInSecondFirebase(secondApp);
        } else {
          this._appManager.authenticatedFirebaseApp();
        }
      } else {
        this.isUserAuthenticated = false;
      }
    });



    onIdTokenChanged(this._auth, (user) => {
      if (user && !user.isAnonymous && this.isUserAuthenticated) {
        if (environment.brand == EBrand.mifarma) {
          this.singInSecondFirebase(secondApp);
        } else {
          this._appManager.authenticatedFirebaseApp();
        }
      }
    });

    if (!this.isBigScreen) {
      this._authImplement.redirectResult().then((response) => {
        if (response.credential) {
          _appStoreService.redirectLoginStatus = REDIRECT_STATUS.PENDING;
          if (_appStoreService.sourceLogin === USER_SOURCE_LOGIN.MAIN_LOGIN) {
            this.registrationValidation.validateUserRegisterMainLogin(response);
          } else {
            this.registrationValidation.validateUserRegisterCartLogin(response);
          }
        }
      });
    }
  }

  setFirstLogin(){
    this._authImplement.currentUserObservable.subscribe((user) => {

      if(!user?.metadata) return ;

      const lastLoginAt = new Date(user.metadata?.lastSignInTime);
      const createdAt = new Date(user.metadata?.creationTime);

      // Calcular la diferencia en minutos entre las dos fechas
      const diferenciaEnMilisegundos = lastLoginAt.getTime() - createdAt.getTime();
      const diferenciaEnMinutos = diferenciaEnMilisegundos / (60 * 1000);

      // Establecer first_login en función de la diferencia en minutos
      let first_login:boolean;
      if (diferenciaEnMinutos <= 5) {
          first_login = true;
      } else {
          first_login = false;
      }

      TaggingService.pushAtributeDataUser("first_login", first_login);

    });
  }

  saveStorageCookiesTimestamp(userResponseData: any) {
    const nowTimestamp = new Date().getTime().toString();
    localStorage.setItem(
      "userid_processed_cookies",
      userResponseData?.authUserId
    );
    localStorage.setItem("user_processed_cookies", nowTimestamp);
    //
    this.userHaveCookiesAccepted(userResponseData?.id);
  }

  hideCookiesPolicies() {
    this.dialogPolicyStatus = false;
    this.hidePolicyDialog();
  }

  singInSecondFirebase(secondApp) {
    this._authenticationTokenImplement.getCustomToken().subscribe((token) => {
      this._appManager
        .signInFirebaseAppWithCustomToken(secondApp, token.customToken)
        .subscribe((response) => {

          if (response) {
            this._appManager.authenticatedFirebaseApp();
          }
        });
    });
  }


  ngOnInit() {
    this.verifyGeolocation();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      window["prerenderReady"] = true;

    }, 18000);

    this.SetCokkiesPolicyScript();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }


  showPolicyDialog(data: FooterItem) {
    this.dialog = this.matDialog.open(CookiesPolicyComponent, {
      panelClass: "fp-cookies-policy",
      hasBackdrop: false,
      autoFocus: false,
      scrollStrategy: this.strategy.noop(),
      maxWidth: "440px",
      width: "100%",
      position: {
        bottom: "46px",
      },
      data,
    });

    this.dialog.afterClosed().subscribe((result) => {
      if (result?.closed) {
        this.dialogClosedByCustomer = true;
      }
    });
  }

  userHaveCookiesAccepted(userId): boolean {
    if (userId) {
      const users = this.listUsersCookies;
      const userExists = this.listUsersCookies.find((u) => u === userId);
      if (
        userExists == null &&
        (this.userCookie != null ||
          (this.userCookie && JSON.parse(this.userCookie) != false))
      ) {
        users.push(userId);
        localStorage.setItem("users_accept_cookies", JSON.stringify(users));
      }

      return userExists != null;
    }

    return false;
  }

  hidePolicyDialog() {
    this.dialog?.close();
  }

  manageCookiesPolicies(event: Event) {
    if (event instanceof NavigationEnd && this.userLoaded) {
      if (this.router.url.includes("checkout")) {
        this.hideCookiesPolicies();
      } else {
        if (
          !this.userHaveCookiesAccepted(this.userResponseData?.id) &&
          !this.dialogPolicyStatus &&
          this.footerCookies &&
          !this.dialogClosedByCustomer &&
          (this.userCookie == null ||
            (this.userCookie && JSON.parse(this.userCookie) === false))
        ) {
          this.dialogPolicyStatus = true;
          if (!this.isOrderTaker) this.showPolicyDialog(this.footerCookies);
        }
      }
    }
  }

  loadJsonStructured(event: Event) {
    const jsonStructured =
      EBrand[environment.brand] == EBrand.inkafarma
        ? JSON_INKA_STRUCTURED_DEFECT
        : JSON_MIFA_STRUCTURED_DEFECT;

    if (event instanceof NavigationEnd) {
      TaggingService.pageView(event["url"]);
      this._seoClient.setTitle("", false);
      this._seoClient.setKeywords();
      this._seoClient.setDescription();
      this._seoClient.setLink("canonical", "");
      this._seoClient.setStructuredData(jsonStructured);
    }
  }

  loadAuthProviderAndSignOut(event: Event) {
    if (event instanceof NavigationEnd) {
      this._appStoreService.setStorageItem(NAVIGATION_URL, event.url);

      window.scrollTo(0, 0);
      this.viewportScroller.scrollToPosition([0, 0]);

      // Validate if the user left the registration flow
      const authProvider = this._appStoreService.getStorageItem(AUTH_PROVIDER);

      if (
        authProvider &&
        event.url !== "/" + BUSINESS_PATHS.REGISTER &&
        event.url !== "/" + BUSINESS_PATHS.PRE_REGISTER &&
        !this._appStoreService.sourceLogin
      ) {
        this._authImplement.signOut();
        this._appStoreService.removeStorageItem(AUTH_PROVIDER);
        this._appStoreService.removeRedirectLoginStatus();
      }
    }
  }

  manageSearchByAlgolia(event: Event) {
    if (event instanceof NavigationEnd) {
      const urlSearch = `${BUSINESS_PATHS.SEARCH}?${BUSINESS_KEYS.SEARCH_QUERY_PARAM_KEY}`

      const isUrlSearch = event.url.includes(urlSearch)
      const isUrlDetailProduct = event.url.includes(`${BUSINESS_PATHS.PRODUCT}/`)

      if (isUrlSearch || isUrlDetailProduct) return
      this.localStorageService.setItem(LS_ALGOLIA_REDIRECTS, false)
    }
  }

  private SetCokkiesPolicyScript() {
    const isTom = OrderTakerValidator.isOrderTakerEnv();

    if(this.scriptCokkie || isTom) return;

    const brand = environment.brand;
    const script = document.createElement("script");

    script.type = "text/javascript";
    script.setAttribute("async", "async");

    if(brand === EBrand.mifarma) script.src = 'https://consent.trustarc.com/v2/notice/4moxhf'; // Set url script for mifarma
    else script.src = "https://consent.trustarc.com/v2/notice/6bujbq"; // Set url script for inkafarma

    document.head.appendChild(script);

    this.scriptCokkie = script;

    setTimeout(() => {
      const teConsent = document.querySelector('#teconsent');
      const consentBanner = document.querySelector('#consent-banner');
      if (teConsent && consentBanner) this.cookiesService.initializeCookieConsentListeners();
    }, 1500);
  }

  private verifyGeolocation() {
    const userSubs = this.userInfoStoreService.currentUser$.subscribe(u => {
      const exist = Boolean(u?.userResponse?.data)

      if(!exist) return;

      if (this._geolocationService.existNavigatorGeolocation) {
        this._geolocationService.getGeolocationStatus()
          .pipe(take(1))
          .subscribe((status) => {
            if (status.state === GEO_STATUS_TYPES.ACCEPTED) {
              this._geolocationService.isAcceptedGeolocation = true;
              this._geolocationService.loadGeolocation();
              this._geolocationStoreService.availableLoc = true;
            } else if (status.state === GEO_STATUS_TYPES.DENIED) {
              this._geolocationService.isAcceptedGeolocation = false;
              this._geolocationService.loadGeolocation();
              this._geolocationStoreService.availableLoc = false;
            } else {
              this._geolocationService.isAcceptedGeolocation = false;
            }
          });
      }

      userSubs.unsubscribe();
    })

    this.subscriptions.push(userSubs);
  }

  private saveProvinceLocation() {
    const subcription = this._userGeolocationHelper.getGeolocationPermissions$().subscribe(result => {
      this._hereMapPlacesService.getFormattedGeolocationAddress$().pipe(take(1)).subscribe((data) => {
        if (data) {
          this._geolocationStoreService.provinceGeo = data.address.state;
          this._geolocationService.isAcceptedGeolocation = true;
          this._geolocationStoreService.addressInfo = data;
        } else {
          this._geolocationService.isAcceptedGeolocation = false;
          this._geolocationStoreService.clearAddressInfo();
          this._geolocationService.loadGeolocation();
        }
      });

      this._geolocationStoreService.addressLoaded = "true";
      this._geolocationStoreService.cordsGeo = result;
    });

    this.subscriptions.push(subcription);
  }
}
