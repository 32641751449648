import { Injectable } from "@angular/core";

@Injectable({ providedIn: "root" })
export class PlatformInformationService {
	getOSInfo(): { name: string; version: string } {
		const userAgent = window.navigator.userAgent;
		let osName = "Unknown OS";
		let osVersion = "Unknown Version";

		if (userAgent.indexOf("Win") > -1) {
			osName = "Windows";
			if (userAgent.indexOf("Windows NT 10.0") > -1) {
				// Check for Windows 11
				if (userAgent.indexOf("Windows NT 10.0; Win64; x64") > -1) {
					osVersion = "11";
				} else {
					osVersion = "10";
				}
			} else if (userAgent.indexOf("Windows NT 6.3") > -1) osVersion = "8.1";
			else if (userAgent.indexOf("Windows NT 6.2") > -1) osVersion = "8";
			else if (userAgent.indexOf("Windows NT 6.1") > -1) osVersion = "7";
		} else if (userAgent.indexOf("Mac") > -1) {
			osName = "macOS";
			osVersion = userAgent.split("Mac OS X ")[1]?.split(")")[0] || "Unknown Version";
		} else if (userAgent.indexOf("Linux") > -1) {
			osName = "Linux";
		} else if (userAgent.indexOf("Android") > -1) {
			osName = "Android";
			osVersion = userAgent.split("Android ")[1]?.split(";")[0] || "Unknown Version";
		} else if (userAgent.indexOf("like Mac") > -1) {
			osName = "iOS";
			osVersion = userAgent.split("OS ")[1]?.split(" ")[0]?.replace(/_/g, ".") || "Unknown Version";
		}

		return { name: osName, version: osVersion };
	}

	getBrowserInfo(): { name: string; version: string } {
		const userAgent = window.navigator.userAgent;
		let browserName = "Unknown Browser";
		let browserVersion = "Unknown Version";

		// Opera
		if (userAgent.indexOf("OPR") > -1 || userAgent.indexOf("Opera") > -1) {
			browserName = "Opera";
			browserVersion = this.extractVersion(userAgent, /(OPR|Opera)\/([\d.]+)/);
		}
		// Edge
		else if (userAgent.indexOf("Edg") > -1) {
			browserName = "Microsoft Edge";
			browserVersion = this.extractVersion(userAgent, /Edg\/([\d.]+)/);
		}
		// Chrome
		else if (userAgent.indexOf("Chrome") > -1) {
			browserName = "Chrome";
			browserVersion = this.extractVersion(userAgent, /Chrome\/([\d.]+)/);
		}
		// Safari
		else if (userAgent.indexOf("Safari") > -1) {
			browserName = "Safari";
			browserVersion = this.extractVersion(userAgent, /Version\/([\d.]+)/);
		}
		// Firefox
		else if (userAgent.indexOf("Firefox") > -1) {
			browserName = "Firefox";
			browserVersion = this.extractVersion(userAgent, /Firefox\/([\d.]+)/);
		}
		// Internet Explorer
		else if (userAgent.indexOf("MSIE") > -1 || userAgent.indexOf("Trident/") > -1) {
			browserName = "Internet Explorer";
			browserVersion = this.extractVersion(userAgent, /(?:MSIE |rv:)([\d.]+)/);
		}

		return { name: browserName, version: browserVersion };
	}

	private extractVersion(str: string, regex: RegExp): string {
		const match = str.match(regex);
		return match && match.length > 1 ? match[1] : "Unknown Version";
	}

	isResponsiveMode(): boolean {
		const width = window.innerWidth;
		return width < 768;
	}
}
