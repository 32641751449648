import { GenericService } from "../generic";
import { Observable, BehaviorSubject } from "rxjs";
import { ENDPOINTS } from "../../parameters/endpoints";
import { IShoppingCartResponse } from "../../models/shopping-cart/shopping-cart-response.interfase";
import { ShoppingCartResponse } from "../../models/shopping-cart/shopping-cart-response.model";
import { map } from "rxjs/operators";
import { ShoppingCartRequest } from "../../models/shopping-cart/shopping-cart-request.model";
import { HttpClient, HttpParams } from "@angular/common/http";
import { IProductDefault } from "../../models/product-default/product-default.interface";
import { AppStoreService } from "../../stores/app/app-store.service";
import { Injectable } from "@angular/core";
import { environment } from "@environments/environment";
import { EBrand, EBrandCode } from "@parameters/access/brand.parameter";
import { OrderTakerValidator } from "@validators/order-taker.validator";
import { of } from "rxjs";
import { concatMap, take } from "rxjs/operators";
import { UserClientService } from "../user/user-client.service";
import { Client } from "@models/client/client.model";
import { UserInfoStoreService } from "@stores/user/user-info-store.service";
import { PlatformInformationService } from "@service/platform-information/platform-information.service";

@Injectable()
export class CartClientService {
	pathExtra = "&whitelabel=&email=";
	pathExtra2 = "&u=";

	constructor(
		private genericService: GenericService,
		public http: HttpClient,
		private _appStoreService: AppStoreService,
		private _userClientService: UserClientService,
		private _userStoreService: UserInfoStoreService,
		private plaftormService: PlatformInformationService
	) {}

	public getCart(params: ShoppingCartRequest): Observable<ShoppingCartResponse> {
		return this.genericService.genericPostCart<IShoppingCartResponse>(ENDPOINTS.POST_CART, params).pipe(
			map((iShoppingCartResponse: IShoppingCartResponse) => {
				return new ShoppingCartResponse(iShoppingCartResponse);
			})
		);
	}

	// BRAINDW REFACTOR
	loadUserRegister() {
		const userData = this._userStoreService.lastUserData;

		if (userData) {
			return of(userData);
		}

		return this._userClientService.getUser$().pipe();
	}

	public getContenidosBrainHome(path = "home/1//&guid=", sectionID, client?) {
		if (client) {
			const { drugstoreStock } = this._appStoreService;
			let url =
				ENDPOINTS.GET_LIST_CONTENIDOS +
				(drugstoreStock ? drugstoreStock : "") +
				this.pathExtra +
				(client?.data?.email || "") +
				this.pathExtra2 +
				path +
				`&sectionID=${sectionID}`;

			return this.genericService.genericGetWithoutHeader(url).pipe();
		}

		return this.loadUserRegister().pipe(
			concatMap((client: any) => {
				const { drugstoreStock } = this._appStoreService;
				let url =
					ENDPOINTS.GET_LIST_CONTENIDOS +
					(drugstoreStock ? drugstoreStock : "") +
					this.pathExtra +
					(client?.data?.email || "") +
					this.pathExtra2 +
					path +
					`&sectionID=${sectionID}`;

				return this.genericService.genericGetWithoutHeader(url);
			})
		);
	}
	// BRAINDW REFACTOR

	// loadUserRegister() {
	//   return new Promise(resolve => {
	//     this._userClientService.getUser$()
	//       .pipe(take(1)).subscribe(data => {
	//         if (data['data']) {
	//           resolve(data['data'])
	//         } else {
	//           resolve('')
	//         }
	//       });
	//   })
	// }

	// public async getContenidosBrainHome(path = 'home/1//&guid=', sectionID) {
	//   const client: any = await this.loadUserRegister();
	//   const { drugstoreStock } = this._appStoreService;
	//   let url = ENDPOINTS.GET_LIST_CONTENIDOS + (drugstoreStock ? drugstoreStock : '') + this.pathExtra + (client.email || '') + this.pathExtra2 + path + `&sectionID=${sectionID}`
	//   const data = await this.genericService.genericGetWithoutHeader(url).toPromise();
	//   return data;
	// }

	public getProductsByIds(ids: Array<string>): Observable<Array<IProductDefault>> {
		const idsArray = ids.toString();
		return this.genericService.genericGet(ENDPOINTS.GET_PRODUCTS_BY_IDS + idsArray);
	}

	// PENDIENTE INTERFAZ
	public potsValidateAddToCart(body: any) {
		const url = OrderTakerValidator.isOrderTakerEnv()
			? ENDPOINTS.APIGEE_PATH_VALIDATE_ADD_TO_CART_TOMADOR
			: ENDPOINTS.APIGEE_PATH_VALIDATE_ADD_TO_CART;
		return this.genericService.genericPost(url, body);
	}

	postValidatePuchaseNumber(puchaseNumberId: string | number, error: string, service: string, _fromClick: boolean) {
		const osName = this.plaftormService.getOSInfo().name;
		const osVersion = this.plaftormService.getOSInfo().version;
		const browser = `${this.plaftormService.getBrowserInfo().name} ${this.plaftormService.getBrowserInfo().version}`;
		const modo = this.plaftormService.isResponsiveMode() ? "Responsive" : "Desktop";
		const fromClick = _fromClick ?? true;

		const params_header = new HttpParams().set("purchaseNumber", puchaseNumberId);
		const body = { osName, osVersion, browser, modo, errorTrace: error, service, fromClick };

		return this.genericService.genericPost(ENDPOINTS.CANCEL_TRANSACTION, body, params_header);
	}
}
