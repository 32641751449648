<div class="input-search-for-ret" [ngClass]="{ 'mb-3': !isResponsive }" [formGroup]="addressForm">
	<fp-input-searcher
		formControlName="referenceAddress"
		inputId=""
		(blurEvent)="blurEvent()"
		(focusEvent)="focusEvent()"
    customClass="input-search-for-ret-custom"
	>
	</fp-input-searcher>
	<div
		class="input-search-message paragraph--4 text-dark-gray text-center my-4"
		*ngIf="!isResponsive && drugstores.length > 0 && pickUpConfig.config.suggestedMessageForPredictiveAddresses"
	>
		{{ pickUpConfig.config.suggestedMessageForPredictiveAddresses }}
	</div>
	<div class="address-predictive-list pr-3 pl-3" *ngIf="isFocus">
		<ng-container *ngIf="searchAutocompleteList.length === 0 && geolocationReverseAddress && !byCoords">
      <div class="matched-address"  (click)="locateGeolocationOnTheHereMap()">
        <div class="address-icon pt-3">
          <fp-icon iconName="gps-outline"></fp-icon>
        </div>
        <div class="address-detail pb-3 pt-3">
          <div class="address-nickname label-black mb-2">
            {{ geolocationReverseAddress.title }}
          </div>
          <div class="address-value label--2 label-lg--1">
            {{ geolocationReverseAddress.title }}
          </div>
        </div>
      </div>
    </ng-container>
		<ng-container *ngIf="searchAutocompleteList.length > 0">
			<div class="matched-address" *ngFor="let place of searchAutocompleteList" (click)="selectStoreAddress(place)">
				<div class="address-icon pt-3">
					<fp-icon iconName="gps-outline"></fp-icon>
				</div>
				<div class="address-detail pb-3 pt-3">
					<div class="address-nickname label-black mb-2">
						{{ place.subTitle }}
					</div>
					<div class="address-value label--2 label-lg--1">
						{{ place.subTitle }}
					</div>
				</div>
			</div>
		</ng-container>
	</div>
</div>

<div #inputSearchForRET [hidden]="true">here show content</div>
