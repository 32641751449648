export * from "./adaptive-image/adaptive-image.pipe";
export * from "./custom-number/custom-number.pipe";
export * from "./url-format/url-format.pipe";
export * from "./md-to-html/md-to-html.pipe";
export * from "./safe-html/safe-html.pipe";

import { AdaptiveImagePipe } from "./adaptive-image/adaptive-image.pipe";
import { CustomNumberPipe } from "./custom-number/custom-number.pipe";
import { UrlFormatPipe } from "./url-format/url-format.pipe";
import { CapitalCasePipe } from "./capital-case/capital-case.pipe";
import { MdToHtmlPipe } from "./md-to-html/md-to-html.pipe";
import { DigitsOnlyDirective } from "./digits-only/digits-only.directive";
import { SafeHtmlPipe } from "./safe-html/safe-html.pipe";
import { EmailMaskPipe } from "./email-mask/email-mask.pipe";
import { PhoneMaskPipe } from "./phone-mask/phone-mask.pipe";
import { MaskSensitiveDataPipe } from "./mask-sensitive-data/mask-sensitive-data.pipe";

export const PIPES = [
  AdaptiveImagePipe,
  CustomNumberPipe,
  UrlFormatPipe,
  CapitalCasePipe,
  MdToHtmlPipe,
  DigitsOnlyDirective,
  SafeHtmlPipe,
  EmailMaskPipe,
  PhoneMaskPipe,
  MaskSensitiveDataPipe,
];
