import { Injectable } from '@angular/core';
import { HandleFilterAlgolioRequest, SearchLandingAlgoliaRequest, SearchLandingMultiIndexRequest } from '@models/search-landing/search-landing-algolia-request.model';
import { take } from 'rxjs/operators';
import { SearchLandingRequest } from '../../../shared/models/search-landing/search-landing-request.model';
import { SearchLandingImplementService } from './search-landing-implement.service';
import { Observable } from 'rxjs';

@Injectable()
export class SearchLandingRequestService {
  private searchLandingRequest: SearchLandingRequest;
  private searchLandingAlgoliaRequest: SearchLandingAlgoliaRequest;

  constructor(
    private _searchLandingImplement: SearchLandingImplementService,
  ) {
  }

  public searchLandingRequest$(searchLandingRequest: string) {
    this.searchLandingRequest = new SearchLandingRequest(searchLandingRequest);
    return this._searchLandingImplement.getSearchLandingFilters(this.searchLandingRequest).pipe(take(1));
  }


  public searchLangingAlgolia$(
    paramReq: string,
    facets: Array<string>,
    selectedCategories?: Array<any>,
    length?: number,
    max?: number,
    indexNames?: string | string[],  // Acepta string o array para múltiples índices
    algoliAPi?: string,
    apiKey?: string,
    analyticsTags?: string[],
    apiIndex?: string
  ): Observable<any> {
    
    // Si es una consulta de sugerencias, usamos el objeto simplificado
    if (indexNames === apiIndex + '_query_suggestions') {
      this.searchLandingAlgoliaRequest = { "params": "query=" + paramReq, "clickAnalytics": true };
      // Aquí aseguramos que se devuelva un observable
      return this._searchLandingImplement.getSearchLandingAlgolaFilters(this.searchLandingAlgoliaRequest, indexNames, algoliAPi, apiKey).pipe(take(1));
    } 
  
    // Si indexNames es un array, creamos múltiples requests
    if (Array.isArray(indexNames)) {
      const multiIndexRequests = indexNames.map((indexName, idx) => {
        const isCustomParamsForIndex = idx === 0;
        const facetsForIndex = isCustomParamsForIndex ? facets : [];
        const selectedCategoriesForIndex = isCustomParamsForIndex ? selectedCategories : [];
        
        return new SearchLandingMultiIndexRequest(
          indexName,
          paramReq,
          facetsForIndex,
          selectedCategoriesForIndex,
          length,
          max
        );
      });
  
      // Llamar al método multi-index
      return this._searchLandingImplement.getSearchLandingAlgolaFilters(multiIndexRequests, null, algoliAPi, apiKey).pipe(take(1));
    }
  
    // Para un solo índice, usamos la solicitud normal
    this.searchLandingAlgoliaRequest = new SearchLandingAlgoliaRequest(paramReq, facets, selectedCategories, length, max);
    if (analyticsTags && analyticsTags.length > 0) {
      this.searchLandingAlgoliaRequest.analyticsTags = analyticsTags;
      this.searchLandingAlgoliaRequest.clickAnalytics = true;
    }
  
    // Aquí también aseguramos que se devuelva un observable
    return this._searchLandingImplement.getSearchLandingAlgolaFilters(this.searchLandingAlgoliaRequest, indexNames, algoliAPi, apiKey).pipe(take(1));
  }
  
  public handleSelectedAlgoliaRequest$(request: HandleFilterAlgolioRequest){
    return this._searchLandingImplement.handleSelectedRequestAlgolia(request).pipe(take(1))
  }

}
