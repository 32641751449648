<div class="row container-price">
	<ng-container *ngIf="productStatus === eProductStatus.available || productStatus === 23 || productStatus === 24; else templateEmpty">
		<ng-container *ngIf="subTotal; else templateRegularPrice">
			<div class="col-12 d-flex align-items-center justify-content-end pb-1" *ngIf="regularPrice > 0">
				<fp-product-regular-price-mifa [regularPrice]="regularPrice" [flkTextStrike]="subTotal > 0 ? true : false" fontSize="--1">
				</fp-product-regular-price-mifa>
			</div>
			<div class="col-12 d-flex align-items-center justify-content-end pb-1 currencyRegular " *ngIf="subTotal">
				<fp-icon class="d-inline-flex" iconSize="20" iconHeight="16" iconName="card-monedero"></fp-icon>
				<span class="label label-xl--1 label-xs--1 container-icon text-right currency">{{ subTotal | currency : "S/ " }}</span>
			</div>
		</ng-container>
		<div class="col-12 d-flex align-items-center justify-content-end" *ngIf="subTotalOffer">
			<!-- <fp-icon class="d-inline-flex" iconSize="20" iconHeight="16" iconName="credit-cards-oh-mf"></fp-icon> -->
			<div class="container-icons-card">
				<ng-container *ngFor="let image of _globalProductCardService.paymentMethodCard.imageArray; trackBy: trackByImage">
					<img class="icon-card" [src]="image" alt="icono tarjeta inkafarma" />
				</ng-container>
			</div>
			<span class="label-black-lg--1 label-black-xl label-black-xxl--1 label-black-xs--1 container-icon text-right currency">{{
				subTotalOffer | currency : "S/ "
			}}</span>
		</div>
		<ng-template #templateRegularPrice>
			<div class="col-12 display-vertical-center justify-content-end currencyRegular" *ngIf="regularPrice > 0">
				<span class="label label-xl--1 label-xs--1 text-dark currency" >{{ regularPrice | currency : "S/ " }}</span>
			</div>
		</ng-template>
	</ng-container>
	<ng-template #templateEmpty>
		<span class="label-black label-black-lg-1 label-black-xl text-dark">---</span>
	</ng-template>
</div>
