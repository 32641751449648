import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { WhatsappModalService } from "../Service/whatsapp-modal.service";
import { ClientManagerFormService } from "../../client-manager/forms/client-manager-form.service";
import { SelectItem } from "@models/documents-type/document-type.model";
import { DOCUMENT_TYPES, DOCUMENT_TYPE_DNI, TYPE_CLIENT_MANAGER } from "../../client-manager/paramaters/client-manager.const";
import { CONFIG_USER_NOT_REGISTERED } from "@parameters/enums";
import { ClientStoreService } from "@stores/client/client-store.service";
import { Client } from "@models/client/client.model";
import { DOCUMENT_TYPE } from "@parameters/global";
import { AliviamedImplementService } from "src/app/business/static-landing/views/aliviamed/service/aliviamed-implement.service";
import { IAliviamedWhatsappRequest } from "@models/aliviamed/aliviamed.model";
import { ShoppingCartImplementService } from "src/app/shared/service/shopping-cart/shopping-cart-implement.service";
import { MiniCartResponse } from "@models/mini-cart/mini-cart-response.model";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ProductImplementService } from "../../product-card/service/product-implement.service";
import {Router} from "@angular/router"
import { AppStoreService } from "@stores/app/app-store.service";
import { ShoppingListStoreService } from "../../../../shared/stores/shopping-list/shopping-list-store.service";
import { Product } from "../../product-card/models/product.model";

// const documents = { '002' : 1, '004' : 2, '003' : 3 };
const documents = { 'DNI' : 1, 'Carnet de Extranjería' : 2, 'Pasaporte' : 3 };
// const equivalents = {
//   'Pasaporte':'003',
//   'Carnet de Extranjería': '004',
//   'DNI':'002'
// }
@Component({
  selector: "fp-whatsapp-sale",
  templateUrl: "./whatsapp-sale.component.html",
  styleUrls: ["./whatsapp-sale.component.sass"],
})
export class WhatsappSaleModalComponent implements OnInit {
  documentTypeList: SelectItem[] = DOCUMENT_TYPES;
  maxLength = CONFIG_USER_NOT_REGISTERED.DNI_LENGTH;
  loader: boolean;
  public documentTypeOptions: Array<string>;
  public documentType: string = "DNI";
  public documentTypePatient: string = "DNI";
  title = "Generar link de pago para Consulta Médica Aliviamed";
  subTitle = "Llena los datos necesarios para generar el link de pago.";
  textButton = "";
  loadingLink = false;
  loadingLinkSearch = false;
  userTomador: any;
  clientTomador: any;
  idShoppingCart: number;
  isPatient = false;
  modalForm: FormGroup;
  private skuAliviamed: string;
  private typeDocument = new FormControl();
  private typeDocumentAgent = new FormControl(1);
  constructor(
    public _dialogRef: MatDialogRef<WhatsappSaleModalComponent>,
    private findClientService: WhatsappModalService,
    public _clientManagerForm: ClientManagerFormService,
    public _clientStoreService: ClientStoreService,
    private aliviamedImplement: AliviamedImplementService,
    private _shoppingCartImplement: ShoppingCartImplementService,
    private _productImplement: ProductImplementService,
    private router: Router,
    private _appStore: AppStoreService,
    private shoppingListStore: ShoppingListStoreService,
    @Inject(MAT_DIALOG_DATA) public data: { productId: string },
  ) {
    this.modalForm = new FormGroup({
      agentType: new FormControl(''),
      agentDocument: new FormControl(''),
      name: new FormControl(''),
      lastNamePat: new FormControl(''),
      lastNameMat: new FormControl(''),
      identityDocumentNumber: new FormControl('',[Validators.required, Validators.pattern('^[0-9A-Za-z\-]*$'), Validators.minLength(8)]),
      idIdentityDocument: new FormControl(''),
      phone: new FormControl('',[Validators.required, Validators.minLength(9), Validators.maxLength(11)]),
      email: new FormControl('',[Validators.required, Validators.email]),
      typeDocument: this.typeDocument,
      typeDocumentAgent: this.typeDocumentAgent,
    });
  }

  public productQuantity = 0;

  ngOnInit(): void {
    this.isAliviamed();
      this._clientManagerForm.validAllFields();
      this.setDefaultDocumentType();
      this.userTomador =  JSON.parse(localStorage.getItem("user"));
      this.clientTomador = JSON.parse(localStorage.getItem("OT_CLIENT_DATA"));
      this._shoppingCartImplement.getShoppingCart().subscribe(
        (miniCartResponse: MiniCartResponse) => {
          this.idShoppingCart = miniCartResponse.id;
        }
      );

      this.modalForm.patchValue({
        name: this.clientTomador.name,
        lastNamePat: this.clientTomador.lastNamePat,
        lastNameMat: this.clientTomador.lastNameMat,
        email: this.clientTomador.email,
        phone: this.clientTomador.phone,
        identityDocumentNumber:  this.clientTomador.identityDocumentNumber,
      });

      const client = JSON.parse(localStorage.getItem('OT_CLIENT_DATA'));
      this.setWSpInit(client);
  }

  setWSpInit(client){

    const params = {
      specialityId: '241638',
      localCode: 'TOMADOR',
      operatorDNI: this.userTomador.id,
      saleChannel: 'TOMADOR',
      company: 'INKAFARMA',
      callMode: 'AUDIO',
      invoiceNumber: ''+this.idShoppingCart,
      cost: '19.00',
      name: client.name,
      lastName: client.lastNamePat,
      second_lastName: client.lastNameMat,
      document_number: client.identityDocumentNumber,
      document_type: client.idIdentityDocument,
      phone:client.phone,
      email: client.email,
      is_patient: 0,
      document_number_patient:  '',
      document_type_patient: '',
      contact_phone_patient: '',
    };


    localStorage.setItem('userWsp', JSON.stringify(params));
  }

  isAliviamed() {
    this.skuAliviamed = this.data.productId;
  }

    onClose(): void {
      localStorage.removeItem('userWsp');
      this._productImplement.deleteProduct(this.skuAliviamed);
      this._dialogRef.close();
    }

    openIsPatient(): void{
      this.isPatient = true;
      this.modalForm.get('agentDocument').addValidators([Validators.required, Validators.minLength(8), Validators.maxLength(8), Validators.pattern('^[0-9]*$')]);

      setTimeout(() => {
        const modal = document.getElementsByClassName('mat-dialog-container')[0];
        const inpt = document.getElementById('ctrl--maternal-lastname2');
        modal.scrollTo(0, 1000);
        inpt.focus();
      }, 0);

    }

    isPatientBefore(): void{
      this.isPatient = false;
      this.modalForm.get('agentDocument').clearValidators();
      this.modalForm.get('agentDocument').updateValueAndValidity()
    }

    searchPatient() : void{
      if (this.loadingLinkSearch) return;
      this.loadingLinkSearch = true;
      const document = this.modalForm.get('identityDocumentNumber').value;
      this.aliviamedImplement.getPatientsData$(document).subscribe(response => {
        this.modalForm.patchValue({
          name: response.name,
          lastNamePat: response.last_name,
          lastNameMat: response.second_last_name,
          email: response.email,
          phone: response.contact_phone,
        });
        this.loadingLinkSearch = false;
      });
    }

    addProductToShoppingCart() {
      if(this.productQuantity === 0) this.productQuantity = 1
      this.router.navigate(['/checkout'])
    }

    onSubmit() : void{


      const params: IAliviamedWhatsappRequest = {
        specialityId: '241638',
        localCode: 'TOMADOR',
        operatorDNI: this.userTomador.id,
        saleChannel: 'TOMADOR',
        company: 'INKAFARMA',
        callMode: 'AUDIO',
        invoiceNumber: ''+this.idShoppingCart,
        cost: '19.00',
        name: this.modalForm.get('name').value,
        lastName: this.modalForm.get('lastNamePat').value,
        second_lastName: this.modalForm.get('lastNameMat').value,
        document_number: this.modalForm.get('identityDocumentNumber').value,
        document_type: this.typeDocument.value||documents[this.documentType],
        phone: this.modalForm.get('phone').value,
        email: this.modalForm.get('email').value,
        is_patient: this.isPatient? 1 : 0,
        document_number_patient: this.isPatient? this.modalForm.get('agentDocument').value.toUpperCase() : '',
        document_type_patient: this.isPatient? documents[this.documentTypePatient] : '',
        contact_phone_patient: '',
      };

      localStorage.setItem('userWsp', JSON.stringify(params))
      localStorage.removeItem('modalGenerateLink');

      document.location.href="/checkout"

    }

    setDocumentType(documentTypeOption: string) {
      const documentType = this.documentTypeList.find((value) => value.name === documentTypeOption);
      this._clientManagerForm.documentTypeControl.setValue(documentType.id);
      this.typeDocument.setValue(documentTypeOption);
    }

    setDocumentTypeAgent(documentTypeOption: string) {
      const documentType = this.documentTypeList.find((value) => value.name === documentTypeOption);
      this._clientManagerForm.documentTypeControl.setValue(documentType.id);
      this.typeDocumentAgent.setValue(documentType.id);
      this.documentTypePatient = documentTypeOption;


      this.modalForm.get('agentDocument').clearValidators();
      if(this.documentTypePatient==='DNI'){
        this.modalForm.get('agentDocument').addValidators([Validators.required, Validators.minLength(8), Validators.maxLength(8), Validators.pattern('^[0-9]*$'), Validators.maxLength(8)]);
      }else{
        this.modalForm.get('agentDocument').addValidators([Validators.required, Validators.minLength(8), Validators.maxLength(20), Validators.pattern('^[0-9A-Za-z\-]*$')]);
      }
      this.modalForm.get('agentDocument').updateValueAndValidity()
    }

    setDefaultDocumentType() {
       const equivalents = {
        'Pasaporte': '003',
        'Carnet de Extranjería': '004',
        'DNI': '002',
      }
      if (this._clientStoreService.client) {
        const { idIdentityDocument }: Client = this._clientStoreService.client;
        const documentTypeFound = this.documentTypeList.find((documentType) => documentType.id === idIdentityDocument);

        this.documentType = documentTypeFound ? documentTypeFound.name : null;
        this._clientManagerForm.documentTypeControl.setValue(documentTypeFound ? documentTypeFound.id : null);
        this.configValidationDocument(documentTypeFound ? documentTypeFound.name : DOCUMENT_TYPE_DNI.name);

      } else {
        this.documentType = DOCUMENT_TYPE_DNI.name;
        this.configValidationDocument(DOCUMENT_TYPE_DNI.name);
        this.typeDocumentAgent.setValue(DOCUMENT_TYPE_DNI.id);
        this.typeDocument.setValue(DOCUMENT_TYPE_DNI.id);
        this.documentTypePatient = DOCUMENT_TYPE_DNI.name;
      }

      this.documentTypeOptions = this.documentTypeList.map((option) => option.name);


      this.configValidationDocument( equivalents[this.documentType]);

      this._clientManagerForm.documentTypeControl.valueChanges.subscribe((value) => {
        this.configValidationDocument(value);
      });


    }

    configValidationDocument(typeDocument: string) {

      switch (typeDocument) {
        case DOCUMENT_TYPE.DNI:
          this._clientManagerForm.documentControl.setDniValidation();
          this.maxLength = CONFIG_USER_NOT_REGISTERED.DNI_LENGTH;
          break;
          case DOCUMENT_TYPE.CE:
          this._clientManagerForm.documentControl.setCEValidation();
          this.maxLength = CONFIG_USER_NOT_REGISTERED.CE_MAX_LENGTH;
          break;
          case DOCUMENT_TYPE.PAS:
          this._clientManagerForm.documentControl.setPassportValidation();
          this.maxLength = CONFIG_USER_NOT_REGISTERED.PASSPORT_MAX_LENGTH;
          break;
        default:
          this._clientManagerForm.documentControl.setDniValidation();
          this.maxLength = CONFIG_USER_NOT_REGISTERED.DNI_LENGTH;
          break;
      }
      this._clientManagerForm.documentControl.setValue("");
    }

}
