import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'fp-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.sass']
})
export class InputComponent implements OnInit {

  @Output() inputBlurEvent = new EventEmitter();
  @Output() inputFocusEvent = new EventEmitter();


  @Input() inputDigitOnlyPipe: boolean;

  @Input() inputId = '';
  @Input() inputName = '';
  @Input() inputType = 'text';
  @Input() inputFocus = true;
  @Input() inputPlaceholder = '';
  @Input() inputClass = '';

  @Input() inputFormGroup: FormGroup = new FormGroup({});
  @Input() inputFormControlName = 'input';
  @Input() inputValidations: boolean;
  @Input() inputRequired: boolean;
  @Input() inputValueControl = '';
  @Input() disabled: boolean;
  public inputFormControl: FormControl;

  public inputDisabledControl: boolean;

  @Input()
  set inputDisabled(disabled: boolean) {
    this.inputDisabledControl = !!disabled;
    const action = disabled ? 'disable' : 'enable';
    if (this.inputFormControl) {
      this.inputFormControl[action]();
    }
  }

  @Input()
  set inputValue(value: string) {
    if (typeof value === 'string' && this.inputFormControl) {
      this.inputFormControl.setValue(value);
      this.inputValueControl = value;
    }
  }

  @Input() inputMaxLength: number;
  public inputFormErrorMessage: string;

  @Input() inputError: boolean;
  @Input() inputErrorMessage: string;

  // Input para activar/desactivar enmascarado
  @Input() isMaskEnabled: boolean = false;

  // Input para especificar el tipo de enmascarado
  @Input() maskType: string = '';
  
  constructor(public _formBuilder: FormBuilder) {
  }

  ngOnInit(): void {
    const controlValue = this.setControlValues();
    const controlValidations = this.setControlValidations();

    this.inputFormControl = this._formBuilder.control(
      controlValue,
      controlValidations
    );

    this.inputFormGroup.addControl(this.inputFormControlName, this.inputFormControl);
  }

  get formGroup() {
    return this.inputFormGroup.controls;
  }

  setControlValidations() {
    let controlValidation = [];

    if (this.inputRequired) {
      controlValidation = [...controlValidation, Validators.required];
    }
    if (!!this.inputMaxLength) {
      controlValidation = [...controlValidation, Validators.maxLength(this.inputMaxLength)];
    }
    if (this.inputType === 'email') {
      const emailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      controlValidation = [...controlValidation, Validators.email, Validators.pattern(emailPattern)];
    }

    return controlValidation;
  }

  setControlValues() {
    let controlValue: any = this.inputValueControl;
    if (this.inputDisabledControl) {
      controlValue = { value: this.inputValueControl, disabled: this.inputDisabledControl };
    }

    return controlValue;
  }

  getFormError() {
    const formErrors = this.formGroup[this.inputFormControlName]?.errors
    return this.inputError ||
      !!formErrors && formErrors['email'] && this.inputFormGroup.touched ||
      !!formErrors && formErrors['pattern'] && this.inputFormGroup.touched;
  }

}
