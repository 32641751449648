import { IProductAliviamed } from '@models/product-default/product-default.interface';
import { EProductPresentation } from '../../../core/modules/product-card/models/product-presentation.enum';

export const DEFAULT_SELLER_ID = "inkafarma";

export class ProductShoppingCartRequest {
	public productId: string;
	public presentationIdSelected: number;
	public quantity: number;
	public sellerId: string;
	public sellerName: string;
	public productPack?: boolean;
	public type?: string;
	public isComplementary?: boolean;
	public productAliviamed?: IProductAliviamed;
}

export class FromProductToProductShoppingCartRequest extends ProductShoppingCartRequest {
	constructor(
		id: string,
		quantity: number = 0,
		presentation: number = EProductPresentation.pack,
		sellerId = "",
		sellerName = "",
		productPack: boolean = false,
		type?,
		productAliviamed: IProductAliviamed = null
	) {
		super();
		this.productId = id || "";
		this.presentationIdSelected = presentation || EProductPresentation.pack;
		this.quantity = quantity || 0;
		this.sellerId = sellerId || "";
		this.sellerName = sellerName || "";
		this.productPack = productPack || false;
		this.isComplementary = type == "complementary" ? true : false;
		this.productAliviamed = productAliviamed || null;
	}
}
