<div class="choose-a-store-modal" [ngClass]="{ 'modal--disabled': isDisableChooseStore }">
	<div class="button-modal-close" (click)="closeXEvent()">
		<span class="icon icon-24-close"></span>
	</div>

	<fp-section-container-title [svgName]="getStoreIconName" iconSize="24px" title="Boticas disponibles para retiro">
	</fp-section-container-title>

	<fp-sub-section-container>
		<div class="content nearby-drugstores-contaner mt-4">
			<div class="row nearby-drugstores-contaner__search">
        <div class="col-12 mb-2" *ngIf="isOrderTaker">
          <fp-border-container>
            <div class="p-5 d-flex align-items-center justify-content-between">
              <span>Buscar por geocordenadas</span>
              <fp-switch
                inputId="ctrl--search-coords"
                [checkedValue]="isCheckedCoords"
                (selectedItem)="clickSwitch()"
              >
              </fp-switch>
            </div>
          </fp-border-container>
        </div>
				<div class="col-12">
					<fp-input-search-for-ret
						[product]="product"
						(searchDone)="setModalStatus($event)"
						[isDisabledInputSearch]="isDisableChooseStore"
            [byCoords]="isCheckedCoords.isChecked"
					>
					</fp-input-search-for-ret>
				</div>
			</div>

			<div class="row" *ngIf="modalStatus === ALL_MODAL_STATUS.LOADING">
				<div class="col">
					<div class="skeleton">
						<fp-skeleton-for-pick-up-modal type="full"> </fp-skeleton-for-pick-up-modal>
					</div>
				</div>
			</div>

			<ng-container *ngIf="modalStatus !== ALL_MODAL_STATUS.LOADING">
				<!-- <div class="row" *ngIf="drugstores.length">
					<div class="col-12">
						<p class="position--title-botica">Boticas cercanas a la dirección ingresada</p>
					</div>
				</div> -->

				<div class="row drugstore-list-content" *ngIf="modalStatus !== ALL_MODAL_STATUS.EMPTY">
					<div class="col-12 drugstore-list-content">
						<fp-drugstore-list [customClass]="true" [status]="modalStatus" [isDisableChooseStore]="isDisableChooseStore">
						</fp-drugstore-list>
					</div>
					<!-- <div class="col-7">
            <div class="row">
              <div class="col-12">
                <fp-map-for-ret
                  [hideMapForRET]="
                    (
                      modalStatus == ALL_MODAL_STATUS.IS_PENDING ||
                      modalStatus == ALL_MODAL_STATUS.NO_ONE
                    )
                  "
                  [isDisabledMapForRET]="isDisableChooseStore"
                >
                </fp-map-for-ret>
              </div>
            </div>
          </div> -->
				</div>

				<div class="row" *ngIf="modalStatus === ALL_MODAL_STATUS.EMPTY">
					<div class="col mt-4">
						<div class="drugstores-list__empty">
							<img src="../../../../../../assets/images/icons/sad.png" alt="sad" />
							<p>
								Lo sentimos, no encontramos boticas cercanas {{ product && "que cuenten con ese producto" }}
								<span>Intenta cambiando la dirección </span>
							</p>
						</div>

						<div class="row justify-content-center mt-5">
							<div class="col-3">
								<fp-button-primary buttonDisplay="block" (click)="closeModal()"> Entendido </fp-button-primary>
							</div>
						</div>
					</div>
				</div>
				<div class="row w-100 disclaimer-container">
					<div class="col-12" *ngIf="modalStatus === 'ok'">
						<div
							class="container--info-product-selected"
							*ngIf="shoppingCartListLocal === 0 && productSeller.length === 0 && isDetail && !isDesktop"
						>
							<div class="disclaimer-container-nearby-modal">
								<fp-icon iconSize="16" [iconName]="getIconName" class="pst-icon"></fp-icon>
								<p>
									Te mostramos solo las boticas que cuentan con la disponibilidad completa de tu carrito y el producto
									seleccionado
								</p>
							</div>
							<!-- <p class="style-text">
								<fp-icon iconSize="16" [iconName]="getIconName" class="pst-icon"></fp-icon>
								Te mostramos solo las boticas a las que puedes acercarte para adquirir este producto.
							</p> -->
						</div>
						<div
							class="container--info-product-selected"
							*ngIf="shoppingCartListLocal === 1 && productSeller.length === 0 && !isDetail && !isDesktop"
						>
							<div class="disclaimer-container-nearby-modal">
								<fp-icon iconSize="16" [iconName]="getIconName" class="pst-icon"></fp-icon>
								<p>
									Te mostramos solo las boticas que cuentan con la disponibilidad completa de tu carrito y el producto
									seleccionado.
								</p>
							</div>
							<!-- <p class="style-text">
								<fp-icon iconSize="16" [iconName]="getIconName" class="pst-icon"></fp-icon>
								Te mostramos solo las boticas que cuentan con el producto que tienes en tu carrito.
							</p> -->
						</div>
						<div
							class="container--info-product-selected"
							*ngIf="shoppingCartListLocal === 1 && productSeller.length === 0 && !isInCart && isDetail && !isDesktop"
						>
							<div class="disclaimer-container-nearby-modal">
								<fp-icon iconSize="16" [iconName]="getIconName" class="pst-icon"></fp-icon>
								<p>
									Te mostramos solo las boticas que cuentan con la disponibilidad completa de tu carrito y el producto
									seleccionado.
								</p>
							</div>
							<!-- <p class="style-text">
								<fp-icon iconSize="16" [iconName]="getIconName" class="pst-icon"></fp-icon>
								Te mostramos solo las boticas a las que puedes acercarte para adquirir este y el producto que tienes en tu
								carrito.
							</p> -->
						</div>

						<div
							class="container--info-product-selected"
							*ngIf="shoppingCartListLocal > 1 && productSeller.length === 0 && isDetail && !isDesktop"
						>
							<div class="disclaimer-container-nearby-modal">
								<fp-icon iconSize="16" [iconName]="getIconName" class="pst-icon"></fp-icon>
								<p>
									Te mostramos solo las boticas que cuentan con la disponibilidad completa de tu carrito y el producto
									seleccionado.
								</p>
							</div>
							<!-- <p class="style-text">
								<fp-icon iconSize="16" [iconName]="getIconName" class="pst-icon"></fp-icon>
								Te mostramos solo las boticas a las que puedes acercarte para adquirir {{ isDesktop ? "" : "este y" }} los
								<b>{{ shoppingCartCount }} productos</b> de tu carrito.

							</p> -->
						</div>

						<div
							class="container--info-product-selected"
							*ngIf="shoppingCartListLocal > 1 && productSeller.length === 0 && !isDetail && !isDesktop"
						>
							<div class="disclaimer-container-nearby-modal">
								<fp-icon iconSize="16" [iconName]="getIconName" class="pst-icon"></fp-icon>
								<p>
									Te mostramos solo las boticas que cuentan con la disponibilidad completa de tu carrito y el producto
									seleccionado.
								</p>
							</div>
							<!-- <p class="style-text">
								<fp-icon iconSize="16" [iconName]="getIconName" class="pst-icon"></fp-icon>
								Te mostramos solo las boticas a las que puedes acercarte para adquirir los
								<b>{{ shoppingCartCount }} productos</b> de tu carrito.

							</p> -->
						</div>
						<div class="container--info-product-selected" *ngIf="shoppingCartListLocal > 1 && productSeller.length > 0">
							<div class="disclaimer-container-nearby-modal">
								<fp-icon iconSize="16" [iconName]="getIconName" class="pst-icon"></fp-icon>
								<p>
									Te mostramos solo las boticas que cuentan con la disponibilidad completa de tu carrito y el producto
									seleccionado.
								</p>
							</div>
							<!-- <p class="style-text">
								<fp-icon iconSize="16" [iconName]="getIconName" class="pst-icon"></fp-icon>
								Te mostramos solo las boticas a las que puedes acercarte para adquirir {{ isDetail ? "este y" : "" }} los
								<b
									>{{ productInka.length }} productos de tu carrito vendidos por
									{{ brand == "Inkafarma" ? "Inkafarma" : "Mifarma" }}.</b
								>

							</p> -->
						</div>
					</div>
				</div>
				<br />

				<ng-container
					*ngIf="modalStatus !== ALL_MODAL_STATUS.EMPTY && modalStatus !== ALL_MODAL_STATUS.NO_ONE && drugstores.length"
				>
					<div class="row dialog-actions justify-content-end">
						<!-- <div class="col-8"></div> -->
						<div class="" *ngIf="!needAddProduct">
							<fp-button-primary
								buttonDisplay="block"
								[buttonDisabled]="isLoadingModal && drugstores.length"
								(click)="selectDrugStore()"
							>
								Elegir botica
							</fp-button-primary>
						</div>

						<div class="" *ngIf="needAddProduct">
							<fp-button-primary
								buttonDisplay="block"
								*ngIf="!productInShoppingCart"
								[buttonDisabled]="isLoadingModal && drugstores.length"
								(click)="selectedAndAddProduct()"
							>
								Elegir botica
							</fp-button-primary>

							<fp-button-primary
								buttonDisplay="block"
								*ngIf="productInShoppingCart"
								[buttonDisabled]="isLoadingModal && drugstores.length"
								(click)="selectedAndAddProduct()"
							>
								Elegir botica
							</fp-button-primary>
						</div>
					</div>
				</ng-container>

				<ng-container *ngIf="!isGeocodeActive && modalStatus === ALL_MODAL_STATUS.NO_ONE">
					<div class="row dialog-actions">
						<div class="col-8"></div>
						<div class="col-4">
							<fp-button-primary buttonDisplay="block" (click)="closeModal()"> Entendido </fp-button-primary>
						</div>
					</div>
				</ng-container>
			</ng-container>
		</div>
	</fp-sub-section-container>
</div>
