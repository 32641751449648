import { AuthImplementService } from "./../../../../../shared/implements/auth/auth-implement.service";
import { Component, Inject, NgZone, OnDestroy, OnInit } from "@angular/core";
import { take, switchMap, delay, map, tap, finalize } from "rxjs/operators";
import { of, combineLatest, Subscription, EMPTY } from "rxjs";
import { UserGeolocationHelperService } from "../../pick-up-helpers/user-geolocation-helper.service";
import { AddressListStoreService } from "../../../address-manager/address-stores/address-list-store.service";
import { NearestDrugstoresImplementService } from "../../pick-up-implements/nearest-drugstores-implement.service";
import { MatDialogRef } from "@angular/material/dialog";
import { environment } from "@environments/environment";
import { EBrand, EBrandName } from "@parameters/access/brand.parameter";
import { AppStoreService } from "@stores/app/app-store.service";
import { OrderTakerValidator } from "@validators/order-taker.validator";
import { catchError} from "rxjs/operators";
import {
  INearestDrugstoresRequest,
  NearestDrugstore,
} from "src/app/shared/models/nearest-drugstores/nearest-drugstores.model";
import { ShoppingCartRequestService } from "src/app/shared/service/shopping-cart/shopping-cart-request.service";
import { AnalyticServiceService } from "src/app/shared/service/analitycService/analitycService.service";
import { ShoppingListStoreService } from "src/app/shared/stores/shopping-list/shopping-list-store.service";
import { AddressManagerPersistanceService } from "../../../address-manager/address-services/address-manager-persistance.service";
import { IDrugStoreRequest } from "../../../selected-drugstore-manager/models/drugstore.interface";
import { DRUG_STORE_CONFIG } from "../../../selected-drugstore-manager/parameters/parameters.const";
import { SelectedDrugstoreHttpService } from "../../../selected-drugstore-manager/services/selected-drugstore-http.service";
import { IModalStatus } from "../../enums/modal-status.enum";
import { ReferenceAddressFormService } from "../../pick-up-forms/reference-address-form.service";
import { PickUpConfigStoreService } from "../../pick-up-stores/pick-up-config-store.service";
import { StoresForPickUpStoreService } from "../../pick-up-stores/stores-for-pick-up-store.service";
import {
  IUserGeolocation,
  UserGeolocationStoreService,
} from "../../pick-up-stores/user-geolocation-store.service";
import {  MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ProductSalesforceService } from "../../../product-card/service/product-salesforce.service";
import { SnackbarAlertService } from "../../../snackbar/snackbar-alert.service";
import { ProductImplementService } from "../../../product-card/service/product-implement.service";
import { ProductShoppingCartRequest } from "@models/shopping-cart/product-shopping-cart-request.model";
import { RetValidateCartService } from "src/app/shared/service/cart/ret-validate-cart.service";
import { Router } from "@angular/router";
import { ShowModalBoticaService } from "src/app/shared/service/showModalServices/showModalService.service";
import { DeliveryInfoImplementService } from "src/app/business/checkout/checkout-services/delivery-info-implement.service";
import { Store } from "@models/store.model";
import { SelectDataStoreService } from "src/app/business/office/store/select-data-store.service";
import { TaggingService } from 'src/app/atm-services/tagging.service';
import { INearbyBoticaData } from "./interface/nearby-botica.interface";
import { LS_ADDRESS_SELECTED } from "src/app/shared/service/local-storage/constants/address.constant";
import { ISwitchState } from "src/app/business/checkout/checkout-components/generic-components/switch/switch.component";

@Component({
  selector: "fp-nearby-botica",
  templateUrl: "./nearby-botica-modal.component.html",
  styleUrls: ["./nearby-botica-modal.component.sass"],
})
export class NearbyBoticaModalComponent implements OnInit, OnDestroy {
  public brand = EBrandName[environment.brand];
  public isDisableChooseStore = false; // to enable or disable the complete modal
  public isLoadingModal = true;
  public isGeocodeActive = false;
  public isGeocodeLoaded = false;
  public ALL_MODAL_STATUS = IModalStatus;
  public modalStatus: IModalStatus = IModalStatus.DEFAULT;
  public drugstores: NearestDrugstore[] = [];
  public subscriptions: Subscription[] = [];
  public showButtonAdd: boolean;
  public shoppingCartListLocal: any;
  public productInka = [];
  public productSeller = [];
  private productInShoppingCartSubscription: Subscription;
  public productQuantity = 1;
  public productPresentationId: number;
  public productInShoppingCart: boolean;
  public selectedDrugStore: NearestDrugstore;
  public urlHome: boolean;
  public timeout: number;
  public isDetail = false;
  public isHome = false;
  public isDesktop = false;
  public isLoadPageAfterClose = true;
  public store: Store
  public isCheckedCoords:ISwitchState = {isChecked:false};
  public isOrderTaker = OrderTakerValidator.isOrderTakerEnv();
  product: any;

  get needAddProduct() {
    return this.retValidateCartService.addIntentIsFromProduct
  }

  get isInCart() {
    const shoppingCart = this.getCurrentCart();
    return shoppingCart.find((p: any) => p.productId === this.product?.id)
  }

  get shoppingCartCount() {
    return this.shoppingCartListLocal > 1 ? this.isInCart ? this.shoppingCartListLocal - 1 : this.shoppingCartListLocal : this.shoppingCartListLocal
  }

  constructor(
    // public typeOfDeliveryForm: CheckoutTypeOfDeliveryFormService,
    private userGeolocationHelper: UserGeolocationHelperService,
    private addressListStore: AddressListStoreService,
    private userGeolocationStore: UserGeolocationStoreService,
    private nearestDrugstoresImplement: NearestDrugstoresImplementService,
    private shoppingListRequest: ShoppingCartRequestService,
    private storesForPickUpStore: StoresForPickUpStoreService,
    private referenceAddressForm: ReferenceAddressFormService,
    public pickUpConfig: PickUpConfigStoreService,
    private ngZone: NgZone,
    private nearbyBoticaModalRef: MatDialogRef<NearbyBoticaModalComponent>,
    private _shoppingListStore: ShoppingListStoreService,
    private serviceAnalytic: AnalyticServiceService,
    private _productImplement: ProductImplementService,
    private snackbarAlert: SnackbarAlertService,
    private _productSalesforce: ProductSalesforceService,
    private _appStoreService: AppStoreService,
    private authImplementService: AuthImplementService,
    private retValidateCartService: RetValidateCartService,
    private appStore: AppStoreService,
    private selectedDrugstoreHttpService: SelectedDrugstoreHttpService,
    private _addressManager: AddressManagerPersistanceService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: INearbyBoticaData,
    public showModalBoticaService: ShowModalBoticaService,
    private deliveryInfoImplement: DeliveryInfoImplementService,
    public _selectDataStore: SelectDataStoreService
  ) {
    const drugstoresSub = this.storesForPickUpStore.drugstores$.subscribe((drugstores) => (this.drugstores = drugstores));
    this.subscriptions.push(drugstoresSub);
  }

  ngOnInit() {
    this.product = this.data.product;
    this.isLoadPageAfterClose = this.data.loadPageAfterClose;
    const shoppingCartListLocal = this._shoppingListStore.getLocalStorageShoppingCartList();
    this.shoppingCartListLocal = shoppingCartListLocal ? shoppingCartListLocal.length : 0;
    const onlyProductInka = this._shoppingListStore.getLocalStorageShoppingCartList() || [];
    onlyProductInka.filter((v) => v.sellerName === "" ? this.productInka.push(v) : []);
    onlyProductInka.filter((v) => v.sellerName !== "" ? this.productSeller.push(v) : []);
    const addresses = this.addressListStore.addressList;
    this.isDesktop = this.data.desktop;
    this.router.url.startsWith('/producto') ? this.isDetail = true : this.isDetail = false;
    this.router.url === '/' ? this.isHome = true : this.isHome = false;
    this.getProductShoppingCart();
    this.validateUrlHome();
    const latitude = this.data.currentDrugstore?.latitude
    const longitude = this.data.currentDrugstore?.longitude
    if (this.data.loadDrugstores && latitude && longitude) {
      this.loadDrugstores({ longitude, latitude }).subscribe((response: any) => {
        if (!response.length) {
          this.modalStatus = IModalStatus.EMPTY;
        } else this.modalStatus = IModalStatus.OK;
        this.isLoadingModal = false;
      });
    } else {
      if (addresses.length) {
        const selectedAddress = addresses.find((address) => address.favorite);
        const paramsForAddress = this.getParamsForAddress(selectedAddress, this.data.prd);
        this.onNearestDrugstores(paramsForAddress);
        this.userGeolocationHelper
          .getGeolocationPermissions$()
          .pipe(
            switchMap((permission) => {
              return permission.status === "ok"
                ? this.userGeolocationHelper.loadGeolocationAddress$(permission)
                : of(false);
            }), finalize(() => this.isLoadingModal = false)
          )
          .subscribe(() => {});
      } else {
        this.userGeolocationHelper
          .isPendingToAskGeolocation$()
          .pipe(delay(200))
          .pipe(take(1))
          .subscribe((isPending) => {
            if (isPending) {
              this.modalStatus = IModalStatus.IS_PENDING;
              this.isLoadingModal = false;
            } else {
              this.getDrugstoresOfGeolocationFlow$(this.data?.prd)
              .pipe(take(1))
              .subscribe((response) => {
                this.ngZone.run(() => {
                  this.storesForPickUpStore.setDrugstores(response.drugstores);
                  if (response.permission.status === "ok") {
                    if (response.drugstores.length) {
                      this.setSelectedTemporalDrugstore(response.drugstores);
                      this.modalStatus = IModalStatus.OK;
                    } else {
                      this.modalStatus = IModalStatus.EMPTY;
                    }
                  } else {
                    const referenceAddress =
                      this.referenceAddressForm.referenceAddressControl.value;
                    const MIN_CHARACTERS =
                      this.pickUpConfig.config
                        .minimunCharactersForPredictiveAddresses;
                    if (
                      typeof referenceAddress === "string" &&
                      referenceAddress.length >= MIN_CHARACTERS
                    ) {
                      this.modalStatus = IModalStatus.NO_ONE;
                    } else {
                      this.modalStatus = IModalStatus.NO_ONE;
                    }
                  }
                  this.isLoadingModal = false;
                });
              });
            }
          });
      }
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  onDrugStore(paramsForAddress) {
    this.selectedDrugstoreHttpService
      .getDrugStoreList$({
        position: {
          latitude: paramsForAddress.latitude,
          longitude: paramsForAddress.longitude,
        },
        proximity: DRUG_STORE_CONFIG.PROXIMITY,
        companyCode:
          this.appStore.brandAtention === EBrand.inkafarma
            ? EBrand.inkafarma
            : EBrand.mifarma,
        page: DRUG_STORE_CONFIG.PAGE,
        maxSixeResult: DRUG_STORE_CONFIG.MAX_SIZE_RESULT,
      } as IDrugStoreRequest)
      .pipe(delay(100))
      .pipe(take(1))
      .subscribe((drugstores) => {
        const listDrugstore: NearestDrugstore[] = drugstores.map(
          (drugstore) => {
            const current = new NearestDrugstore(null);
            current.address = drugstore.address || "";
            current.distance = drugstore.distance || 0;
            current.id = drugstore.legacyId || 0;
            current.latitude = drugstore.latitude || 0;
            current.longitude = drugstore.longitude || 0;
            current.localCode = drugstore.localCode || "";
            current.name = drugstore.name || "";
            current.defaultDrugstore = drugstore.defaultDrugstore || false;
            current.localOpeningHours =
              drugstore.startHour + " - " + drugstore.endHour || "";

            return current;
          }
        );

        this.ngZone.run(() => {
          this.storesForPickUpStore.setDrugstores(listDrugstore);
          if (listDrugstore.length) {
            this.setSelectedTemporalDrugstore(listDrugstore);
            this.modalStatus = IModalStatus.OK;
          } else {
            this.modalStatus = IModalStatus.EMPTY;
          }
          this.isLoadingModal = false;
        });
      });
  }

  onNearestDrugstores(paramsForAddress) {
    this.nearestDrugstoresImplement
      .getDrugstores$(paramsForAddress)
      .pipe(delay(200))
      .pipe(take(1))
      .pipe(
        catchError((err) => {
          this.modalStatus = IModalStatus.EMPTY;
          this.isLoadingModal = false;

          throw err;
        })
      )
      .subscribe((drugstores) => {
        this.ngZone.run(() => {
          this.storesForPickUpStore.setDrugstores(drugstores);
          if (drugstores.length) {
            this.setSelectedTemporalDrugstore(drugstores);
            this.modalStatus = IModalStatus.OK;
          } else {
            this.modalStatus = IModalStatus.EMPTY;
          }
          this.isLoadingModal = false;
        });
      });
  }

  private setSelectedTemporalDrugstore(drugstores: NearestDrugstore[]) {
    const foundDefaultDrugstore = drugstores.find((d) => d.defaultDrugstore);
    const defaultDrugstore = foundDefaultDrugstore
      ? foundDefaultDrugstore
      : drugstores[0];
    this.storesForPickUpStore.setSelectedTemporalDrugstore(defaultDrugstore);
  }

  private getDrugstoresOfGeolocationFlow$(prd) {
    return this.userGeolocationHelper.getGeolocationPermissions$().pipe(
      switchMap((permission) => {
        if (OrderTakerValidator.isOrderTakerEnv()) {
          if (this._addressManager.addressToUpdate) {
            const lat = this._addressManager.addressToUpdate.latitude;
            const lng = this._addressManager.addressToUpdate.longitude;
            const loadMap$ = this.userGeolocationHelper.loadGeolocationAddress$(permission);
            const paramsForGeo = this.getParamsForAddress(
              this._addressManager.addressToUpdate, prd
            );
            const drugstores$ = this.nearestDrugstoresImplement.getDrugstores$(paramsForGeo);
            return combineLatest([drugstores$, loadMap$]).pipe(
              map(([drugstores, loadMap]) => {
                return { drugstores, permission };
              })
            );
          } else {
            if (permission.status === "ok") {
              const paramsForGeo = this.getParamsForGeo(permission.coords);
              const drugstores$ = this.nearestDrugstoresImplement.getDrugstores$(paramsForGeo);
              return combineLatest([drugstores$]).pipe(
                map(([drugstores]) => ({ drugstores, permission }))
              );
            } else {
              return of({
                drugstores: [] as NearestDrugstore[],
                permission,
              });
            }
          }
        } else {
          if (permission.status === "ok") {
            const paramsForGeo = this.getParamsForGeo(permission.coords, prd);
            const drugstores$ = this.nearestDrugstoresImplement.getDrugstores$(paramsForGeo);
            return combineLatest([drugstores$]).pipe(
              map(([drugstores]) => ({ drugstores, permission }))
            );
          } else {
            const userGeolocation = {
              permissions: permission,
            } as IUserGeolocation;
            this.userGeolocationStore.setUserGeolocation(userGeolocation);
            return of({
              drugstores: [] as NearestDrugstore[],
              permission,
            });
          }
        }
      })
    );
  }

  private getVerifiedSelectedDrugstore(drugstores: NearestDrugstore[]) {
    const { selectedDrugstore } = this.storesForPickUpStore;
    if (selectedDrugstore.id) {
      const lastSelectedDrugstoreId = selectedDrugstore.id;
      const temporalSelectedDrugstore = drugstores.find(
        (d) => d.id === lastSelectedDrugstoreId
      );
      return temporalSelectedDrugstore
        ? temporalSelectedDrugstore
        : drugstores[0];
    } else {
      return drugstores[0];
    }
  }

  private loadDrugstores(location) {
    this.storesForPickUpStore.setDrugstores([]);
    return this.nearestDrugstoresImplement
      .getDrugstores$(this.getParamsForAddress(location, this.data?.prd))
      .pipe(
        take(1),
        catchError(() => {
          this.storesForPickUpStore.setDrugstores([]);
          this.modalStatus = IModalStatus.EMPTY;
          return EMPTY;
        }),
        tap((drugstores) => {
          this.storesForPickUpStore.setDrugstores(drugstores);
          const temporalSelectedDrugstore = this.getVerifiedSelectedDrugstore(drugstores);
          this.storesForPickUpStore.setSelectedTemporalDrugstore(
            temporalSelectedDrugstore
          );
        }),
      )
  }

  getCurrentCart(prd?) {
    const intencionProducts = [{"productId": prd,"presentationIdSelected": 1,"quantity": 1,"sellerId": "","sellerName": ""}];
    const shoppingCartListLocal = this._shoppingListStore.getLocalStorageShoppingCartList() || [];
    const shoppingCartList = prd ? shoppingCartListLocal.concat(intencionProducts) : shoppingCartListLocal;
    return shoppingCartList;
  }

  private getParamsForAddress(coords: any, prd?) {
    const shoppingCart = this.getCurrentCart(prd);
    let coordTomador = JSON.parse(this.appStore.getStorageItem('coordTomador'));

    return {
      latitude: OrderTakerValidator.isOrderTakerEnv() && coordTomador ? coordTomador?.latitude : coords.latitude,
      longitude: OrderTakerValidator.isOrderTakerEnv() && coordTomador ? coordTomador?.longitude : coords.longitude,
      shoppingCart: this.product?.id ? this.productIsInShopping(shoppingCart) : shoppingCart,
      companyCode: this.appStore.companyCode,
      userId: this.authImplementService.currentUID
    } as INearestDrugstoresRequest;
  }

  private getParamsForGeo(coords: GeolocationCoordinates, prd?) {
    const shoppingCart = this.getCurrentCart(prd);

    return {
      companyCode: this.appStore.companyCode,
      latitude: coords.latitude,
      longitude: coords.longitude,
      shoppingCart: this.product?.id ? this.productIsInShopping(shoppingCart) : shoppingCart,
      userId: this.authImplementService.currentUID
    } as INearestDrugstoresRequest;
  }

  productIsInShopping(shoppingCart) {
    const {
      productId
    } = this.product4Shopping;

    return !shoppingCart.find((p: any) => p.productId === productId) ? [...shoppingCart, this.product4Shopping] : shoppingCart
  }

  get product4Shopping() {
    const {
      id: productId,
      defaultPresentation
    } = this.product

    return {
      productId,
      presentationIdSelected:defaultPresentation,
      quantity: 1
    }
  }

  public closeModal() {
    this.nearbyBoticaModalRef.close();
    this.showModalBoticaService.fromProduct.next(false);
    this.serviceAnalytic.registerEventAnalytics(
      this.serviceAnalytic.detectModuleTag("boton_entendio"),
      "Boton :: entendido",
      ""
    );
  }

  closeXEvent() {
    this.nearbyBoticaModalRef.close();
    this.showModalBoticaService.fromProduct.next(false);
  }

  public setModalStatus(state: IModalStatus) {
    this.modalStatus = state;
  }

  getProductShoppingCart() {
    const shoppingCartListLocal = JSON.parse(
      this._appStoreService.getStorageItem("shoppingCartList")
    );
    this._productImplement
      .getProductShoppingCart$(
        this.product?.id || shoppingCartListLocal[0]?.productId
      )
      .subscribe((value: ProductShoppingCartRequest) => {
        this.productInShoppingCart = !!value;
        if (this.productInShoppingCart) {
          this.productPresentationId = this.productInShoppingCart
            ? value.presentationIdSelected
            : this.product.defaultPresentation;
          this.productQuantity = this.productInShoppingCart
            ? value.quantity
            : 1;
        }
      });
  }

  public saveChanges() {
    if(OrderTakerValidator.isOrderTakerEnv()){
      this.setNearByStoreTomado();
    }else{
      this._appStoreService.setStorageItem(
        "drugstoreSelected",
        JSON.stringify(this.storesForPickUpStore.selectedTemporalDrugstore)
      );
    }
    this.storesForPickUpStore.setSelectedDrugstore(
      this.storesForPickUpStore.selectedTemporalDrugstore
    );
    this._appStoreService.setStorageItem(LS_ADDRESS_SELECTED, "false");
    this.storesForPickUpStore.setDrugstore.emit(
      this.storesForPickUpStore.selectedTemporalDrugstore
    );
  }

  selectDrugStore() {
    TaggingService.RetSaveDeliveryTypeDialog(false);
    this.isLoadingModal = true;
    clearTimeout(this.timeout);
    this.retValidateCartService.storeProcessType('RET');
    this.saveChanges();
    this.getDeliveryInfo().subscribe(resp => {
      if(this.isLoadPageAfterClose) this.reloadPage();
    });
    this.tagDrugstoreSelectedBotica();
    this.nearbyBoticaModalRef.close();
    this.snackbarAlert.snackbarAlertSuccess(
      "Método de entrega actualizado a <b>Retiro en tienda<b>"
    );

    if(this.isLoadPageAfterClose) this.reloadPage();
  }

  selectedAndAddProduct() {
    TaggingService.RetSaveDeliveryTypeDialog(false);
    this.isLoadingModal = true;
    clearTimeout(this.timeout);
    this.retValidateCartService.storeProcessType('RET');
    this.saveChanges();
    this.addProductToShoppingCart();
    this.tagDrugstoreSelectedAndAddBotica();
    this.getDeliveryInfo().subscribe(resp => {
      this.nearbyBoticaModalRef.close();
      this.reloadPage();
    });
    this.snackbarAlert.snackbarAlertSuccess(
      "Producto agregado y método de entrega actualizado a <b>Retiro en tienda<b>");
    this.reloadPage();
  }

  addProductToShoppingCart() {
    const shoppingCartListLocal = JSON.parse(
      this._appStoreService.getStorageItem("shoppingCartList")
    );

      this._productImplement.addProduct(
        this.product?.id || shoppingCartListLocal[0].productId,
        this.productQuantity || shoppingCartListLocal[0].quantity,
        this.product?.presentationIdSelected || shoppingCartListLocal[0].presentationIdSelected,
        this.product?.sellerId || "",
        this.product?.sellerName || ""
      );
      this._productSalesforce.addProduct(
        this.product?.id || shoppingCartListLocal[0].productId,
        this.product?.id || shoppingCartListLocal[0].productId,
        this.productQuantity || 0,
        this.product?.price * this.productQuantity || 0
      );
  }

  validateUrlHome(){
    this.router.url === '/' ? this.urlHome = true : this.urlHome = false;
  }

  reloadPage(){
    this.timeout = setTimeout(()=>{
      window.location.reload();
    },2000);
  }

  getDeliveryInfo() {
    return this.deliveryInfoImplement
    .getDeliveryAddressWithoutParams$()
    .pipe(take(1), tap(((deliveryInfo) => {
    })))
  }

  public setNearByStoreTomado(){
    const drugstoreSelected = this.storesForPickUpStore.selectedTemporalDrugstore;
      if(drugstoreSelected){
        this.store = {
          address: drugstoreSelected.address,
          distance: drugstoreSelected.distance,
          drugstoreWareHouseId: drugstoreSelected.drugstoreWareHouseId,
          id: drugstoreSelected.id,
          latitude: drugstoreSelected.latitude,
          longitude: drugstoreSelected.longitude,
          localCode: drugstoreSelected.localCode,
          localOpeningHours: drugstoreSelected.localOpeningHours,
          name: drugstoreSelected.name
        };
        this._selectDataStore.setNearbyStore(this.store as Store);
        this.appStore.setStorageItem('drugstoreSelected', JSON.stringify(this.store));
      }
  }

  tagDrugstoreSelectedBotica(){
    TaggingService.tagEventAllRet("ui_drugstore_click_select", "UI :: Drugstore", "Click", this.retValidateCartService.isRADProcess ? "desde_rad" : "desde_ret");
  }

  tagDrugstoreSelectedAndAddBotica(){
    TaggingService.tagEventAllRet("ui_drugstore_click_selectadd", "UI :: Drugstore", "Click", this.retValidateCartService.isRADProcess ? "desde_rad" : "desde_ret");
  }

  get getIconName(){
    return environment.brand === EBrand.mifarma ? 'info-purple': 'icon-info'
  }

  get getStoreIconName(){
    return environment.brand === EBrand.mifarma ? 'mf-store': 'store-green'
  }
  clickSwitch(){
    this.isCheckedCoords.isChecked = !this.isCheckedCoords.isChecked;
  }
}
