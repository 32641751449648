<ng-container *ngIf="isOrderTaker; else notOrderTaker">
<div>
  <div class="row">
    <div class="col-4 col-sm-6 col-md-4">
      <div class="content-section-delivery">
        <div class="heading d-flex align-items-end">
          <fp-icon  iconSize="24" [iconName]="isRAD ? (availableRad ? 'icon_rad_on' : 'icon_rad_off') : 'icon_rad_on'"></fp-icon>
          <span class="ml-2 text--4"> Despacho a domicilio</span>
        </div>
        <div class="text-despacho">
          <span class="pl-1 text--4" [ngClass]="isRAD ? (availableRad ? '' : 'text-error') : ''">
            {{isRAD ? (availableRad ? 'Disponible' : 'No disponible') : 'Disponible'}}
          </span>
        </div>
      </div>
    </div>
    <div class="col-4 col-sm-6">
      <div class="content-section-delivery-ver-botica" *fpServiceType [ngStyle]="{'z-index': !checkMarkActive && '1'}">
        <div class="heading d-flex align-items-end">
          <fp-icon  iconSize="24" [iconName]="isRAD ? 'icon_ret_on' : (availableRet ? 'icon_ret_on' : 'icon_ret_off')"></fp-icon>
          <span class="ml-2 text--4"> Retiro en tienda (Gratis)</span>
        </div>
        <div class="text-despacho">
          <span class="pl-1 text--4" [ngClass]="isRAD ? '' : (availableRet ? '' : 'text-error')">
            {{isRAD ? 'Disponible' : (availableRet ? 'Disponible' : 'No disponible')}}
          </span>
          <fp-link-buttom *ngIf="!isSeller"  [buttonText]="'Consultar y elegir botica'" (click)="showModalBotica(product)">
          </fp-link-buttom>
        </div>
      </div>
    </div>
  </div>
</div>
</ng-container>
<ng-template #notOrderTaker>
  <div>
    <div class="row">
      <div class="col-4 col-sm-6 col-md-4">
        <div class="content-section-delivery">
          <div class="heading d-flex align-items-end">
            <fp-icon *ngIf="availableRad" iconSize="24" iconName="icon_rad_on"></fp-icon>
            <fp-icon *ngIf="!availableRad" iconSize="24" iconName="icon_rad_off"></fp-icon>
            <span class="ml-2 text--4"> Despacho a domicilio</span>
          </div>
          <div class="text-despacho">
            <span class="pl-1 text--4" [ngClass]="{ 'text-error': !availableRad}">
              {{availableRad ? 'Disponible' : 'No disponible'}}
            </span>
          </div>
        </div>
      </div>
      <div class="col-4 col-sm-6">
        <div class="content-section-delivery-ver-botica" [ngStyle]="{'z-index': !checkMarkActive && '1'}">
          <div class="heading d-flex align-items-end">
            <fp-icon *ngIf="availableRet" iconSize="24" iconName="icon-ret"></fp-icon>
            <fp-icon *ngIf="!availableRet" iconSize="24" iconName="icon_ret_off"></fp-icon>
            <span class="ml-2 text--4"> Retiro en tienda (Gratis)</span>
          </div>
          <div class="text-despacho">
            <span class="pl-1 text--4" [ngClass]="{'text-error': !availableRet}">
              {{availableRet ? 'Disponible' : 'No disponible'}}
            </span>
            <fp-link-buttom *ngIf="!isSeller" [buttonText]="'Consultar y elegir botica'" (click)="showModalBotica(product)">
            </fp-link-buttom>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
