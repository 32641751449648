import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'maskSensitiveData'
})
export class MaskSensitiveDataPipe implements PipeTransform {

  transform(value: any, type: string, isMaskEnabled: boolean): string {
		if (!isMaskEnabled) return value;
		if (!value) return value;

		switch (type) {
			case "document":
				return this.maskDocument(value);
			case "text":
				return this.maskText(value);
			case "email":
				return this.maskEmail(value);
			case "phone":
				return this.maskPhone(value);
			case "birthday":
				return this.maskBirthday(value);
			default:
				return value;
		}
	}

	private maskDocument(document: string): string {
		return document.replace(/.(?=.{3})/g, "*");
	}

	private maskText(value: string): string {
		return value
			.split(" ")
			.filter(Boolean) // Filtra cualquier cadena vacía
			.map((part) => {
				return part.length > 1 ? part[0] + "*".repeat(part.length - 1) : part;
			})
			.join(" ");
	}

	private maskEmail(email: string): string {
		const [username, domain] = email.split("@");

		if (username && domain) {
			// Solo se agrega asteriscos si hay más de 3 caracteres
			const numAsterisks = Math.max(0, username.length - 3);
			const maskedUsername = `${username.slice(0, 3)}${"*".repeat(numAsterisks)}`;
			return `${maskedUsername}@${domain}`;
		}

		return email;
	}

	private maskPhone(phone: string): string {
		const totalLength = phone.length;
		const visibleStart = 1;
		const visibleEnd = 2;

		// Si el número de teléfono es muy corto, no se realiza enmascaramiento
		if (totalLength < visibleStart + visibleEnd) return phone;

		const numAsterisks = totalLength - visibleStart - visibleEnd;
		return `${phone.slice(0, visibleStart)}${"*".repeat(numAsterisks)}${phone.slice(-visibleEnd)}`;
	}

	private maskBirthday(birthday: any) {
		if (!birthday) return birthday;

		const parts = birthday.split("/");
		if (parts.length === 3) {
			// Enmascarar día y mes, pero mostrar los dos últimos dígitos del año
			const day = parts[0].replace(/\d/g, "*");
			const month = parts[1].replace(/\d/g, "*");
			const year = parts[2].slice(0, 2).replace(/\d/g, "*") + parts[2].slice(2);

			return `${day}/${month}/${year}`;
		}

		return birthday;
	}

}
