import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { StaticLandingClientService } from "../../../shared/service/static-landing/static-landing-client.service";
import {
  ContentfulCyberWOWDashboard,
  ContentfulDashboard,
  Shortcut,
  StaticLanding,
  StaticLandingCatalogue,
  StaticLandingPage,
} from "../models/static-landing.model";
import { STATIC_LANDING_SLUG } from "../parameters/static-landing";
import { EmbedCatalog } from "../views/embed-catalogs/models/embed-catalogs.model";

@Injectable()
export class StaticLandingImplementService {
	private slug = STATIC_LANDING_SLUG;

	constructor(private _staticLandingClient: StaticLandingClientService) {}

	public getLegalsLanding() {
		return this._staticLandingClient.getStaticLanding(this.slug.legals).pipe(map((item) => new StaticLanding(item)));
	}

	public getCookiesPolicy() {
		return this._staticLandingClient.getStaticLanding(this.slug.cookiesPolicy).pipe(map((item) => new StaticLanding(item)));
	}

	public getTermsOfService() {
		return this._staticLandingClient.getStaticLanding(this.slug.termsOfService).pipe(map((item) => new StaticLanding(item)));
	}

	public getTermsOfServiceSeller() {
		return this._staticLandingClient.getStaticLanding(this.slug.termsOfServiceSeller).pipe(map((item) => new StaticLanding(item)));
	}

	public getPersonalDataProcesing() {
		return this._staticLandingClient.getStaticLanding(this.slug.personalDataProcesing).pipe(map((item) => new StaticLanding(item)));
	}

	public getCallCenter() {
		return this._staticLandingClient.getStaticLanding(this.slug.callCenter).pipe(map((item) => new StaticLanding(item)));
	}

	public getFrequentlyAskedQuestions() {
		return this._staticLandingClient.getStaticLanding(this.slug.frequentlyAskedQuestions).pipe(map((item) => new StaticLanding(item)));
	}

	public getInkaClub() {
		return this._staticLandingClient.getStaticLanding(this.slug.inkaclub).pipe(map((item) => new StaticLanding(item)));
	}

	public getTermsAndConditionsOfPayment() {
		return this._staticLandingClient
			.getStaticLanding(this.slug.termsAndConditionsOfPayment)
			.pipe(map((item) => new StaticLanding(item)));
	}

	public getTermsAndConditionsSpecial() {
		return this._staticLandingClient
			.getStaticLanding(this.slug.termsAndConditionsSpecial)
			.pipe(map((item) => new StaticLanding(item)));
	}

	public getTreatmentForAdditionalPurposes() {
		return this._staticLandingClient
			.getStaticLanding(this.slug.treatmentForAdditionalPurposes)
			.pipe(map((item) => new StaticLanding(item)));
	}

	public getPrivacyPolicy() {
		return this._staticLandingClient.getStaticLanding(this.slug.privacyPolicy).pipe(map((item) => new StaticLanding(item)));
	}

	public getElectronicVoucher() {
		return this._staticLandingClient.getStaticLanding(this.slug.electronicVoucher).pipe(map((item) => new StaticLanding(item)));
	}

	public getBlog() {
		return this._staticLandingClient.getStaticLanding(this.slug.blog).pipe(map((item) => new StaticLanding(item)));
	}

	public getCatalogue() {
		return this._staticLandingClient.getStaticLanding(this.slug.catalogue).pipe(map((item) => new StaticLandingCatalogue(item)));
	}

	public getStrategicPartners() {
		return this._staticLandingClient.getStaticLanding(this.slug.strategicPartners).pipe(map((item) => new StaticLanding(item)));
	}

	public getTreatmentManagers() {
		return this._staticLandingClient.getStaticLanding(this.slug.treatmentManagers).pipe(map((item) => new StaticLanding(item)));
	}

	public getUs() {
		return this._staticLandingClient.getStaticLandingPage(this.slug.usLanding).pipe(map((item) => new StaticLandingPage(item)));
	}

	public getBlogByIds(ids: Array<string>) {
		return this._staticLandingClient.getBlog(ids).pipe(
			map((item) => {
				return item.map((shortcut) => new Shortcut(shortcut.fields));
			})
		);
	}

	public getLandingIframe(slug: string) {
		return this._staticLandingClient.getStaticLanding(slug).pipe(map((item) => new StaticLanding(item)));
	}

	public getTermsOfOneDocThree() {
		return this._staticLandingClient.getStaticLanding(this.slug.termsOfOneDocThree).pipe(map((item) => new StaticLanding(item)));
	}

	public getDataUsageDocThree() {
		return this._staticLandingClient.getStaticLanding(this.slug.dataUsageOfOneDocThree).pipe(map((item) => new StaticLanding(item)));
	}

	public getPrivacyPolicyDocThree() {
		return this._staticLandingClient
			.getStaticLanding(this.slug.privacyPoliciesOfOneDocThree)
			.pipe(map((item) => new StaticLanding(item)));
	}

	public getEmbedCatalogs() {
		return this._staticLandingClient.getBannerListLanding(this.slug.landingEmbedCatalogs).pipe(map((item) => new EmbedCatalog(item)));
	}

	public getCyberWow() {
		return this._staticLandingClient.getDashBoardLanding(this.slug.cyberWow).pipe(map((item) => new ContentfulDashboard(item)));
	}

	// public getCyberWow() {
	//   return this._staticLandingClient
	//     .getDashBoardLanding(this.slug.cyberWow)
	//     .pipe(map((item: any) => new ContentfulDashboard(item[0].fields)));
	// }

	public getNeighborPharmacy() {
		return this._staticLandingClient
			.getDashBoardLanding(this.slug.neighborPharmacy)
			.pipe(map((item) => new ContentfulDashboard(item)));
	}

	public getCyberWowRegister() {
		return this._staticLandingClient
			.getDashBoardLanding(this.slug.cyberWOWRegister)
			.pipe(map((item) => new ContentfulCyberWOWDashboard(item)));
	}

	public getEventosInkafarma() {
		return this._staticLandingClient
			.getDashBoardLanding(this.slug.formularioEventosMifarma)
			.pipe(map((item) => new ContentfulCyberWOWDashboard(item)));
	}

	public getCategoryLanding(keyword: string) {
		return this._staticLandingClient.getCategoryLanding(keyword).pipe(map((item) => new StaticLanding(item)));
	}

	public getTarjetaOh() {
		return this._staticLandingClient.getStaticLanding(this.slug.tarjetaOh).pipe(map((item) => new StaticLanding(item)));
	}

	public getAgora() {
		return this._staticLandingClient.getStaticLanding(this.slug.agora).pipe(map((item) => new StaticLanding(item)));
	}
}
