<div class="search-autocomplete-item" (click)="selectAutocompleteItem()">
	<div class="search-address-icon" [ngClass]="{ 'search-address-icon--label': isJustLabel }">
		<div class="icon icon-16-{{ icon }} icono" [ngClass]="{ 'label-icono': isJustLabel }"></div>
	</div>
	<div class="search-address-detail" [ngClass]="{ 'search-address-detail--label': isJustLabel }">
		<div class="search-address-title label-black--2 label-black-lg" *ngIf="title">
			{{ title && subTitleRed ? title : subTitle }}
		</div>
		<div
			class="search-address-sub-title label--2 label-lg--1"
			[ngClass]="{ 'search-address-sub-title--apply-dots': applyDots }"
			*ngIf="!subTitleRed && !isJustLabel"
		>
			{{ subTitle }}
		</div>
		<div
			class="search-address-sub-title search-address-sub-title--red label--2 label-lg--1"
			[ngClass]="{ 'search-address-sub-title--apply-dots': applyDots }"
			(click)="subTittleRedEvent()"
			*ngIf="subTitleRed && !isJustLabel"
		>
			{{ subTitle }}
		</div>
		<div
			class="search-address-sub-title label--3 label-lg--2"
			[ngClass]="{ 'search-address-sub-title--apply-dots': applyDots }"
			*ngIf="isJustLabel"
		>
			{{ subTitle }}
		</div>
	</div>
</div>
