import { HttpHeaders } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { environment } from "@environments/environment";
import { HandleSelectedAlgoliaResponse } from '@models/search-landing/handle-filter-selected-response.model';
import { HandleFilterAlgolioRequest, SearchLandingAlgoliaRequest, SearchLandingMultiIndexRequest } from '@models/search-landing/search-landing-algolia-request.model';
import { EBrand, EBrandAsset } from "@parameters/access/brand.parameter";
import { AppStoreService } from "@stores/app/app-store.service";
import { OrderTakerValidator } from "@validators/order-taker.validator";
import { BehaviorSubject, Observable } from "rxjs";
import { filter, map } from "rxjs/operators";
import { SearchLandingRequest } from "../../models/search-landing/search-landing-request.model";
import { ISearchLandingResponse } from "../../models/search-landing/search-landing-response.interface";
import { SearchLandingAlgoliaResponse, SearchLandingResponse } from '../../models/search-landing/search-landing-response.model';
import { ENDPOINTS } from "../../parameters/endpoints";
import { GenericService } from "../generic";


@Injectable({
  providedIn: "root",
})

export class SearchLandingClientService {

  public endPointAlgolia = ENDPOINTS.GET_LIST_FILTER_ALGOLIA;
  private searchResults$ = new BehaviorSubject<any>(null);

  public headers = new HttpHeaders();
  public setHeaders = this.headers
      .set('X-Algolia-API-Key', environment.algolia_filter_categories.X_Algolia_API_Key)
      .set('X-Algolia-Application-Id', environment.algolia_filter_categories.X_Algolia_Application_Id)
      .set('content-type', 'application/json');
  constructor(
    private _genericService: GenericService,
    private _appStoreService: AppStoreService
  ) {}

  getUrlLandingFilters(): string {
    let isOrderTaker = OrderTakerValidator.isOrderTakerEnv();
    if (isOrderTaker) {
      const isMifaBrand =
        EBrand[EBrandAsset[environment.brand]] == EBrand.mifarma;
      return isMifaBrand
        ? ENDPOINTS.GET_SEARCH_LANDING_FILTERS_MF
        : ENDPOINTS.GET_SEARCH_LANDING_FILTERS;
    }
    return ENDPOINTS.GET_SEARCH_LANDING_FILTERS;
  }

  getSearchLandingFilters(
    searchLandingRequest: SearchLandingRequest
  ): Observable<SearchLandingResponse> {
    return this._genericService
      .genericPost(this.getUrlLandingFilters(), searchLandingRequest)
      .pipe(
        map(
          (iSearchLandingResponse: ISearchLandingResponse) =>
            new SearchLandingResponse(iSearchLandingResponse)
        )
      );
  }

  private createHeaders(api_algolia: string, api_key: string): HttpHeaders {
    return this.headers
      .set('X-Algolia-API-Key', api_key)
      .set('X-Algolia-Application-Id', api_algolia)
      .set('content-type', 'application/json');
  }
// Método que maneja la búsqueda en un solo índice (sin cambios significativos)
getSearchLandingAlgoliaFilter(
  searchLandingRequest: SearchLandingAlgoliaRequest,
  indexName: string,
  api_algolia?: string,
  api_key?: string
): Observable<SearchLandingAlgoliaResponse> {
  const setHeaders = this.createHeaders(api_algolia, api_key);
  return this._genericService.genericPostWithoutSecurityHeaders(
    `https://${api_algolia}-dsn.algolia.net/1/indexes/${indexName}/query`,
    searchLandingRequest,
    null,
    setHeaders
  );
}

// Método que maneja la búsqueda en múltiples índices
getSearchLandingAlgoliaMultiIndex(
  searchRequests: SearchLandingMultiIndexRequest[],
  api_algolia?: string,
  api_key?: string
): Observable<any> {
  const setHeaders = this.createHeaders(api_algolia, api_key);

  // Definir el índice que queremos manejar de forma especial (por texto)
  const indexAlgoliaBusquedaPositiva = (environment.brand === EBrand.inkafarma) 
    ? "busqueda_positiva_inkafarma" 
    : "busqueda_positiva_mifarma";

  // Construimos las solicitudes para múltiples índices
  const requests = searchRequests.map((request) => {
    let params = `${request.params}`; // Siempre incluimos los params (el query)

    // Si el nombre del índice es "busqueda_positiva_inkafarma" o "busqueda_positiva_mifarma", solo enviamos el query
    if (request.indexName === indexAlgoliaBusquedaPositiva) {
      // Solo enviar el query sin otros parámetros
      return {
        indexName: request.indexName,
        params: params  // Solo el query (ej. "query=panales")
      };
    } else {
      // Para los otros índices, enviar los parámetros opcionales si están presentes
      const optionalParams = [
        request.facets?.length ? `&facets=${JSON.stringify(request.facets)}` : '',
        request.facetFilters?.length ? `&facetFilters=${JSON.stringify(request.facetFilters)}` : '',
        request.length !== undefined ? `&length=${request.length}` : '',
        request.offset !== undefined ? `&offset=${request.offset}` : '',
        request.maxValuesPerFacet !== undefined ? `&maxValuesPerFacet=${request.maxValuesPerFacet}` : ''
      ].filter(Boolean).join('');

      return {
        indexName: request.indexName,
        params: params + optionalParams
      };
    }
  });

  const body = { requests };

  return this._genericService.genericPostWithoutSecurityHeaders(
    `https://${api_algolia}-dsn.algolia.net/1/indexes/*/queries`, 
    body, 
    null, 
    setHeaders
  ).pipe(
    filter(response => !!response),
    map((response: any) => {
      // Guardamos los resultados en BehaviorSubject
      this.searchResults$.next(response.results);
      return Object.assign({}, response.results[0]);  // Ajusta según necesites
    })
  );
}


  // Método para obtener la respuesta según la posición del índice
  getSearchResultByIndexName(indexName: string): Observable<any> {
    return this.searchResults$.pipe(
      filter(results => !!results && results.length > 0), // Asegurarse de que existan resultados
      map(results => results.find(result => result.index === indexName)) // Buscar el resultado que coincida con el nombre del índice
    );
  }

  handleFSubmitFilterCategory(filterSelectedRequest:HandleFilterAlgolioRequest): Observable<HandleSelectedAlgoliaResponse>{
    return this._genericService.genericPostWithoutSecurityHeaders(this.endPointAlgolia+`${'query'}`, filterSelectedRequest, null, this.setHeaders);
  }
}
