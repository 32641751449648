<div
  class="choose-a-store-modal"
  *ngIf="!isLoadingModal"
  [ngClass]="{ 'modal--disabled': isDisableChooseStore }"
>
  <div class="button-modal-close" (click)="closeModal()">
    <span class="icon icon-24-close"></span>
  </div>

  <fp-section-container-title-v2
    title="Selecciona una botica"
    [svgName]="brand == 'Inkafarma'
      ? 'store-for-pick-up-green'
      : 'store-for-pick-up-orange'"
    iconSize="32"
  >
  </fp-section-container-title-v2>

  <fp-sub-section-container-v2>
    <div class="mt-5">
      <div class="row" [ngClass]="{'justify-content-center': modalStatus === ALL_MODAL_STATUS.EMPTY}">
        <div [ngClass]="{'col-12': modalStatus === ALL_MODAL_STATUS.EMPTY, 'col-6 pr-3': modalStatus !== ALL_MODAL_STATUS.EMPTY}">
          <div class="mb-2" *ngIf="isOrderTaker">
            <fp-border-container>
              <div class="p-5 d-flex align-items-center justify-content-between">
                <span>Buscar por geocordenadas</span>
                <fp-switch
                  inputId="ctrl--search-coords-checkout"
                  [checkedValue]="isCheckedCoords"
                  (selectedItem)="clickSwitch()"
                >
                </fp-switch>
              </div>
            </fp-border-container>
          </div>
          <fp-input-search-for-ret
            (searchDone)="setModalStatus($event)"
            [isDisabledInputSearch]="isDisableChooseStore"
            [byCoords]="isCheckedCoords"
          >
          </fp-input-search-for-ret>
          <div class="row justify-content-center">
            <div [ngClass]="{'col-6 pl-3': modalStatus === ALL_MODAL_STATUS.EMPTY, 'col': modalStatus !== ALL_MODAL_STATUS.EMPTY}">
              <fp-drugstore-list-v2
                [status]="modalStatus"
                [withoutButton]="true"
                [isDisableChooseStore]="isDisableChooseStore"
              >
              </fp-drugstore-list-v2>
            </div>
          </div>
        </div>
        <div class="col-6 pl-3" *ngIf="modalStatus !== ALL_MODAL_STATUS.EMPTY">
          <div class="row">
            <div class="col-12 map-container">
              <fp-map-for-ret-v2
                *ngIf="modalStatus !== ALL_MODAL_STATUS.LOADING"
                [hideMapForRET]="
                  !(
                    !isLoadingModal &&
                    modalStatus !== ALL_MODAL_STATUS.IS_PENDING &&
                    modalStatus !== ALL_MODAL_STATUS.NO_ONE
                  )
                "
                [isDisabledMapForRET]="isDisableChooseStore"
              >
              </fp-map-for-ret-v2>
            </div>
          </div> 
        </div>
      </div>
    </div>
  </fp-sub-section-container-v2>
  <fp-sub-section-container-v2 [flagBorderTop]="false">
    <div class="buttons d-flex align-items-center py-4">
      <fp-button-secondary
        (click)="closeModal()"
        [buttonDisabled]="isDisableChooseStore"
        >
        Cancelar
      </fp-button-secondary>
      <fp-button-primary
        [buttonId]="'btn--choose-drugstore'"
        [buttonDisabled]="
        isDisableChooseStore || drugstores.length === 0
        "
        (click)="saveChanges()"
        >
        Elegir botica
      </fp-button-primary>
    </div>
  </fp-sub-section-container-v2>
</div>

<div class="skeleton" *ngIf="isLoadingModal">
  <fp-skeleton-for-pick-up-modal-v2></fp-skeleton-for-pick-up-modal-v2>
</div>
