<div class="card product"  [ngClass]="classCard" [id]="product.id" [attr.data-product-brand]="product?.brand" [attr.data-product-category]="product?.category">
	<div *ngIf="product.alertTagText?.length > 0" class="tag-product red px-2 py-1">
		<div class="tag-product-imagen mr-1">
			<fp-icon iconSize="16" iconName="alert-white" class="vertical-align-middle"></fp-icon>
		</div>
		<div class="tag-text text-tag-mini" [innerText]="product.alertTagText"></div>
	</div>

	<fp-link
		[linkRouter]="['/' + productPath, product.slug, product.id]"
		(linkActive)="registerEventAnalyticsAlgoliaAndValidateRedirect()"
	>
		<div class="row align-items-center">
			<div class="col-4 content-image-product">
				<fp-product-image
					class="w-100"
					[productStatus]="productStatus"
					[image]="productPresentation?.image"
					[imageName]="product.name"
					imageSize="91px"
				>
				</fp-product-image>
				<div class="tag-image-product" *ngIf="product.tagImageUrl">
					<fp-lazy-wrapper [lazy]="product?.tagImageUrl">
						<img #fpImg alt="tag" />
					</fp-lazy-wrapper>
				</div>
			</div>

			<div class="col-8">
				<div class="row">
					<div class="col-12 mb-2">
						<fp-product-card-information [name]="product.name" [description]="productPresentation?.description">
						</fp-product-card-information>
					</div>
					<!-- <div class="col-12 mt-2 d-flex flex-wrap tags" *ngIf="isInkaBrand">
						<fp-tag-seller
							*ngIf="isShowSeller"
							iconName="shopping-cart"
							text="{{ product.isFromSeller ? product.sellerName : 'Inkafarma' }}"
						>
						</fp-tag-seller>
						<fp-tag-seller *ngIf="product.prescription === productPrescription.PRESCRIPTION" iconName="prescription-gray">
						</fp-tag-seller>
					</div>
					<div class="col-12 mt-2 d-flex flex-wrap">
						<fp-tag-seller-brand
							iconName="shopping-cart"
							[text]="product.isFromSeller ? product.sellerName : getTextSellerINKAorMIFA()"
							innerClass="margin-left:0 !important;"
						></fp-tag-seller-brand>
					</div> -->

					<div class="tags-container col-12 mt-2 d-flex flex-wrap tags">
						<fp-tag-seller-brand
							*ngIf="product.isGeneric"
							[iconSize]="'16'"
							[iconName]="'pill'"
							[text]="'Genérico'"
						></fp-tag-seller-brand>

						<!-- <fp-tag-seller-brand
							*ngIf="showPrescription"
							[iconName]="iconPrescription"
							[text]="product.prescription"
							[shortTag]="shortTagPrescription"
						>
						</fp-tag-seller-brand>
						<fp-tag-seller-brand
							[iconSize]="'16'"
							[iconName]="iconBrand"
							[text]="textTagSeller"
							[shortTag]="shortTagBrand"
						></fp-tag-seller-brand> -->

						<fp-tag-seller-brand
							*ngIf="showPrescription"
							[iconName]="iconPrescription"
							[text]="product.prescription"
							[shortTag]="shortTagPrescription"
							[iconSize]="'16'"

						>
						</fp-tag-seller-brand>

						<fp-tag-seller-brand [iconSize]="'16'" [iconName]="iconBrand" [text]="textTagSeller" [shortTag]="shortTagBrand">
						</fp-tag-seller-brand>

						
					</div>
					<div class="col-12 mt-2">
						<fp-product-card-price
							*fpBrandAccess="[brand.inkafarma]"
							[regularPrice]="productPresentation?.regularPrice"
							[price]="productPresentation?.price"
							[offerPrice]="productPresentation?.offerPrice"
							[productStatus]="productStatus"
							[crossOutPL]="productPresentation?.crossOutPL"
						>
						</fp-product-card-price>
						<fp-product-card-price-mifa
							*fpBrandAccess="[brand.mifarma]"
							[regularPrice]="productPresentation?.regularPrice"
							[price]="productPresentation?.price"
							[offerPrice]="productPresentation?.offerPrice"
							[productStatus]="productStatus"
							[crossOutPL]="productPresentation?.crossOutPL"
						>
						</fp-product-card-price-mifa>
					</div>
					<br />

					<div class="col-12 mt-4">
						<div class="d-flex gap-3">
							<ng-container *ngIf="productStatus !== eProductStatus.unavailable">
								<div>
									<fp-icon iconSize="24" [iconName]="radHaveStock ? 'icon_rad_on' : 'icon_rad_off'"></fp-icon>
								</div>
								<div>
									<fp-icon
										iconSize="24"
										[iconName]="!isProductSeller && retHaveStock ? 'icon_ret_on' : 'icon_ret_off'"
									></fp-icon>
								</div>
							</ng-container>

							<ng-container *ngIf="productStatus === eProductStatus.unavailable">
								<div>
									<fp-icon iconSize="24" iconName="icon_rad_off"></fp-icon>
								</div>
								<div>
									<fp-icon iconSize="24" iconName="icon_ret_off"></fp-icon>
								</div>
							</ng-container>
						</div>
					</div>
				</div>
			</div>
		</div>
	</fp-link>

	<!-- <div class="tag-product-image" *ngIf="product.tagImageUrl">
		<fp-lazy-wrapper [lazy]="product?.tagImageUrl">
			<img #fpImg alt="tag" />
		</fp-lazy-wrapper>
	</div> -->

	<div class="row">
		<div class="col-12 mt-4 p-0">
			<fp-product-add-button
				[productInShoppingCart]="!!productInShoppingCart"
				[quantityLimit]="productPresentation?.maxUnitSale"
				[quantity]="productQuantity"
				[productStatus]="productStatus"
				(newQuantity)="updateQuantity($event)"
				[product]="product"
				(addProduct)="addProductToShoppingCart()"
				(deleted)="doDeleted()"
			>
			</fp-product-add-button>
		</div>
	</div>

	<fp-product-favorite
		class="product-favorite"
		[favorite]="product.favorite"
		(addFavorite)="addToFavorite()"
		(removeFavorite)="removeFromFavorite()"
	>
	</fp-product-favorite>
</div>
