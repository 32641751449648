import { Injectable } from '@angular/core';
import { GenericService } from '../generic';
import { ENDPOINTS } from '../../parameters/endpoints';
import { CampaignCustomerParams, ICampaignCustomerRequest } from '../../models/campaign/campaign-customer-request.model';
import { Observable } from 'rxjs';
import { ICampaignDepartment } from '../../models/campaign/campaign-department.model';
import { ICampaignIdentityDocumentType } from '../../models/campaign/campaign-identity-document-type.model';
import { ICampaignCustomerResponse } from '../../models/campaign/campaign-customer-response.model';
import { map, switchMap } from 'rxjs/operators';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { CampaignCustomerValuesRequest } from '../../../business/world-landing/models/world-affiliation-mom-and-baby.interface';
import { AmountToPointResponse } from 'src/app/business/savings-wallet/models/amount-to-point-response';
import { EBrand } from '@parameters/access/brand.parameter';
import { environment } from '@environments/environment';
import { InkaclubAffiliationImplementService } from 'src/app/business/inkaclub-landing/services/inkaclub-affiliation-implement.service';
import { ApigeeTokenService } from '@service/apigee/apigee-token.service';
@Injectable({
  providedIn: 'root'
})
export class CampaignClientService {
  url: string = ENDPOINTS.APIGEE_POST_PROMO_MULTIMARCA;

  constructor(
    private genericService: GenericService,
    private _apigeeTokenService: ApigeeTokenService,
  ) {
  }

  public getIdentityDocumentType(): Observable<Array<ICampaignIdentityDocumentType>> {
    return this.genericService.genericGet(ENDPOINTS.GET_IDENTITY_DOCUMENT_TYPE)
      .pipe(
        map((iCampaignIdentityDocumentTypeArray: Array<ICampaignIdentityDocumentType>) =>
          iCampaignIdentityDocumentTypeArray && iCampaignIdentityDocumentTypeArray.length ? iCampaignIdentityDocumentTypeArray : []));
  }

  public getCampaignDepartment(): Observable<Array<ICampaignDepartment>> {
    return this.genericService.genericGet(ENDPOINTS.GET_CAMPAIGN_DEPARTMENT)
      .pipe(
        map((iCampaignDepartmentArray: Array<ICampaignDepartment>) =>
          iCampaignDepartmentArray && iCampaignDepartmentArray.length ? iCampaignDepartmentArray : []));
  }

  public getCampaignCustomer(campaignCustomerParams: CampaignCustomerParams): Observable<ICampaignCustomerResponse> {
    const newHttpParams = new HttpParams()
      .set('campaignId', String(campaignCustomerParams.campaignId))
      .set('identityDocumentNumber', String(campaignCustomerParams.identityDocumentNumber))
      .set('identityDocumentType', String(campaignCustomerParams.identityDocumentType));
    return this.genericService.genericGet(ENDPOINTS.GET_CAMPAIGN_CUSTOMER, newHttpParams);
  }

  public postCampaignCustomer(request: ICampaignCustomerRequest): Observable<{ customerId: number, hoursUntilAccessToPromotions: number }> {
    return this.genericService.genericPost(ENDPOINTS.POST_CAMPAIGN_CUSTOMER, request);
  }

  public postCampaignCustomerValues(request: CampaignCustomerValuesRequest): Observable<{ id: number }> {
    return this.genericService.genericPost(ENDPOINTS.POST_CAMPAIGN_CUSTOMER_VALUES, request);
  }
// Methods for saving wallet


  public postCampaignRegister(request: any): Observable<any> {
    const url = this.getBrandSpecificUrl('registrar-afiliado');

		return this._apigeeTokenService.getTokenFromOauthParam(environment.apigee.tokenBase64Encode).pipe(
			switchMap((res: any) => {
				const newToken = res.access_token;
				const headers = new HttpHeaders({
					"Content-Type": "application/json; charset=utf-8",
					Authorization: `Bearer ${newToken}`
				});

				const encodeRequest = this.encodeFieldsToBase64(request)
				return this.genericService.genericPost(url, encodeRequest, null, headers);
			})
		);
  }

  public searchCampaignUser(request): Observable<any> {
    const url = this.getBrandSpecificUrl('buscar-afiliado');
		return this._apigeeTokenService.getTokenFromOauthParam(environment.apigee.tokenBase64Encode).pipe(
			switchMap((res: any) => {
				const newToken = res.access_token;
				const headers = new HttpHeaders({
					"Content-Type": "application/json; charset=utf-8",
					Authorization: `Bearer ${newToken}`
				});

				return this.genericService.genericPost(url, request, null, headers).pipe(
					map((response: any) => {
						if (response) {
							response.accountNumber = this.decodeBaseData64(response.accountNumber);
							response.name = this.decodeBaseData64(response.name);
							response.lastNamePaternal = this.decodeBaseData64(response.lastNamePaternal);
							response.lastNameMaternal = this.decodeBaseData64(response.lastNameMaternal);
							response.birthDate = this.decodeBaseData64(response.birthDate);
							response.email = this.decodeBaseData64(response.email);
              response.phone = this.decodeBaseData64(response.phone);
							response.cellPhone = this.decodeBaseData64(response.cellPhone);
						}
						return response;
					})
				);
			})
		);
  }

  public searchPurseUser(dni): Observable<any> {
    return this.genericService.genericGetWithoutSecurity(ENDPOINTS.SEARCH_PURSE+'/'+dni);
  }

  public benefitsUser(request): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers
      .set('source', 'web')
      .set('brand', 'MIFA')
      .set('x-access-token', 'token');
    return this.genericService.genericPostWithoutSecurityHeaders(ENDPOINTS.POST_BENEFITS_USER, request, null, headers);
  }

  public convertAmountToPoint(request): Observable<AmountToPointResponse> {
    let headers = new HttpHeaders();
    headers = headers
      .set('x-access-token', 'token');
    return this.genericService.genericPostWithoutSecurityHeaders(ENDPOINTS.POST_AMOUNT_TO_POINT, request, null, headers);
  }
  
	getBrandSpecificUrl(endpoint: string): string {
		return EBrand[environment.brand] == EBrand.inkafarma
			? `${this.url}/inkafarma/${endpoint}` : `${this.url}/mifarma/${endpoint}`;
	}

	private decodeBaseData64(data: string): string {
		return data ? atob(data) : data;
	}

	private encodeFieldsToBase64(request: any): any {
		// Lista de campos a codificar en base64
		const fieldsToEncode = [
			'nombres',
			'apePaterno',
			'apeMaterno',
			'dni',
			'fecnac',
			'fechaNacimiento',
			'celular',
			'correo'
		];
		// Se crea un nuevo objeto para no mutar el request original
		const encodedRequest = { ...request };
		// Codificar los campos que existan en el request
		fieldsToEncode.forEach(field => {
			if (encodedRequest[field]) {
				encodedRequest[field] = btoa(encodedRequest[field]);
			}
		});
		return encodedRequest;
	}
}
