<div class="new-address-modal-v2 scrollbar" [formGroup]="newAddressForm.form" id="core-new-address-modal">
	<fp-section-container-title-v2 [title]="modalTitle" [svgName]="isInkaBrand ? 'navigation-green' : 'navigation-orange'" [iconSize]="32">
	</fp-section-container-title-v2>
	<div class="icon icon-14-close icon-close" (click)="closeModal()"></div>

	<fp-sub-section-container-v2>
    <div class="responsive-container">
      <fp-border-container *ngIf="isOrderTaker">
        <div class="p-5 d-flex align-items-center justify-content-between">
          <span>Buscar por geocordenadas</span>
          <fp-switch
            inputId="ctrl--search-coords"
            [checkedValue]="isCheckedCoords"
            (selectedItem)="clickSwitch()"
          >
          </fp-switch>
        </div>
      </fp-border-container>
      <div
      class="card new-address-map"
      [style.background-image]="'url(' + googleMapImage + ')'"
      [ngClass]="{ 'map-height': showHereMap }"
    >
      <div class="google-map-container">
        <div class="here-map" #hereMap></div>
      </div>
      <div class="new-address-search">
        <fp-input-control-v2 [inputTitle]="''" [marginWithError]="false">
          <input
            type="text"
            class="input"
            id="ctrl--address"
            formControlName="direction"
            #inputControl
            [placeholder]="'Ingresa una dirección'"
            autocomplete="none"
            (input)="addressIsChanging()"
            (focus)="searchFocus()"
            (blur)="searchBlur()"
          />
          <div class="icon icono">
            <fp-icon iconSize="18" [iconName]="'location-icon-search'"></fp-icon>
          </div>
          <span *ngIf="newAddressForm.directionControl?.value?.length > 0" class="icon icon-16-circle-delete" (click)="removeAddress()"></span>
        </fp-input-control-v2>
        <div class="search-autocomplete-list card">
          <ng-container
            *ngIf="showCurrentLocationItem && geolocationService.isAcceptedGeolocation && isFocused && !addressValue"
          >
            <fp-search-autocomplete-item
              [title]="''"
              [icon]="'info'"
              [isJustLabel]="true"
              [subTitle]="'Si no encuentras tu dirección, prueba buscando primero tu provincia'"
              [applyDots]="false"
            >
            </fp-search-autocomplete-item>
            <fp-search-autocomplete-item *ngIf="!isCheckedCoords.isChecked"
              [title]="'Ubicación actual'"
              [icon]="'current-location'"
              [subTitle]="geolocationAddress"
              (selectItem)="locateGeolocationOnTheHereMap()"
            >
            </fp-search-autocomplete-item>
          </ng-container>
          <ng-container
            *ngIf="showCurrentLocationItem && !geolocationService.isAcceptedGeolocation && isFocused && !addressValue"
          >
            <fp-search-autocomplete-item
              [title]="''"
              [icon]="'info'"
              [isJustLabel]="true"
              [subTitle]="'Si no encuentras tu dirección, prueba buscando primero tu provincia'"
              [applyDots]="false"
            >
            </fp-search-autocomplete-item>
            <fp-search-autocomplete-item
              [title]="'Ubicación actual'"
              [icon]="'current-location'"
              [subTitle]="isPendingGeo ? geoMessage1 : geoMessage2"
              [subTitleRed]="true"
              (subTittleRedClick)="activateGeolocation()"
            >
            </fp-search-autocomplete-item>
          </ng-container>
          <ng-container *ngIf="showSearchAutocompleteList">
            <fp-search-autocomplete-item
              *ngIf="searchAutocompleteList.length === 0"
              [title]="''"
              [icon]="'info'"
              [isJustLabel]="true"
              [subTitle]="'Si no encuentras tu dirección, prueba buscando primero tu provincia'"
              [applyDots]="false"
            >
            </fp-search-autocomplete-item>
            <fp-search-autocomplete-item
              *ngIf="searchAutocompleteList.length === 0 && isFocused && geolocationAddress && !isCheckedCoords.isChecked"
              [title]="'Ubicación actual'"
              [icon]="'current-location'"
              [subTitle]="geolocationAddress"
              (selectItem)="locateGeolocationOnTheHereMap()"
            >
            </fp-search-autocomplete-item>
            <fp-search-autocomplete-item
              *ngFor="let item of searchAutocompleteList"
              [icon]="item.icon"
              [title]="item.title"
              [subTitle]="item.subTitle"
              [searchItem]="item.searchItem"
              (selectItem)="getPositionAndLocateAddress($event)"
            >
            </fp-search-autocomplete-item>
            <fp-search-autocomplete-item
              *ngIf="searchAutocompleteList.length === 0 && isFocused"
              [title]="'¿No encuentras la dirección?'"
              [subTitle]="'Fija la dirección en el mapa'"
              (selectItem)="locateDirectlyOnTheHereMap()"
            >
            </fp-search-autocomplete-item>
          </ng-container>
        </div>
      </div>
      <div class="no-coverage-container" *ngIf="thereIsNoCoverage">
        <fp-no-coverage (searchAgainEvent)="searchAgain()"> </fp-no-coverage>
      </div>
      </div>

      <div class="new-address-reference">
        <div *ngIf="isOrderTaker">
          <fp-input-control-v2 [inputTitle]="'Número'" [marginWithError]="false">
            <input
              type="text"
              class="input"
              id="ctrl--address-number"
              maxlength="4"
              formControlName="number"
              autocomplete="none"
              #inputControl
              placeholder="Ejem: 100"
              fpDigitOnly
            />
          </fp-input-control-v2>
        </div>
        <div class="new-address-reference-two-field">
          <fp-input-control-v2 [inputTitle]="'Piso, puerta, oficina o referencia'" [marginWithError]="false">
            <input
              type="text"
              class="input"
              id="ctrl--address-reference"
              maxlength="100"
              formControlName="reference"
              autocomplete="none"
              #inputControl
              placeholder="Ejem: Entre Av. Colonial y Av. Venezuela"
            />
          </fp-input-control-v2>
        </div>
      </div>
      <div class="radio-address-nickname">
        <div class="form-label color-gray">Marca una opción para esta dirección:</div>
        <div class="row">
          <div class="col-xs-12 col-md-4 container-border">
            <fp-border-container [selected]="newAddressForm.addressNicknameControl.value === 1">
              <fp-radio-address-nickname-v2
                [iconName]="'home-icon'"
                [name]="'Mi casa'"
                [selected]="newAddressForm.addressNicknameControl.value === 1"
                (selectRadioOption)="selectRadioOption(1)"
              >
                <fp-radio-control
                  id="ctrl--address-house"
                  formControlName="addressNickname"
                  [radioOptionValue]="1"
                ></fp-radio-control>
              </fp-radio-address-nickname-v2>
            </fp-border-container>
          </div>
          <div class="col-xs-12 col-md-4 container-border">
            <fp-border-container [selected]="newAddressForm.addressNicknameControl.value === 2">
              <fp-radio-address-nickname-v2
                [iconName]="'suitcase-icon'"
                [name]="'Trabajo'"
                [selected]="newAddressForm.addressNicknameControl.value === 2"
                (selectRadioOption)="selectRadioOption(2)"
              >
                <fp-radio-control
                  id="ctrl--address-office"
                  formControlName="addressNickname"
                  [radioOptionValue]="2"
                ></fp-radio-control>
              </fp-radio-address-nickname-v2>
            </fp-border-container>
          </div>
          <div class="col-xs-12 col-md-4 container-border">
            <fp-border-container [selected]="newAddressForm.addressNicknameControl.value === 3">
              <fp-radio-address-nickname-v2
                [iconName]="'location-icon'"
                [name]="'Otros'"
                [selected]="newAddressForm.addressNicknameControl.value === 3"
                (selectRadioOption)="selectRadioOption(3)"
              >
                <fp-radio-control
                  id="ctrl--address-other-reference"
                  formControlName="addressNickname"
                  [radioOptionValue]="3"
                ></fp-radio-control>
              </fp-radio-address-nickname-v2>
            </fp-border-container>
          </div>
        </div>
      </div>

      <div class="new-address-other-direction" [ngClass]="{ 'other-direction--show': addressNicknameValue === 3 }">
        <fp-input-control-v2 [inputTitle]="'¿De quién es esta dirección?'" [marginWithError]="false">
          <input
            type="text"
            id="ctrl--address-other"
            class="input"
            formControlName="otherDirection"
            autocomplete="none"
            #inputControl
            placeholder="Ejem: Tía Rosa"
          />
        </fp-input-control-v2>
      </div>
	  </div>
	</fp-sub-section-container-v2>

	<div class="botonera container">
		<div class="row d-flex flex-lg-row">
			<div class="col-xs-0 col-md-5"></div>

			<div class="col-xs-6 col-md-3">
				<button id="btn--close-modal-address" class="btn btn-secondary" (click)="closeModal()">Cancelar</button>
			</div>

			<div class="col-xs-6 col-md-4 px-0">
				<button
					id="btn--save-new-address"
					class="btn btn-primary"
					(click)="createOrUpdateAddress()"
					[disabled]="
						!(newAddressForm.isValidForm && !thereIsNoCoverage && wasDoneTheSearch) ||
						isCreatingOrUpdating ||
						isLoadingAddressFromMarker
					"
				>
					<div *ngIf="isCreatingOrUpdating" class="icon-alert loading loading-circle"></div>
					<span *ngIf="!isCreatingOrUpdating">Guardar dirección</span>
				</button>
			</div>
		</div>
	</div>
</div>
