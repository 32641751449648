import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ProductCard } from "../../models/product-card/product-card.model";
import { EProductStatus } from "../../models/product-status.enum";
import { ProductPresentation } from "../../models/product-presentation.model";
import { Subscription } from "rxjs";
import { ProductImplementService } from "../../service/product-implement.service";
import {
	FromProductToProductShoppingCartRequest,
	ProductShoppingCartRequest
} from "../../../../../shared/models/shopping-cart/product-shopping-cart-request.model";
import { BUSINESS_PATHS } from "src/app/shared/parameters/business.parameter";
import { getUnavailablePresentationStatus, productRADHaveStock, productRETHaveStock } from "../../service/product-status.helper";
import { ProductSalesforceService } from "../../service/product-salesforce.service";
import { TaggingService } from "src/app/atm-services/tagging.service";
import { PRODUCT_PRESCRIPTION } from "src/app/business/product-detail/parameters/product-detail.parameters";
import { ModalsManagerService } from "src/app/core/modules/pick-up-in-store/pick-up-services/modals-manager.service";
import { ShoppingListStoreService } from "src/app/shared/stores/shopping-list/shopping-list-store.service";
import { ShoppingCartRequestService } from "src/app/shared/service/shopping-cart/shopping-cart-request.service";
import { AnalyticServiceService } from "src/app/shared/service/analitycService/analitycService.service";
import { ShowModalBoticaService } from "src/app/shared/service/showModalServices/showModalService.service";
import { ProductDetailImplementService } from "src/app/business/product-detail/services/product-detail-implement.service";
import { AppStoreService } from "src/app/shared/stores/app/app-store.service";
import { ProductDetail } from "src/app/business/product-detail/models/product-detail-model";
import { take } from "rxjs/operators";
import { EBrand, EBrandName } from "@parameters/access/brand.parameter";
import { environment } from "@environments/environment";
import { BreakpointBehavior } from "src/app/shared/behaviors";
import { BREAKPOINTS } from "@parameters/global";
import { RetValidateCartService } from "src/app/shared/service/cart/ret-validate-cart.service";
import { CoverageLocationResponse } from "@models/coverage-location/coverage-location.model";
import { CoverageLocationStoreService } from "@stores/coverage-location/coverage-location-store.service";
import { OrderTakerValidator } from "@validators/order-taker.validator";
@Component({
	selector: "fp-product-small-category",
	templateUrl: "./product-small-category.component.html",
	styleUrls: ["./product-small-category.component.sass"]
})
export class ProductSmallCategoryComponent implements OnInit, OnDestroy {
	public brand = EBrand;
	public isInkaBrand = EBrand[environment.brand] == EBrand.inkafarma;
	public isMifaBrand = EBrand[environment.brand] == EBrand.mifarma;
	@Input() sectionID: string;
	@Input() product: ProductCard;
	@Input() classCard: string = "";
	public eProductStatus = EProductStatus;
	public productStatus: number = EProductStatus.available;
	public productPresentation: ProductPresentation;
	public productPrescription = PRODUCT_PRESCRIPTION;

	public productQuantity = 1;
	public productPresentationId: number;
	public productInShoppingCart: boolean;
	public isBigScreenSubscription: Subscription;
	private productInShoppingCartSubscription: Subscription;
	public isBigScreen: boolean;
	public productPath = BUSINESS_PATHS.PRODUCT;
	public coverage: CoverageLocationResponse;
	private coverageSubscription: Subscription;
	public isTomadorBrand = OrderTakerValidator.isOrderTakerEnv();

	constructor(
		private _breakpointBehavior: BreakpointBehavior,
		private _productImplement: ProductImplementService,
		private _productSalesforce: ProductSalesforceService,
		// ---- servicios para snackbar o modal de producto agregado o rechazado (marketplace) -------
		private _modalManagerService: ModalsManagerService,
		private _shoppingListStore: ShoppingListStoreService,
		private _shoppingCartRequest: ShoppingCartRequestService,
		private serviceAnalytic: AnalyticServiceService,
		private showModalBoticaService: ShowModalBoticaService,
		private productDetailImplement: ProductDetailImplementService,
		private _shoppingListStoreService: ShoppingListStoreService,
		private _appStore: AppStoreService,
		private retValidateCartService: RetValidateCartService,
		private coverageLocationStore: CoverageLocationStoreService // -----------------------
	) {
		this.coverageSubscription = this.coverageLocationStore.coverageLocationResponse$.subscribe((res) => {
			this.coverage = res;
		});
		this.isBigScreenSubscription = _breakpointBehavior
			.mediaBreakpointUpObserver(BREAKPOINTS.xxl)
			.subscribe((value) => (this.isBigScreen = value.matches));
	}

	get typeHaveStock() {
		return this.retValidateCartService.isRADProcess ? this.radHaveStock : this.retHaveStock;
	}

	get radHaveStock() {
		return productRADHaveStock(this.product, true, this.coverage);
	}

	get retHaveStock() {
		return productRETHaveStock(this.product, false);
	}

	ngOnInit() {
		this.loadRequestShoppingCart();
		this.getProductPresentation(this.product.defaultPresentation);
		this.getProductShoppingCart();
		this.getProductStatus();
	}

	addEventBrain() {
		TaggingService.productClickBrain(this.product, this.sectionID, "category");
	}

	getProductShoppingCart() {
		let productUpdate = true;
		this.productInShoppingCartSubscription = this._productImplement
			.getProductShoppingCart$(this.product.id)
			.subscribe((value: ProductShoppingCartRequest) => {
				this.productInShoppingCart = !!value;
				this.productPresentationId = this.productInShoppingCart ? value.presentationIdSelected : this.product.defaultPresentation;
				this.productQuantity = this.productInShoppingCart ? value.quantity : 1;
				this.getProductPresentation(this.productPresentationId);
				this.getProductStatus();

				if (this.productPresentation.id !== this.productPresentationId && productUpdate) {
					productUpdate = false;
					this.productQuantity = 1;
					this.updateProductToShoppingCart();
				}
				if (this.productInShoppingCart) {
					this.validateProductQuantity(this.product.id);
				}
			});
	}

	getProductStatus() {
		this.productStatus = this.product.statusId;
	}

	getProductPresentation(presentationId: number) {
		this.productPresentation =
			this.product.presentations.find((presentation) => presentation.id === presentationId) ||
			this.product.presentations.find((presentation) => presentation.id === this.product.defaultPresentation);
		this.productPresentationId = this.productPresentation.id;
	}

	updateQuantity(quantity: number) {
		if (this.productQuantity < quantity) {
			TaggingService.productClick(this.product);
			TaggingService.detail(this.product);
			// TaggingService.addToCartSDK(this.product,  this.productPresentation,quantity)
			TaggingService.addToCart(this.product, this.productPresentation);
		} else {
			TaggingService.removeFromCart(this.product, this.product.presentations[0].price, this.product.presentations[0].presentation);
			TaggingService.removeFromCartSDK(this.product, this.product.presentations[0].price, quantity);
		}

		this.productQuantity = quantity;
		this.updateProductToShoppingCart();
	}

	addProductToShoppingCart() {
		this._productImplement.addProduct(
			this.product.id,
			this.productQuantity,
			this.productPresentation.id,
			this.product.sellerId,
			this.product.sellerName
		);
		this._productSalesforce.addProduct(
			this.product.id,
			this.product.id,
			this.productQuantity,
			this.product.presentations[0].price * this.productQuantity
		);
		TaggingService.productClick(this.product);
		TaggingService.detail(this.product);
		TaggingService.addToCart(this.product, this.productPresentation);
		TaggingService.addToCartSDK(this.product, this.productPresentation, this.productQuantity);
		if (this.sectionID) {
			TaggingService.productAddToCartBrain(this.product, this.sectionID, this.productQuantity, "category");
		}
	}

	updateProductToShoppingCart() {
		this._productImplement.updateProduct(
			this.product.id,
			this.productQuantity,
			this.productPresentation.id,
			this.product.sellerId,
			this.product.sellerName
		);

		TaggingService.updateCartSDK(this.product, this.productPresentation.price, this.productQuantity);
	}

	ngOnDestroy(): void {
		this.productInShoppingCartSubscription.unsubscribe();
		this.isBigScreenSubscription.unsubscribe();
		this.coverageSubscription.unsubscribe();
	}

	addToFavorite() {
		this._productImplement.addToFavoriteProduct(this.product.id).subscribe(() => (this.product.favorite = true));
	}

	removeFromFavorite() {
		this._productImplement.removeFromFavoriteProduct(this.product.id).subscribe(() => (this.product.favorite = false));
	}

	showModalBotica(product) {
		this.showModalBoticaService.showModalResponsiveService(product);
		this.serviceAnalytic.registerEventAnalytics(
			this.serviceAnalytic.detectModuleTag("consultar_stock_en_botica"),
			"Boton :: consultar stock en botica",
			product.id
		);
	}

	loadRequestShoppingCart() {
		const shoppingCartListLocal = this._shoppingListStore.getLocalStorageShoppingCartList();
		this._shoppingCartRequest.shoppingProductListRequest = shoppingCartListLocal;
	}

	validateProductQuantity(id: string) {
		this.productDetailImplement
			.getProductDetail(id)
			.pipe(take(1))
			.subscribe((product: [ProductDetail, ProductCard]) => {
				const maxQuantity = product[0].presentations[0].maxUnitSale;
				if (this.productQuantity > maxQuantity && maxQuantity != 0) {
					this.productQuantity = maxQuantity;
					this.updateShoppingCart(id, this.productQuantity);
				}
			});
	}
	updateShoppingCart(id: string, quantity: number) {
		const shoppingLocalList = this._appStore.getStorageItem("shoppingCartList");
		const parsedObjectShoppingList = JSON.parse(shoppingLocalList);
		parsedObjectShoppingList.filter((res, i) => {
			if (id === res.productId) {
				parsedObjectShoppingList[i].quantity = quantity;
			}
		});
		this._appStore.setStorageItem("shoppingCartList", JSON.stringify(parsedObjectShoppingList));
	}

	get textTagSeller() {
		if (this.isTomadorBrand) {
			return this.product.isFromSeller ? this.product.sellerName : this._appStore.brandAtention == "IKF" ? "Inkafarma" : "Mifarma";
		} else {
			return this.product.isFromSeller
				? this.product.sellerName
				: this.isMifaBrand
				? EBrandName["mifarma"]
				: EBrandName["inkafarma"];
		}
	}

	get iconBrand() {
		return this.product.isFromSeller ? "shopping-cart" : this.isMifaBrand ? "tag-mf" : "tag-ikf";
	}

	get showPrescription() {
		return (
			this.product.prescription.toUpperCase() === this.productPrescription.PRESCRIPTION.toUpperCase() ||
			this.product.prescriptionCode
		);
	}

	get shortTagBrand() {
		return this.showPrescription || this.product.isGeneric;
	}
	get shortTagPrescription() {
		// return this.product.isGeneric;
		return this.product.isGeneric;
	}
	get iconPrescription() {
		return this.product.prescriptionCode ? "paper-background" : "prescription";
	}
}
