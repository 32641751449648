import { IconsSvgModel } from "../models/icons-svg.model";

const path = "../assets/icons/";

export const ICONS_SVG: Array<IconsSvgModel> = [
	{ name: "card-oh-mini-disabled", path: `${path}card-oh-mini-disabled.svg` },
	{ name: "card-oh-mini", path: `${path}card-oh-mini.svg` },
	{ name: "shop-discount", path: `${path}shop-discount.svg` },
	{ name: "arrow-right", path: `${path}arrow-right.svg` },
	{ name: "arrow-right-colored", path: `${path}arrow-right-colored.svg` },
	{ name: "arrow-right-purple", path: `${path}arrow-right-purple.svg` },
	{ name: "bag-gray-outline", path: `${path}bag-gray-outline.svg` },
	{ name: "bin-gray-outline", path: `${path}bin-gray-outline.svg` },
	{ name: "briefcase-outline", path: `${path}briefcase-outline.svg` },
	{ name: "check-gray-glyph", path: `${path}check-gray-glyph.svg` },
	{ name: "chevron-down-gray", path: `${path}chevron-down-gray.svg` },
	{ name: "close-outline-gray", path: `${path}close-outline-gray.svg` },
	{ name: "credit-card-outline", path: `${path}credit-card-outline.svg` },
	{ name: "enlarge-outline-gray", path: `${path}enlarge-outline-gray.svg` },
	{
		name: "favorite-default-outline-gray",
		path: `${path}favorite-default-outline-gray.svg`
	},
	{
		name: "favorite-default-outline",
		path: `${path}favorite-default-outline.svg`
	},
	{ name: "gps-outline", path: `${path}gps-outline.svg` },
	{ name: "house-outline", path: `${path}house-outline.svg` },
	{ name: "info-outline-small", path: `${path}info-outline-small.svg` },
	{ name: "invited-outline-small", path: `${path}invited-outline-small.svg` },
	{ name: "invoice-outline-small", path: `${path}invoice-outline-small.svg` },
	{ name: "log-out-outline", path: `${path}log-out-outline.svg` },
	{ name: "marker-outline", path: `${path}marker-outline.svg` },
	{ name: "pin-copy-outline", path: `${path}pin-copy-outline.svg` },
	{ name: "pin-outline-gray", path: `${path}pin-outline-gray.svg` },
	{
		name: "position-marker-outline",
		path: `${path}position-marker-outline.svg`
	},
	{
		name: "position-pin-outline-small",
		path: `${path}position-pin-outline-small.svg`
	},
	{ name: "prescription-glyph", path: `${path}prescription-glyph.svg` },
	{ name: "preview-off-outline", path: `${path}preview-off-outline.svg` },
	{ name: "preview-on-outline", path: `${path}preview-on-outline.svg` },
	{ name: "remove-outline-small", path: `${path}remove-outline-small.svg` },
	{
		name: "setup-preferences-glyph-gray",
		path: `${path}setup-preferences-glyph-gray.svg`
	},
	{
		name: "setup-preferences-colored",
		path: `${path}setup-preferences-colored.svg`
	},
	{ name: "shop-outline-gray", path: `${path}shop-outline-gray.svg` },
	{
		name: "shopping-cart-outline-gray",
		path: `${path}shopping-cart-outline-gray.svg`
	},
	{ name: "time-outline-small", path: `${path}time-outline-small.svg` },
	{ name: "user-outline-gray", path: `${path}user-outline-gray.svg` },
	{ name: "zoom-outline-gray", path: `${path}zoom-outline-gray.svg` },
	{ name: "zoom-find", path: `${path}zoom-find.svg` },

	{ name: "arrow-left", path: `${path}arrow-left.svg` },
	{ name: "bag-outline", path: `${path}bag-outline.svg` },
	{ name: "bin-outline", path: `${path}bin-outline.svg` },
	{ name: "book-outline", path: `${path}book-outline.svg` },
	{ name: "broken-heart-outline", path: `${path}broken-heart-outline.svg` },
	{ name: "cash-outline", path: `${path}cash-outline.svg` },
	{ name: "check-colored", path: `${path}check-colored.svg` },
	{ name: "check-glyph", path: `${path}check-glyph.svg` },
	{ name: "chevron-down", path: `${path}chevron-down.svg` },
	{ name: "chevron-left", path: `${path}chevron-left.svg` },
	{ name: "chevron-right", path: `${path}chevron-right.svg` },
	{ name: "chevron-up", path: `${path}chevron-up.svg` },
	{ name: "close-outline", path: `${path}close-outline.svg` },
	{ name: "collection-outline", path: `${path}collection-outline.svg` },
	{ name: "credit-card-outline", path: `${path}credit-card-outline.svg` },
	{ name: "crosshair-outline", path: `${path}crosshair-outline.svg` },
	{ name: "cry-face-outline", path: `${path}cry-face-outline.svg` },
	{ name: "danger-colored", path: `${path}danger-colored.svg` },
	{ name: "deliver-outline", path: `${path}deliver-outline.svg` },
	{ name: "facebook-glyph", path: `${path}facebook-glyph.svg` },
	{ name: "facebook-outline", path: `${path}facebook-outline.svg` },
	{ name: "favorite-active", path: `${path}favorite-active.svg` },
	{ name: "favorite-default", path: `${path}favorite-default.svg` },
	{ name: "favorite-hover", path: `${path}favorite-hover.svg` },
	{ name: "gps-outline", path: `${path}gps-outline.svg` },
	{ name: "info-outline", path: `${path}info-outline.svg` },
	{ name: "info-outline-purple", path: `${path}info-outline-purple.svg` },
	{ name: "instagram-outline", path: `${path}instagram-outline.svg` },
	{ name: "invited-outline", path: `${path}invited-outline.svg` },
	{ name: "invoice-outline", path: `${path}invoice-outline.svg` },
	{ name: "loading-process", path: `${path}loading-process.svg` },
	{ name: "menu-hamburger-outline", path: `${path}menu-hamburger-outline.svg` },
	{
		name: "menu-horizontal-outline",
		path: `${path}menu-horizontal-outline.svg`
	},
	{ name: "menu-list-outline", path: `${path}menu-list-outline.svg` },
	{ name: "menu-vertical-glyph", path: `${path}menu-vertical-glyph.svg` },
	{ name: "minus-sign", path: `${path}minus-sign.svg` },
	{ name: "pencil-outline", path: `${path}pencil-outline.svg` },
	{ name: "pin-outline", path: `${path}pin-outline.svg` },
	{ name: "plus-sign", path: `${path}plus-sign.svg` },
	{ name: "plus-sign-white", path: `${path}plus-sign-white.svg` },
	{ name: "plus-sign-blue", path: `${path}plus-sign-blue.svg` },
	{ name: "select-arrow-down", path: `${path}select-arrow-down.svg` },
	{ name: "pos-outline", path: `${path}pos-outline.svg` },
	{ name: "position-pin-outline", path: `${path}position-pin-outline.svg` },
	{ name: "remove-outline", path: `${path}remove-outline.svg` },
	{ name: "scooter-outline", path: `${path}scooter-outline.svg` },
	{ name: "shop-outline", path: `${path}shop-outline.svg` },
	{ name: "shopping-cart-glyph", path: `${path}shopping-cart-glyph.svg` },
	{ name: "shopping-cart-outline", path: `${path}shopping-cart-outline.svg` },
	{ name: "smile-face-outline", path: `${path}smile-face-outline.svg` },
	{ name: "time-outline", path: `${path}time-outline.svg` },
	{ name: "user-outline", path: `${path}user-outline.svg` },
	{ name: "warning-colored", path: `${path}warning-colored.svg` },
	{ name: "zoom-outline", path: `${path}zoom-outline.svg` },
	{ name: "invited-outline-round", path: `${path}invited-outline-round.svg` },
	{ name: "close-input", path: `${path}close-input.svg` },
	{ name: "search-input", path: `${path}search-input.svg` },

	{
		name: "favorite-active-desktop",
		path: `${path}favorite-active-desktop.svg`
	},
	{
		name: "favorite-active-desktop",
		path: `${path}favorite-active-desktop.svg`
	},
	{ name: "favorite-active-mobile", path: `${path}favorite-active-mobile.svg` },
	{
		name: "favorite-default-desktop",
		path: `${path}favorite-default-desktop.svg`
	},
	{
		name: "favorite-default-mobile",
		path: `${path}favorite-default-mobile.svg`
	},
	{ name: "favorite-hover-desktop", path: `${path}favorite-hover-desktop.svg` },
	{ name: "favorite-hover-mobile", path: `${path}favorite-hover-mobile.svg` },
	{ name: "card-amex-colored", path: `${path}card-amex-colored.svg` },
	{
		name: "card-mastercard-colored",
		path: `${path}card-mastercard-colored.svg`
	},
	{ name: "card-oh-colored", path: `${path}card-oh-colored.svg` },
	{ name: "card-visa-colored", path: `${path}card-visa-colored.svg` },
	{ name: "card-diners-club", path: `${path}card-diners-club.svg` },
	{ name: "card-oh", path: `${path}card-oh.svg` },
	{ name: "card-oh-visa", path: `${path}card-oh-visa.svg` },
	{ name: "card-oh-mastercard", path: `${path}card-oh-mastercard.svg` },
	{ name: "card-agora-visa", path: `${path}card-agora-visa.svg` },
	{ name: "card-agora-46_34", path: `${path}card-agora-46_34.svg` },

	{ name: "bag-plus-colored", path: `${path}bag-plus-colored.svg` },
	{ name: "book-open-colored", path: `${path}book-open-colored.svg` },
	{ name: "broken-heart-colored", path: `${path}broken-heart-colored.svg` },
	{
		name: "feedback-bubble-colored",
		path: `${path}feedback-bubble-colored.svg`
	},
	{ name: "headset-colored", path: `${path}headset-colored.svg` },
	{
		name: "inkafarma-isotipo-color",
		path: `${path}inkafarma/logo/isotipo-color.svg`
	},
	{ name: "mobile-chat-colored", path: `${path}mobile-chat-colored.svg` },
	{ name: "navigation-map-colored", path: `${path}navigation-map-colored.svg` },
	{
		name: "padlock-unlocked-colored",
		path: `${path}padlock-unlocked-colored.svg`
	},
	{ name: "paper-copy-colored", path: `${path}paper-copy-colored.svg` },
	{ name: "paper-plane-colored", path: `${path}paper-plane-colored.svg` },
	{ name: "phone-call-colored", path: `${path}phone-call-colored.svg` },
	{ name: "privacy-shield-colored", path: `${path}privacy-shield-colored.svg` },
	{
		name: "question-bubble-colored",
		path: `${path}question-bubble-colored.svg`
	},
	{ name: "remove-colored", path: `${path}remove-colored.svg` },
	{ name: "sad-sweat-face-colored", path: `${path}sad-sweat-face-colored.svg` },
	{ name: "scale-colored", path: `${path}scale-colored.svg` },
	{ name: "user-colored", path: `${path}user-colored.svg` },
	{ name: "world-marker-colored", path: `${path}world-marker-colored.svg` },

	{ name: "big-bag-colored", path: `${path}big-bag-colored.svg` },
	{ name: "shopping-cart-colored", path: `${path}shopping-cart-colored.svg` },
	{ name: "moto-colored", path: `${path}moto-colored.svg` },
	{ name: "pin-colored", path: `${path}pin-colored.svg` },
	{ name: "small-bag-colored", path: `${path}small-bag-colored.svg` },
	{ name: "store-colored", path: `${path}store-colored.svg` },
	{ name: "truck-colored", path: `${path}truck-colored.svg` },
	{ name: "filters-icon", path: `${path}filters-icon.svg` },

	{
		name: "background-search-no-products",
		path: `${path}background-search-no-products.svg`
	},
	{ name: "person-searching", path: `${path}person-searching.svg` },
	{ name: "gray-phone", path: `${path}gray-phone.svg` },

	{ name: "track-order", path: `${path}track-order.svg` },
	{ name: "delivery-truck", path: `${path}delivery-truck.svg` },
	{ name: "mail-1", path: `${path}mail-1.svg` },
	{ name: "headphones-1", path: `${path}headphones-1.svg` },
	{ name: "cry-face-search", path: `${path}cry-face-search.svg` },
	{ name: "arrow-blue-left", path: `${path}arrow-blue-left.svg` },
	{ name: "track-option", path: `${path}track-option.svg` },
	{ name: "linkedin-round", path: `${path}linkedin-round.svg` },
	{ name: "twitter-round", path: `${path}twitter-round.svg` },
	{ name: "whatsapp-ico", path: `${path}whatsapp-ico.svg` },
	{ name: "facebook-ico", path: `${path}facebook-ico.svg` },
	{ name: "star-colored", path: `${path}star-colored.svg` },

	{ name: "box-group", path: `${path}box-group.svg` },
	{ name: "gray-pc", path: `${path}gray-pc.svg` },
	{ name: "gray-phone-call", path: `${path}gray-phone-call.svg` },
	{ name: "gray-shop", path: `${path}gray-shop.svg` },
	{
		name: "mifarma-isotipo-color",
		path: `${path}mifarma/logo/isotipo-color.svg`
	},
	{ name: "semi-cursor", path: `${path}semi-cursor.svg` },
	{ name: "finger-pointing-up", path: `${path}finger-pointing-up.svg` },
	{ name: "impressed-person", path: `${path}impressed-person.svg` },
	{ name: "main-logo-mifarma", path: `${path}main-logo-mifarma.svg` },

	{ name: "yellow-star", path: `${path}yellow-star.svg` },

	{ name: "ecommerce-outline-gray", path: `${path}ecommerce-outline-gray.svg` },
	{ name: "store-colored-gray", path: `${path}store-colored-gray.svg` },

	{ name: "world-affiliation-man", path: `${path}world-affiliation-man.svg` },
	{
		name: "world-affiliation-man-with-baby",
		path: `${path}world-affiliation-man-with-baby.svg`
	},
	{
		name: "world-affiliation-woman",
		path: `${path}world-affiliation-woman.svg`
	},
	{
		name: "world-affiliation-woman-with-baby",
		path: `${path}world-affiliation-woman-with-baby.svg`
	},
	{
		name: "world-affiliation-heart-with-heart",
		path: `${path}world-affiliation-heart-with-heart.svg`
	},
	{
		name: "world-affiliation-heart",
		path: `${path}world-affiliation-heart.svg`
	},

	{ name: "person-hands-up", path: `${path}person-hands-up.svg` },
	{ name: "surprised-person", path: `${path}surprised-person.svg` },
	{ name: "zoom-glyph", path: `${path}zoom-glyph.svg` },

	{ name: "delivery-store", path: `${path}delivery-store.svg` },
	{ name: "delivery-place", path: `${path}delivery-place.svg` },
	{ name: "shop-1", path: `${path}shop-1.svg` },
	{ name: "blue-pencil", path: `${path}blue-pencil.svg` },
	{ name: "hand-pointing-up", path: `${path}hand-pointing-up.svg` },
	{
		name: "inkafarma-active-marker",
		path: `${path}inkafarma-active-marker.svg`
	},
	{
		name: "inkafarma-inactive-marker",
		path: `${path}inkafarma-inactive-marker.svg`
	},
	{ name: "moto-delivery", path: `${path}moto-delivery.svg` },
	{ name: "store-for-pick-up", path: `${path}store-for-pick-up.svg` },
	{ name: "white-fill-gps", path: `${path}white-fill-gps.svg` },
	{ name: "blue-clock", path: `${path}blue-clock.svg` },
	{ name: "blue-shop", path: `${path}blue-shop.svg` },
	{ name: "user-pickup", path: `${path}user-pickup.svg` },
	{ name: "blue-check", path: `${path}blue-check.svg` },

	{ name: "delivery-store", path: `${path}delivery-store.svg` },
	{ name: "delivery-place", path: `${path}delivery-place.svg` },
	{ name: "shop-1", path: `${path}shop-1.svg` },
	{ name: "blue-pencil", path: `${path}blue-pencil.svg` },
	{ name: "hand-pointing-up", path: `${path}hand-pointing-up.svg` },
	{
		name: "inkafarma-active-marker",
		path: `${path}inkafarma-active-marker.svg`
	},
	{
		name: "inkafarma-inactive-marker",
		path: `${path}inkafarma-inactive-marker.svg`
	},
	{ name: "moto-delivery", path: `${path}moto-delivery.svg` },
	{ name: "store-for-pick-up", path: `${path}store-for-pick-up.svg` },
	{ name: "white-fill-gps", path: `${path}white-fill-gps.svg` },
	{ name: "blue-clock", path: `${path}blue-clock.svg` },
	{ name: "blue-shop", path: `${path}blue-shop.svg` },
	{ name: "user-pickup", path: `${path}user-pickup.svg` },
	{ name: "blue-check", path: `${path}blue-check.svg` },

	{ name: "diners-club", path: `${path}diners-club.svg` },
	{ name: "delivery-fast", path: `${path}delivery-fast.svg` },
	{ name: "delivery-fast-mifa", path: `${path}delivery-fast-mifa.svg` },
	{ name: "calendar-picker", path: `${path}calendar-picker.svg` },
	{ name: "circle-semantic", path: `${path}circle-semantic.svg` },
	{ name: "icon-happy-face", path: `${path}happy.svg` },
	{ name: "icon-ret", path: `${path}icon-RET.svg` },
	{ name: "icon_rad_off", path: `${path}icon_rad_off.svg` },
	{ name: "icon_rad_on", path: `${path}icon_rad_on.svg` },
	{ name: "icon_ret_off", path: `${path}icon_ret_off.svg` },
	{ name: "icon_ret_on", path: `${path}icon_ret_on.svg` },
	{ name: "icon-cupon", path: `${path}icon-cupon.svg` },
	{ name: "icon-green-success", path: `${path}green-success.svg` },
	{ name: "icon-copy-coupon", path: `${path}icon-copy-coupon.svg` },
	{ name: "icon-happy-face", path: `${path}happy.svg` },
	{ name: "image_coupon_new", path: `${path}image_coupon_new.svg` },
	{ name: "alert-red", path: `${path}alert-red.svg` },
	{ name: "alert-white", path: `${path}alert-white.svg` },
	{ name: "marketplace-gray", path: `${path}marketplace-gray.svg` },
	{ name: "marketplace-gray2", path: `${path}marketplace-gray2.svg` },
	{ name: "marketplace-blue", path: `${path}marketplace-blue.svg` },
	{ name: "marketplace-orange", path: `${path}marketplace-orange.svg` },
	{ name: "prescription-gray", path: `${path}prescription-gray.svg` },
	{ name: "icon-info", path: `${path}icon-info.svg` },
	{ name: "save-money", path: `${path}save-money.svg` },
	{ name: "warning-gray", path: `${path}warning-gray.svg` },
	{ name: "arrow-right", path: `${path}24_ic-small-right-glyph-24.svg` },
	{ name: "icon-camion", path: `${path}camion.svg` },
	{ name: "icon-logo-inka", path: `${path}icon_inka_logo.svg` },
	{ name: "icon-delete", path: `${path}icon-delete.svg` },
	{ name: "icon-plus", path: `${path}icon-plus.svg` },
	{ name: "coupon", path: `${path}cupon.svg` },
	{ name: "icon-cupon", path: `${path}icon-cupon.svg` },
	{ name: "icon-green-success", path: `${path}green-success.svg` },
	{ name: "icon-copy-coupon", path: `${path}icon-copy-coupon.svg` },
	{ name: "expand_more", path: `${path}expand_more.svg` },
	{ name: "user_only", path: `${path}user_only.svg` },
	{ name: "vita_logout", path: `${path}vita_logout.svg` },
	{
		name: "main_logo_farmacias_white",
		path: `${path}main_logo_farmacias_white.svg`
	},
	{ name: "avatar", path: `${path}avatar.svg` },
	{ name: "face_sad", path: `${path}face_sad.svg` },
	{ name: "face_bad", path: `${path}face_bad.svg` },
	{ name: "map", path: `${path}map.svg` },
	{ name: "add", path: `${path}add.svg` },
	{ name: "drugstore", path: `${path}drugstore_icon.svg` },
	{ name: "location", path: `${path}location.svg` },
	{ name: "box", path: `${path}box.svg` },
	{ name: "box-seller", path: `${path}box-seller.svg` },
	{ name: "navigation", path: `${path}navigation.svg` },
	{ name: "navigation-blue", path: `${path}navigation-blue.svg` },
	{ name: "icon-success", path: `${path}check-circle.svg` },
	{ name: "icon-error", path: `${path}error.svg` },
	{ name: "icon-warning", path: `${path}warning.svg` },
	{ name: "address", path: `${path}address.svg` },
	{ name: "alert-light", path: `${path}alert-light.svg` },
	{ name: "alert-error", path: `${path}alert-error.svg` },
	{ name: "store", path: `${path}store.svg` },
	{ name: "cart-dispatch", path: `${path}cart-dispatch.svg` },
	{ name: "required", path: `${path}required.svg` },
	{ name: "delivery", path: `${path}delivery.svg` },
	{ name: "dispatch-store", path: `${path}dispatch-store.svg` },
	{ name: "expand_more_white", path: `${path}expand_more_white.svg` },
	{ name: "prescription", path: `${path}prescription.svg` },
	{ name: "pin-green", path: `${path}pin-green.svg` },
	{ name: "clock", path: `${path}clock.svg` },
	{ name: "recipe", path: `${path}recipe.svg` },
	{ name: "cash", path: `${path}cash.svg` },
	{ name: "logout-icon", path: `${path}logout.svg` },
	{ name: "swap", path: `${path}swap.svg` },
	{ name: "zoom", path: `${path}zoom.svg` },
	{ name: "zoom-no", path: `${path}zoom-no.svg` },
	{ name: "icon-backup", path: `${path}icon-backup.svg` },
	{ name: "store_outline", path: `${path}store_outline.svg` },
	{ name: "truck", path: `${path}truck.svg` },
	{ name: "time", path: `${path}time.svg` },
	{ name: "time-circle", path: `${path}time-circle.svg` },
	{ name: "heart", path: `${path}heart.svg` },
	{ name: "shopping-cart", path: `${path}shopping-cart.svg` },
	{ name: "copy-paper", path: `${path}copy-paper.svg` },
	{ name: "delete", path: `${path}delete.svg` },
	{ name: "pen", path: `${path}pen.svg` },
	{ name: "transfer", path: `${path}transfer.svg` },
	{ name: "store_outline_blue", path: `${path}store_outline_blue.svg` },
	{ name: "store-for-pick-up-blue", path: `${path}store-for-pick-up-blue.svg` },
	{ name: "moto-delivery-blue", path: `${path}moto-delivery-blue.svg` },
	{ name: "pin-blue", path: `${path}pin-blue.svg` },
	{ name: "recipe-blue", path: `${path}recipe-blue.svg` },
	{ name: "delivery-fast-blue", path: `${path}delivery-fast-blue.svg` },
	{ name: "icon-happy-face", path: `${path}happy.svg` },
	{ name: "icon-bestofferts", path: `${path}shop-bestofferts.svg` },
	{ name: "image_coupon_new", path: `${path}image_coupon_new.svg` },
	{ name: "search-icon-purple", path: `${path}search-icon-purple.svg` },
	{ name: "check-green", path: `${path}check-green.svg` },
	{ name: "cancel-red", path: `${path}cancel-red.svg` },
	{ name: "warning-yellow", path: `${path}warning-yellow.svg` },

	{ name: "check-icon", path: `${path}check-icon.svg` },
	{ name: "sp-info", path: `${path}sp-info.svg` },
	{ name: "icon-x-close", path: `${path}icon-x-close.svg` },
	{ name: "face-smile", path: `${path}face-smile.svg` },
	{ name: "clock-flextime", path: `${path}clock-flextime.svg` },

	{ name: "check-m", path: `${path}check-m.svg` },
	{ name: "mission-face", path: `${path}m-face.svg` },
	{ name: "mission-calendar", path: `${path}m-calendar.svg` },
	{ name: "play-icon", path: `${path}play-icon.svg` },
	{ name: "car-aliviamed", path: `${path}car-aliviamed.svg` },
	{ name: "coupon-aliviamed", path: `${path}coupon-aliviamed.svg` },
	{ name: "rx-aliviamed", path: `${path}rx-aliviamed.svg` },
	{ name: "unfold-more", path: `${path}unfold-more.svg` },
	{ name: "play-icon", path: `${path}play-icon.svg` },

	{ name: "card-monedero", path: `${path}card-monedero-mf.svg` },
	{ name: "monedero-check", path: `${path}monedero-check.svg` },
	{ name: "credit-cards-oh-mf", path: `${path}credit-cards-oh-mf.svg` },
	{ name: "dropdown_arrow", path: `${path}light/arrow--down.svg` },

	{ name: "check-m", path: `${path}check-m.svg` },
	{ name: "mission-face", path: `${path}m-face.svg` },
	{ name: "mission-calendar", path: `${path}m-calendar.svg` },

	{ name: "coupon-ticket", path: `${path}coupon-ticket.svg` },
	{ name: "chat-alert", path: `${path}chat-alert.svg` },

	{ name: "call-center-inka", path: `${path}inkafarma/call-center-inka.svg` },
	{ name: "chat-inka", path: `${path}inkafarma/chat-inka.svg` },
	{ name: "call-center-mifa", path: `${path}mifarma/call-center-mifa.svg` },
	{ name: "chat-mifa", path: `${path}mifarma/chat-mifa.svg` },

	{ name: "aliviamed", path: `${path}aliviamed.svg` },
	{ name: "messenger", path: `${path}messenger.svg` },
	{ name: "whatsapp", path: `${path}whatsapp.svg` },
	{ name: "calling-bold", path: `${path}bold/calling.svg` },

	{ name: "monedero", path: `${path}monedero.svg` },
	{ name: "alert-triangle", path: `${path}alert-triangle.svg` },
	{ name: "arrow_diag_right", path: `${path}arrow_diag_right.svg` },
	{ name: "icon-history", path: `${path}history.svg` },

	{ name: "oh-logo", path: `${path}oh-logo.svg` },
	{ name: "arrow_diag_right", path: `${path}arrow_diag_right.svg` },
	{ name: "icon-history", path: `${path}history.svg` },
	{ name: "arrow_diag_right", path: `${path}arrow_diag_right.svg` },
	{ name: "icon-history", path: `${path}history.svg` },

	{ name: "oh-logo", path: `${path}oh-logo.svg` },
	{ name: "oh-logo", path: `${path}oh-logo.svg` },
	{ name: "cartv2", path: `${path}cartv2.svg` },

	{ name: "promotion", path: `${path}promotion.svg` },
	{ name: "delivery-free", path: `${path}delivery-free.svg` },
	{ name: "cyber-wow", path: `${path}cyber-wow.svg` },
	{ name: "suggested", path: `${path}suggested.svg` },

	{ name: "online-icon", path: `${path}SVG_credit-card1.svg` },
	{ name: "agora-icon", path: `${path}SVG_credit-card2.svg` },
	{ name: "pos-icon", path: `${path}SVG_pos.svg` },
	{ name: "cash-icon", path: `${path}SVG_money-coins.svg` },
	{ name: "store-green", path: `${path}store-green.svg` },
	{ name: "time-purple", path: `${path}time-purple.svg` },
	{ name: "info-purple", path: `${path}info-purple.svg` },
	{ name: "mf-store", path: `${path}mf-store.svg` },
	{ name: "recipe-ikf", path: `${path}/inkafarma/recipe-ikf.svg` },
	{ name: "recipe-mf", path: `${path}/mifarma/recipe-mf.svg` },
	{ name: "upload-file-ikf", path: `${path}/inkafarma/upload-file-ikf.svg` },
	{ name: "upload-file-mf", path: `${path}/mifarma/upload-file-mf.svg` },
	{ name: "user-card-ikf", path: `${path}/inkafarma/user-card-ikf.svg` },
	{ name: "user-card-mf", path: `${path}/mifarma/user-card-mf.svg` },
	{ name: "upload-ikf", path: `${path}/inkafarma/upload-ikf.svg` },
	{ name: "upload-mf", path: `${path}/mifarma/upload-mf.svg` },
	{ name: "paper", path: `${path}paper.svg` },
	{ name: "upload-disabled", path: `${path}upload-disabled.svg` },
	{ name: "paper-background", path: `${path}paper-background.svg` },
	{ name: "alert-yellow", path: `${path}alert-yellow.svg` },
	{ name: "tag-mf", path: `${path}/mifarma/tag-mf.svg` },
	{ name: "tag-ikf", path: `${path}/inkafarma/tag-ikf.svg` },

	{ name: "point-consumed", path: `${path}point-consumed.svg` },
	{ name: "point-acquired", path: `${path}point-acquired.svg` },
	{ name: "point-disabled", path: `${path}point-disabled.svg` },
	{ name: "warning-v2", path: `${path}warning-v2.svg` },
	{ name: "question-mark", path: `${path}question-mark.svg` },
	{ name: "clock-v2", path: `${path}clock-v2.svg` },
	{ name: "refresh", path: `${path}refresh.svg` },
	{ name: "close-square-white", path: `${path}close-square-white.svg` },
	{ name: "arrow-back-new", path: `${path}arrow-back-new.svg` },
	{ name: "file", path: `${path}file.svg` },
	{ name: "image", path: `${path}image.svg` },
	{ name: "camera", path: `${path}camera.svg` },
	{ name: "arrow-back-new", path: `${path}arrow-back-new.svg` },

	{ name: "bill-green", path: `${path}bill-green.svg` },
	{ name: "bill-orange", path: `${path}bill-orange.svg` },
	{ name: "recipe-green", path: `${path}recipe-green.svg` },
	{ name: "recipe-orange", path: `${path}recipe-orange.svg` },
	{ name: "right_arrow", path: `${path}light/arrow--right.svg` },
	{ name: "pin-orange", path: `${path}pin-orange.svg` },
	{ name: "store-for-pick-up-orange", path: `${path}store-for-pick-up-orange.svg` },
	{ name: "store-blue", path: `${path}store-blue.svg` },
	{ name: "store-purple", path: `${path}store-purple.svg` },
	{ name: "calendar-green", path: `${path}calendar-green.svg` },
	{ name: "calendar-orange", path: `${path}calendar-orange.svg` },
	{ name: "clock-green", path: `${path}clock-green.svg` },
	{ name: "clock-orange", path: `${path}clock-orange.svg` },
	{ name: "check-blue-lg", path: `${path}check-blue-lg.svg` },
	{ name: "check-purple-lg", path: `${path}check-purple-lg.svg` },
	{ name: "bike-green", path: `${path}bike-green.svg` },
	{ name: "bike-orange", path: `${path}bike-orange.svg` },
	{ name: "vt-calendar", path: `${path}vt-calendar.svg` },
	{ name: "navigation-green", path: `${path}navigation-green.svg` },
	{ name: "navigation-orange", path: `${path}navigation-orange.svg` },
	{ name: "home-icon", path: `${path}home_icon.svg` },
	{ name: "suitcase-icon", path: `${path}suitcase_icon.svg` },
	{ name: "plus-blue", path: `${path}plus-blue.svg` },
	{ name: "plus-purple", path: `${path}plus-purple.svg` },
	{ name: "world-marker", path: `${path}world-marker.svg` },
	{ name: "location-icon", path: `${path}location_icon.svg` },
	{ name: "location-icon-search", path: `${path}location-icon-search.svg` },
	{ name: "check-coupon", path: `${path}check-coupon.svg` },
	{ name: "purple-clock", path: `${path}purple-clock.svg` },
	{ name: "user-green", path: `${path}user-green.svg` },
	{ name: "card-line", path: `${path}card-line.svg` },
	{ name: "card-pos", path: `${path}card-pos.svg` },
	{ name: "card-money", path: `${path}card-money.svg` },
	{ name: "shield-orange", path: `${path}shield-orange.svg` },
	{ name: "shield-green", path: `${path}shield-green.svg` },
	{ name: "coupon-vt", path: `${path}coupon-vt.svg` },
	{ name: "store-for-pick-up-green", path: `${path}store-for-pick-up-green.svg` },
	{ name: "save-money-v2", path: `${path}save-money-v2.svg` },
	{ name: "save-money-pig", path: `${path}save-money-pig.svg` },
	{ name: "coupon-green", path: `${path}coupon-green.svg` },
	{ name: "position-pin-2", path: `${path}position-pin-2.svg` },
	{ name: "voucher", path: `${path}voucher.svg` },
	{ name: "payment-method", path: `${path}payment-method.svg` },
	{ name: "pencil", path: `${path}pencil.svg` },
	{ name: "info-outline-blue", path: `${path}info-outline-blue.svg` },
	{ name: "search-modal", path: `${path}search-modal.svg` },
	{ name: "face-sad-v2", path: `${path}face-sad-v2.svg` },
	{ name: "happy-face", path: `${path}happy-face.svg` },
	{ name: "info", path: `${path}info.svg` },
	{ name: "personal-information", path: `${path}personal-information.svg` },

	{ name: "arrow-up-mifa", path: `${path}mifarma/arrow-up-mifa.svg` },
	{ name: "arrow-down-mifa", path: `${path}mifarma/arrow-down-mifa.svg` },

	{ name: "arrow-up-ikf", path: `${path}inkafarma/arrow-up-ikf.svg` },
	{ name: "arrow-down-ikf", path: `${path}inkafarma/arrow-down-ikf.svg` },
	
	{ name: "tag-mf", path: `${path}mifarma/tag-mf.svg` },
	{ name: "tag-ikf", path: `${path}inkafarma/tag-ikf.svg` },
	{ name: "pill", path: `${path}pill.svg` },
	{ name: "paper-background", path: `${path}paper-background.svg` },
  { name: "mini-cart-ikf", path: `${path}mini-cart-ikf.svg`},
  { name: "mini-cart-mf", path: `${path}mini-cart-mf.svg`},
  { name: "mini-cart-default", path: `${path}mini-cart-default.svg`},
	{ name: "pill", path: `${path}pill.svg` },
	{ name: "info-mf", path: `${path}mifarma/info.svg` },
	{ name: "info-ikf", path: `${path}inkafarma/info.svg` },
	{ name: "play", path: `${path}play.svg` },

];
