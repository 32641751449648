import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "@environments/environment";
import { AuthImplementService } from "@implements/auth/auth-implement.service";
import { CoverageLocationResponse } from "@models/coverage-location/coverage-location.model";
import { EBrand, EBrandCode, EBrandName } from "@parameters/access/brand.parameter";
import { CoverageLocationStoreService } from "@stores/coverage-location/coverage-location-store.service";
import { LOCALSTORAGE_SHOPPING_CART_LIST } from "@stores/shopping-list/constants/shopping-list.constant";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";
import { TaggingService } from "src/app/atm-services/tagging.service";
import { ProductDetail } from "src/app/business/product-detail/models/product-detail-model";
import { PRODUCT_PRESCRIPTION } from "src/app/business/product-detail/parameters/product-detail.parameters";
import { ProductDetailImplementService } from "src/app/business/product-detail/services/product-detail-implement.service";
import { BUSINESS_KEYS, BUSINESS_PATHS } from "src/app/shared/parameters/business.parameter";
import { ProductClientService } from "src/app/shared/service";
import { AnalyticServiceService } from "src/app/shared/service/analitycService/analitycService.service";
import { RetValidateCartService } from "src/app/shared/service/cart/ret-validate-cart.service";
import { ShowModalBoticaService } from "src/app/shared/service/showModalServices/showModalService.service";
import { AppStoreService } from "src/app/shared/stores/app/app-store.service";
import { ShoppingListStoreService } from "src/app/shared/stores/shopping-list/shopping-list-store.service";
import { BreakpointBehavior } from "../../../../../shared/behaviors";
import { ProductShoppingCartRequest } from "../../../../../shared/models/shopping-cart/product-shopping-cart-request.model";
import { AlgoliaInstanceService } from "../../../custom-instantsearch/algolia-instance.service";
import { ModalsManagerService } from "../../../pick-up-in-store/pick-up-services/modals-manager.service";
import { ProductCard } from "../../models/product-card/product-card.model";
import { ProductPresentation } from "../../models/product-presentation.model";
import { EProductStatus } from "../../models/product-status.enum";
import { ProductImplementService } from "../../service/product-implement.service";
import { ProductSalesforceService } from "../../service/product-salesforce.service";
import { productRADHaveStock, productRETHaveStock } from "../../service/product-status.helper";
import { ShoppingCartListValidationsService } from "../../service/shopping-cart-list-validations.service";
import { StoresForPickUpStoreService } from "../../../pick-up-in-store/pick-up-stores/stores-for-pick-up-store.service";
import { AddressListStoreService } from "../../../address-manager/address-stores/address-list-store.service";
import { OrderTakerValidator } from "@validators/order-taker.validator";
import { WhatsappInCartAliviamedModalService } from "../../../whatsapp-sale/Service/whatsapp-in-cart-aliviamed-modal.service";
import { LS_ALGOLIA_REDIRECTS } from "@service/local-storage/algolia/ls-algolia.constans";
import { LocalStorageService } from "@service/local-storage/local-storage.service";
import { IAlgoliaObjectData } from "../../../custom-instantsearch/interfaces/algolia-searcher.interface";

@Component({
	selector: "fp-product-large",
	templateUrl: "./product-large.component.html",
	styleUrls: ["./product-large.component.sass"]
})
export class ProductLargeComponent implements OnInit, OnDestroy {
	public brand = EBrand;
	brandCode = environment.brand;
	public isInkaBrand = EBrand[environment.brand] == EBrand.inkafarma;
	public isMifaBrand = EBrand[environment.brand] == EBrand.mifarma;
	@Input() product: ProductCard;
	@Input() sectionID: string;
	@Input() type: string = "";
	@Input() buttonIndex;
	public eProductStatus = EProductStatus;
	public productStatus: number = EProductStatus.available;
	public productPresentation: ProductPresentation;
	public productPrescription = PRODUCT_PRESCRIPTION;
	public productPath = BUSINESS_PATHS.PRODUCT;
	public isProductSeller: boolean;
	public coverage: CoverageLocationResponse;

	public productQuantity = 1;
	public productPresentationId: number;
	public productInShoppingCart: boolean;
	private productInShoppingCartSubscription: Subscription;

	public hoverScreen: boolean;
	private hoverSubscription: Subscription;
	private coverageSubscription: Subscription;

	public initialPresentation: string;
	public initialPrecio: number;

	public validProduct: boolean = false;
	public shoppingCartListUpdateLocal: Array<ProductShoppingCartRequest> = [];

	isOrderTaker = OrderTakerValidator.isOrderTakerEnv();
	isRAD = this.retValidateCartService.isRADProcess;
	isShowSeller = false;
	companyCodeIkf = "IKF";
	textSellerINKAorMIFA = "";
	brandCurrent = "";
	prescription;
	isGeneric = true;
	get typeHaveStock() {
		return this.retValidateCartService.isRADProcess ? this.radHaveStock : this.retHaveStock;
	}

	get radHaveStock() {
		return productRADHaveStock(this.product, true, this.coverage);
	}

	get retHaveStock() {
		return productRETHaveStock(this.product, false);
	}

	constructor(
		private _breakpointBehavior: BreakpointBehavior,
		private _productImplement: ProductImplementService,
		private _productSalesforce: ProductSalesforceService,
		// ---- servicios para snackbar o modal de producto agregado o rechazado (marketplace) -------
		private shoppingCartListValidations: ShoppingCartListValidationsService,
		private productClientService: ProductClientService,
		// -----------------------
		private router: Router,
		private serviceAnalytic: AnalyticServiceService,
		private showModalBoticaService: ShowModalBoticaService,
		private productDetailImplement: ProductDetailImplementService,
		private _appStore: AppStoreService,
		private _shoppingListStore: ShoppingListStoreService,
		private retValidateCartService: RetValidateCartService,
		public auth: AuthImplementService,
		private modalManager: ModalsManagerService,
		private algoliaInstanceService: AlgoliaInstanceService,
		private coverageLocationStore: CoverageLocationStoreService,
		private storesForPickUpStore: StoresForPickUpStoreService,
		private addressListStore: AddressListStoreService,
		private inCartAliviamed: WhatsappInCartAliviamedModalService,
		private localStorageService: LocalStorageService
	) {
		this.hoverSubscription = this._breakpointBehavior.hoverObserver().subscribe((value) => (this.hoverScreen = value.matches));
		this.coverageSubscription = this.coverageLocationStore.coverageLocationResponse$.subscribe((res) => {
			this.coverage = res;
		});
	}

	ngOnInit() {
		this.getProductPresentation(this.product.defaultPresentation);
		this.getProductShoppingCart();
		this.getProductStatus();

		this.brandCurrent = this._appStore.brandAtention;
		this.textSellerINKAorMIFA = EBrandName[this.brandCurrent];

		this.isShowSeller = this.brandCurrent === EBrand.inkafarma;
		this.isProductSeller = this.product.isFromSeller;		
	}

	getProductShoppingCart() {
		let productUpdate = true;
		this.productInShoppingCartSubscription = this._productImplement
			.getProductShoppingCart$(this.product.id)
			.subscribe((value: ProductShoppingCartRequest) => {
				this.productInShoppingCart = !!value;
				this.productPresentationId = this.productInShoppingCart ? value.presentationIdSelected : this.product.defaultPresentation;
				this.productQuantity = this.productInShoppingCart ? value.quantity : 1;
				this.getProductPresentation(this.productPresentationId);
				this.getProductStatus();

				if (this.productPresentation.id !== this.productPresentationId && productUpdate) {
					productUpdate = false;
					this.productQuantity = 1;
					this.updateProductToShoppingCart();
				}
				if (this.productInShoppingCart) {
					this.validateProductQuantity(this.product.id);
				}
			});
	}

	getProductStatus() {
		// this.productStatus = this.typeHaveStock ? this.product.statusId : 3;
		this.productStatus = this.product.statusId;
	}

	validateProductQuantity(id: string) {
		this.productDetailImplement
			.getProductDetail(id)
			.pipe(take(1))
			.subscribe((product: [ProductDetail, ProductCard]) => {
				const maxQuantity = product[0]?.presentations[0]?.maxUnitSale;
				if (this.productQuantity > maxQuantity && maxQuantity != 0) {
					this.productQuantity = maxQuantity;
					this.updateShoppingCart(id, this.productQuantity);
				}
			});
	}

	checkProductAliamedInCart(){
		return this._productImplement.isAliviamedConsultationInShoppingCart();
	}

	updateShoppingCart(id: string, quantity: number) {
		const shoppingLocalList = this._appStore.getStorageItem(LOCALSTORAGE_SHOPPING_CART_LIST);
		const parsedObjectShoppingList = JSON.parse(shoppingLocalList);
		parsedObjectShoppingList.filter((res, i) => {
			if (id === res.productId) {
				parsedObjectShoppingList[i].quantity = quantity;
			}
		});
		this._appStore.setStorageItem("shoppingCartList", JSON.stringify(parsedObjectShoppingList));
	}

	getProductPresentation(presentationId: number) {
		this.productPresentation =
			this.product.presentations.find((presentation) => presentation.id === presentationId) ||
			this.product.presentations.find((presentation) => presentation.id === this.product.defaultPresentation);
		this.productPresentationId = this.productPresentation.id;
	}

	updateQuantity(quantity: number) {
		if (quantity > this.productQuantity) {
			TaggingService.productClick(this.product);
			TaggingService.detail(this.product);
			this.productQuantity = quantity;
			TaggingService.addToCart(this.product, this.productPresentation);
			// TaggingService.addToCartSDK(this.product,  this.productPresentation,quantity)
			this.callEventAnalyticsAlgolia();
		} else {
			this.productQuantity = quantity;
			TaggingService.removeFromCart(this.product, this.productPresentation.price, this.productPresentation.presentation);
			// TaggingService.removeFromCartSDK(this.product, this.product.presentations,  quantity)
		}

		this.updateProductToShoppingCart();
		this.retValidateCartService.processValidateAddToCart.next(true);
	}

	doDeleted() {
		if (this.type == "complementary") {
			TaggingService.removeComplementaryProduct(this.buttonIndex + 1, this.product.id);
		}
		this.retValidateCartService.processValidateAddToCart.next(true);
	}

	addEventBrain() {
		TaggingService.productClickBrain(this.product, this.sectionID);
	}

	updateQuantityProduct() {
		this.shoppingCartListUpdateLocal.push({
			productId: this.product.id,
			quantity: this.productQuantity,
			presentationIdSelected: this.productPresentation.id,
			sellerId: this.product.sellerId,
			sellerName: this.product.sellerName
		});
		return this.shoppingCartListUpdateLocal;
	}

	addProductToShoppingCart(card?: boolean) {
		if (this.checkProductAliamedInCart()) {
			this.inCartAliviamed.showModalAliviamedInCart();
			return;
		}

		if (!this.retValidateCartService.isRADProcess && this.isProductSeller) {
			this.modalManager.openRETProductCantAdded(this.product);
			return;
		}

		if (!card) {
			if (!this.coverageLocationStore.isCovered && this.retValidateCartService.isRADProcess) {
				const drugstore = this.storesForPickUpStore.selectedDrugstore;
				const currentAddreses = this.addressListStore.currentSelectedAddressFromStore;
				this.retValidateCartService.showOutCoverageDialog(drugstore, currentAddreses, null, this.product.id);
				return;
			}
		}

		this._productImplement.addProduct(
			this.product.id,
			this.productQuantity,
			this.productPresentation.id,
			this.product.sellerId,
			this.product.sellerName,
			this.product.productPack,
			this.type
		);
		this._productSalesforce.addProduct(
			this.product.id,
			this.product.id,
			this.productQuantity,
			this.productPresentation.price * this.productQuantity
		);
		this.retValidateCartService.processValidateAddToCart.next(true);
		console.log("🚀 ~ ProductLargeComponent ~ addProductToShoppingCart ~ this.productQuantity:", this.productQuantity);

		TaggingService.productClick(this.product);
		TaggingService.detail(this.product);
		TaggingService.addToCartSDK(this.product, this.productPresentation, this.productQuantity);
		TaggingService.addToCart(this.product, this.productPresentation);
		if (this.type == "complementary") {
			TaggingService.addComplementaryProduct(this.buttonIndex + 1, this.product.id);
		}
		if (this.sectionID) {
			TaggingService.productAddToCartBrain(this.product, this.sectionID, this.productQuantity);
		}

		this.algoliaInstanceService.algoliaClickedObjectIDs(this.product.id);
		this.callEventAnalyticsAlgolia();
	}

	updateProductToShoppingCart() {
		this._productImplement.updateProduct(
			this.product.id,
			this.productQuantity,
			this.productPresentation.id,
			this.product.sellerId,
			this.product.sellerName,
			this.product.productPack
		);

		TaggingService.updateCartSDK(this.product, this.productPresentation.price, this.productQuantity);
	}

	ngOnDestroy(): void {
		this.hoverSubscription.unsubscribe();
		this.productInShoppingCartSubscription.unsubscribe();
		this.coverageSubscription.unsubscribe();
	}

	addToFavorite() {
		this._productImplement
			.addToFavoriteProduct(this.product.id)
			.pipe(take(1))
			.subscribe(() => (this.product.favorite = true));
	}

	removeFromFavorite() {
		this._productImplement
			.removeFromFavoriteProduct(this.product.id)
			.pipe(take(1))
			.subscribe(() => (this.product.favorite = false));
	}

	clickOnDetail() {
		this.router.navigate(["/" + this.productPath, this.product.slug, this.product.id]);
		TaggingService.productClick(this.product);
	}

	showModalBotica(product) {
		if (this.hoverScreen) {
			this.showModalBoticaService.showModalDesktopService(product);
		} else {
			this.showModalBoticaService.showModalResponsiveService(product);
		}
		this.serviceAnalytic.registerEventAnalytics(
			this.serviceAnalytic.detectModuleTag("consultar_stock_en_botica"),
			"Boton :: consultar stock en botica",
			product.id
		);
	}

	registerEventAnalyticsAndValidateRedirectToAlgolia() {
		//Redirect to detail product
		const isRedirectoToSearchAlgolia = this.validateRedirectByAlgolia();
		if (isRedirectoToSearchAlgolia) {
			this.localStorageService.setItem(LS_ALGOLIA_REDIRECTS, true);
		}
		this.algoliaInstanceService.algoliaClickedObjectIDs(this.product.id);
	}

	getTextSellerINKAorMIFA() {
		return this._appStore.companyCode === this.companyCodeIkf ? EBrandName[EBrand.inkafarma] : EBrandName[EBrand.mifarma];
	}

	callEventAnalyticsAlgolia() {
		const isRedirectoToSearchAlgolia = this.validateRedirectByAlgolia();

		isRedirectoToSearchAlgolia ? this.algoliaInstanceService.convertedAfterSearch([this.product.id]) : null;

		if (isRedirectoToSearchAlgolia) {
			const discount =
				this.productPresentation.regularPrice != 0 ? this.productPresentation.price - this.productPresentation.regularPrice : 0;
			const objectData: IAlgoliaObjectData = {
				price: this.productPresentation.price,
				discount: Number(discount.toFixed(2)),
				quantity: this.productQuantity
			};

			this.algoliaInstanceService.addedToCartObjectIDsAfterSearch([this.product.id], [objectData]);
		}

		this.algoliaInstanceService.addConvertedObjectID(
			this.product,
			this.productPresentation,
			this.productQuantity,
			isRedirectoToSearchAlgolia
		);
	}

	validateRedirectByAlgolia(): boolean {
		const urlSearch = `${BUSINESS_PATHS.SEARCH}?${BUSINESS_KEYS.SEARCH_QUERY_PARAM_KEY}`;
		const isUrlSearch = this.router.url.includes(urlSearch);
		const isUrlDetailProduct = this.router.url.includes(`${BUSINESS_PATHS.PRODUCT}/`);
		const isRedirectoToSearchAlgolia = this.localStorageService.getItem(LS_ALGOLIA_REDIRECTS) as boolean;

		return isUrlSearch || (isUrlDetailProduct && isRedirectoToSearchAlgolia);
	}
	getBrandIcon(isFromSeller) {
		if (isFromSeller) {
			return "shopping-cart";
		} else {
			return environment.brand === EBrand.inkafarma ? "tag-ikf" : "tag-mf";
		}
	}

	get textTagSeller() {
		if (OrderTakerValidator.isOrderTakerEnv()) {
			return this.product.isFromSeller ? this.product.sellerName : this._appStore.brandAtention == "IKF" ? "Inkafarma" : "Mifarma";
		} else {
			return this.product.isFromSeller
				? this.product.sellerName
				: this.isMifaBrand
				? EBrandName["mifarma"]
				: EBrandName["inkafarma"];
		}
	}

	get iconBrand() {
		if (OrderTakerValidator.isOrderTakerEnv()) {
			return this.product.isFromSeller ? "shopping-cart" : this._appStore.brandAtention == "IKF" ? "tag-ikf" : "tag-mf";
		} else {
			return this.product.isFromSeller ? "shopping-cart" : this.isMifaBrand ? "tag-mf" : "tag-ikf";
		}
	}

	get showPrescription() {
		return (
			this.product.prescription.toUpperCase() === this.productPrescription.PRESCRIPTION.toUpperCase() ||
			this.product.prescriptionCode
		);
	}

	get shortTagBrand() {
		return this.showPrescription || this.product.isGeneric;
	}
	get shortTagPrescription() {
		// return this.product.isGeneric;
		return this.product.isGeneric;
	}

	get iconPrescription() {
		return this.product.prescriptionCode ? "paper-background" : "prescription";
	}
}
